import { Component, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../presentation-sharing/presentation-sharing.interfaces';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent {
  @Input() users: any[] = [];
  @Input() showRemoveButton = false;
  @Input() showInviteInfo = false;
  @Input() isAutocomplete = false;
  @Input() selectedIndex = -1;
  @Input() listPositionTop: number;
  @Input() listPositionLeft: number;
  @Input() listWidth: number;

  @Output() removeUser = new EventEmitter<string>();
  @Output() selectUser = new EventEmitter<User>();

  onRemoveUser(email: string): void {
    this.removeUser.emit(email);
  }

  onSelectUser(user: User): void {
    this.selectUser.emit(user);
  }
}
