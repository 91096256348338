import {
  ActivatedRoute,
  Event,
  GuardsCheckEnd,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  Router,
} from '@angular/router';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewInit,
  Renderer2,
  ChangeDetectorRef,
  HostListener,
  Inject,
} from '@angular/core';

import * as _ from 'lodash-es';
import { Store, select } from '@ngrx/store';
import { Subscription, Observable, Subject, throwError } from 'rxjs';
import { filter, map, first, switchMap, takeUntil, withLatestFrom, tap, catchError } from 'rxjs/operators';
import { isNumber } from 'lodash-es';

import { AppState } from '../../../reducers';
import {
  APIService,
  DOMService,
  Utils,
  Global,
  PermissionHandlerService,
  StyleEditorService,
  UpdateNavbarPagesService,
} from '@shared/services';
import { SetupService } from './setup.service';
import * as setupConstant from './setup.constant';
import { ConfirmModalComponent } from '@shared/components/modal/confirm-modal/confirm-modal.component';
import { TargetPremiumComponent } from '../target-premium/target-premium.component';
import {
  getPlanLoading,
  getSelectedPageId,
  getDependentPagesConfig,
  getSetupLoading,
  getNavbarCustomPages,
  getNavbarDependentPages,
  getNavbarEndPages,
} from './setup.selectors';
import {
  setPlanLoadingPending,
  changeSelectedPageId,
  changeSelectedPage,
  setSetupLoading,
  salesConceptsModalAvailable,
} from './setup.actions';
import { CustomPageService } from './custom-page/custom-page.service';
import {
  GlobalConfig,
  PageConfig,
  CustomPageValues,
  CustomPagesConfig,
  PlansOptions,
  PlanOption,
  NavbarCustomPagesData,
} from '@shared/models';
import { IAMService } from '@assurance/um-services';
import {
  presentationConfigsSettingFailure,
  presentationConfigsSettingPending,
  presentationConfigsSettingSuccess,
  presentationPlansUpdateSuccess,
} from '../presentation.actions';
import * as ConfigsActions from '../redux/configs/actions';
import { coverSheetLandscape } from '@ngrx-app/global.actions';
import { getCoverLetterWithRequired } from '../modals/cover-sheet-modal/cover-sheet-modal.selectors';
import { chain, ensightTitle } from '@shared/constants';
import { getPermittedConfigs, getPresentationConfigs } from '../redux/configs/selectors';
import { getPresentationPlans } from '../presentation.selectors';
import { GAService } from '@shared/services';
import { PlansService } from '../presentation-plans/plans.service';
import { CareerPlan } from '@core/model';
import { WINDOW_TOKEN } from '@core/constant';

@Component({
  selector: 'ensight-presentation-setup-page',
  templateUrl: 'setup.html',
  styleUrls: ['./setup.component.scss'],
})
export class PresentationSetupPageComponent implements OnInit, OnDestroy, AfterViewInit {
  collapse: boolean;
  selectedPage: PageConfig | CustomPageValues;
  navbarStaticPages: any[];
  plansOptions: PlansOptions = {} as PlansOptions;
  menuPagesList: PageConfig[] = [];
  config: any;
  pagesNavId: string;
  isPlanLoading$: Observable<boolean>;
  getNavbarDependentPages$: Observable<CustomPageValues[]>;
  getNavbarEndPages$: Observable<CustomPageValues[]>;
  dependentPages: CustomPageValues[];
  dependentPagesConfig: CustomPagesConfig[];
  salesConcepts: CustomPageValues[];
  selectedPageId: string;
  setupLoading$: Observable<boolean> = this.store.pipe(select(getSetupLoading));
  compilePage: boolean;
  headerHeight: number;
  showSpinner: boolean;

  private activeRouterSubscriptions: Subscription[];
  private coverLetter: any;
  private coverLettersRequired: any;
  private presentationConfigs: Array<GlobalConfig & PageConfig>;
  private initialWidth: number;
  private isInitialized = false;

  protected routerOutletComponent: any = null;
  protected readonly unsubscribe$ = new Subject<void>();

  @ViewChild(ConfirmModalComponent) public confirmModalComponent: ConfirmModalComponent;
  @ViewChild('innerContainer') public innerContainer: ElementRef<any>;

  @HostListener('window:resize', ['$event']) onResize() {
    const currentWidth = this.window.innerWidth;

    if (this.initialWidth !== currentWidth) {
      this.initialWidth = currentWidth;
      this.getHeaderHeight();
    }
  }

  constructor(
    public iamService: IAMService,
    public global: Global,
    public elementRef: ElementRef,
    public renderer: Renderer2,
    public utils: Utils,
    public apiService: APIService,
    public setupService: SetupService,
    public domService: DOMService,
    public router: Router,
    public route: ActivatedRoute,
    public store: Store<AppState>,
    public customPageService: CustomPageService,
    public gaService: GAService,
    public plansService: PlansService,
    protected permissionHandlerService: PermissionHandlerService,
    @Inject(WINDOW_TOKEN) protected window: Window,
    public updateNavbarService: UpdateNavbarPagesService,
    public cdr?: ChangeDetectorRef,
    private styleEditorService?: StyleEditorService
  ) {}

  ngOnInit(): void {
    this.watchRouteEvents();
    this.watchForPageRecompile();
    this.getPages();

    if (!this.global.isPresentationView) sessionStorage.removeItem(setupConstant.PRODUCTS_SESSION_STORAGE_KEY);

    this.global.isPresentationView &&
      this.store
        .pipe(select(getDependentPagesConfig), takeUntil(this.unsubscribe$))
        .subscribe(config => (this.dependentPagesConfig = config?.configs));

    this.store
      .pipe(
        select(getCoverLetterWithRequired),
        filter(data => !!data.coverLettersRequiredLoaded),
        withLatestFrom(
          this.store.pipe(select(this.global.isSharedPresentation() ? getPresentationConfigs : getPermittedConfigs))
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([data, configs]) => {
        const coverLetter = data.coverLetter;
        const coverLettersRequired = data.coverLettersRequired;

        this.setPlansNavOptions();

        const stayOnPage = !_.isNull(this.coverLetter) && !_.isNull(coverLetter);
        const shouldRedirectToCoverSheet = _.isNull(this.coverLetter) && !_.isNull(coverLetter);

        if (shouldRedirectToCoverSheet) {
          this.global.selectedPlanId = null;
        }

        this.coverLetter = coverLetter;
        this.coverLettersRequired = coverLettersRequired;
        this.getPageConfig(configs as Array<GlobalConfig & PageConfig>, stayOnPage, shouldRedirectToCoverSheet);
      });

    this.store
      .pipe(select(getPresentationPlans), takeUntil(this.unsubscribe$))
      .subscribe(data => this.setPlansNavOptions(data));

    this.store
      .pipe(
        select(this.global.isSharedPresentation() ? getPresentationConfigs : getPermittedConfigs),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((configs: Array<GlobalConfig & PageConfig>) => {
        configs = this.permissionHandlerService.filterPermittedConfigs<GlobalConfig & PageConfig>(configs);
        this.presentationConfigs = configs;
        this.getActualPagesConfig(configs);
        this.menuPagesList = this.setCoverLetter(this.menuPagesList);
        this.handleDisabledPages(this.menuPagesList);
        this.setNavbarStaticPages();
      });

    this.isPlanLoading$ = this.store.select(getPlanLoading);

    if (!this.global.isPresentationView) {
      this.setPlansNavOptions();
    }

    this.domService.setActiveButton('.setup-presentation-btn'); // TODO: need to remove because of removing presentation view

    if (!this.global.getDispatchEvents) {
      this.global.setDispatchEvents = d3.dispatch();
    }

    this.pagesNavId = setupConstant.pagesNavId;

    this.store
      .pipe(select(this.global.isSharedPresentation() ? getPresentationConfigs : getPermittedConfigs), first())
      .subscribe((configs: Array<GlobalConfig & PageConfig>) => {
        this.getPageConfig(configs);

        if (!this.global.isPresentationView) {
          const plans = this.global.getCurrentCarrierPlans;

          if (plans.length && this.hasIllustrationFiles(plans)) {
            this.global.activatePresentationMenu(true);
            this.go(this.selectedPage);
          } else {
            this.router.navigate(['/presentation', this.global.getActivePresentationId, 'setup', 'plans']);
          }
        } else {
          this.go(this.selectedPage);
        }

        this.store
          .pipe(
            select(getSelectedPageId),
            filter(id => id !== this.selectedPageId),
            tap(id => {
              this.menuPagesList = this.permissionHandlerService.filterPermittedConfigs<PageConfig>(this.menuPagesList);
              this.selectedPageId = id;

              if (id) {
                const selectedPage = this.setupService.getPageConfigById(this.menuPagesList, id);
                this.selectedPage =
                  selectedPage ||
                  (!this.global.getCurrentCarrierPlans.find(plan => plan.id === Number(this.selectedPageId)) &&
                    this.selectedPage) ||
                  ({ config: { uiId: id } } as PageConfig);
                this.store.dispatch(changeSelectedPage({ payload: this.selectedPage }));
                this.cdr && this.cdr.detectChanges();
                this.triggerEmptyPagesRedirect();
              }
            }),
            takeUntil(this.unsubscribe$)
          )
          .subscribe();
      });
    this.initialWidth = this.window.innerWidth;
    this.getHeaderHeight();

    if (this.global.isPresentationSetupRoute()) {
      this.styleEditorService.collapsedState$.subscribe(() => {
        if (this.isInitialized) {
          setTimeout(() => {
            this.getHeaderHeight();
          }, 500);
        } else {
          this.isInitialized = true;
        }
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.innerContainer) {
      this.innerContainer.nativeElement.style.transition = 'margin-left 0.9s ease';
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    sessionStorage.removeItem(setupConstant.PRODUCTS_SESSION_STORAGE_KEY);
  }

  mousemoveEvent = () => {
    return;
  };

  itemsMoved(listId: string): void {
    // fix loaders
    this.store.dispatch(setSetupLoading({ payload: true }));

    if (listId === setupConstant.plansNavId) {
      this.setupService.reorderPlans(this.plansOptions.dndList).subscribe(({ data }) => {
        this.store.dispatch(setSetupLoading({ payload: false }));
        this.global.setCurrentCarrierPlans = this.global.getCurrentCarrierPlans.map((item: CareerPlan) => ({
          ...item,
          order: data.order.indexOf(item.id) + 1,
        }));
        this.store.dispatch(
          presentationPlansUpdateSuccess({
            plans: _.cloneDeep(this.global.getCurrentCarrierPlans),
          })
        );

        this.store.dispatch(
          ConfigsActions.updateActivePlansOrdering({
            orderedPlansIds: this.plansOptions.dndList.map((dnd: PlanOption) => dnd.id),
          })
        );
      });
    } else if (listId === setupConstant.pagesNavId) {
      const orderedUiIds = this.menuPagesList.map(page => page.config.uiId);
      const orderedPageOrder = _.orderBy(
        this.menuPagesList.map(page => page.pageOrder),
        Number
      );
      this.store.dispatch(ConfigsActions.updatePageOrdering({ orderedUiIds, orderedPageOrder }));
      this.store.dispatch(setSetupLoading({ payload: false }));
    }
  }

  updateNavbarPages(): Observable<[NavbarCustomPagesData, CustomPageValues[]]> {
    this.store.dispatch(presentationConfigsSettingPending());

    return this.apiService.getSharedPresentationConfigs().pipe(
      switchMap(res => {
        const configs = this.global.getConfiguration(res.data.configs);
        this.store.dispatch(presentationConfigsSettingSuccess({ data: configs }));

        return this.updateNavbarService.fetchNavbarCustomPages().pipe(map(() => configs));
      }),
      catchError(error => {
        this.store.dispatch(presentationConfigsSettingFailure({ error }));

        return throwError(error);
      })
    );
  }

  getPageConfig(
    configs: Array<GlobalConfig & PageConfig>,
    stayOnPage?: boolean,
    shouldRedirectToCoverSheet?: boolean
  ): void {
    configs = this.permissionHandlerService.filterPermittedConfigs<GlobalConfig & PageConfig>(configs);
    this.getActualPagesConfig(configs);
    this.menuPagesList = this.setCoverLetter(this.menuPagesList);

    if (this.shouldSetSelectedPage(stayOnPage)) {
      this.selectedPage = this.getSelectedPageConf(this.dependentPagesConfig);

      if (this.selectedPage?.config?.uiId === this.selectedPageId) {
        this.store.dispatch(changeSelectedPageId({ payload: this.selectedPage.config.uiId }));
        this.store.dispatch(changeSelectedPage({ payload: this.selectedPage }));
      }
    }

    this.handleDisabledPages(this.menuPagesList);
    this.setNavbarStaticPages();

    if (shouldRedirectToCoverSheet) {
      this.selectedPage = this.setupService.getPageConfigById(this.menuPagesList, 'cover');
      this.store.dispatch(changeSelectedPage({ payload: this.selectedPage }));
      this.store.dispatch(changeSelectedPageId({ payload: this.selectedPage.config.uiId }));
    }

    if (!stayOnPage) {
      this.setupService.navigatePage(this.selectedPage);
    }
  }

  getSelectedPageConf(dependentPagesConfig?: CustomPagesConfig[]): PageConfig | CustomPageValues {
    const coverLetter = this.coverLetter || _.find(this.menuPagesList, { isRequiredCoverSheet: true });
    const list = this.setupService.moveOrRemoveCoverLetterByIndex(this.menuPagesList, coverLetter);
    this.menuPagesList = this.permissionHandlerService.filterPermittedConfigs(list);
    let config = this.setupService.getFirstPage(list);

    if (this.global.isPresentationView && !config?.config?.showPreview) {
      const orderedConfigs = chain(this.menuPagesList)
        .filter(
          (item: PageConfig) =>
            !item.config.isSalesConcept || _.find(this.salesConcepts, { config: { uiId: item.config.uiId } })
        )
        .sortBy(item => item.pageOrder)
        .value();
      const dependentPage =
        dependentPagesConfig &&
        this.setupService.getFirstAvailableDependentPageId(
          chain(dependentPagesConfig)
            .filter(conf => _.find(orderedConfigs, { config: { uiId: conf.uiId } }))
            .sortBy(conf => _.findIndex(orderedConfigs, { config: { uiId: conf.uiId } }))
            .value(),
          this.dependentPages
        );
      config =
        this.setupService.defineFirstPage(orderedConfigs, true) ||
        this.dependentPages?.find(
          page => page.config.uiId === dependentPage?.dependentPageId && page.parentUiId === dependentPage?.parentUiId
        );
    }

    return config || list[0];
  }

  selectPage(page: any, replaceUrl = false): void {
    if (!this.global.pageLoading || this.global.isPresentationView) {
      const isPlanPage = Boolean(page.id);

      if (isPlanPage) {
        this.store.dispatch(setPlanLoadingPending());
        this.goToPlan(page);

        return;
      }

      if (
        page.isEndPage ||
        page.isRequiredCoverSheet ||
        page.isDependentPage ||
        (page.config && page.config.isSalesConcept)
      ) {
        this.goToEndPageOrRequired(page, replaceUrl);
      } else {
        this.go(page, replaceUrl);
      }

      this.scrollToTopPosition();
      this.gaService.sendOpenPageEvent(page.pageName ?? page.label);
    }

    if (this.global.isSharedPresentation()) {
      const title = page ? page?.pageName || page?.label : '';
      this.global.setBrowserTabTitle(`${ensightTitle} - ${title}`);
    }
  }

  go(page: any, replaceUrl = false): void {
    this.setupService.navigatePage(page, replaceUrl);
    this.global.selectedPlanId = null;
    this.selectedPage = page;
    this.store.dispatch(changeSelectedPageId({ payload: this.selectedPage.config.uiId }));
    this.setupService.setSelectedPlanId(page.config.carrierPlanId);
    this.setupService.classNameForChart = page.config.uiId;
  }

  setCoverLetter(list) {
    const coverAccess = this.global.isSharedPresentation() || this.iamService.hasGroupAccess('add_cover_letter');

    if (!coverAccess) return list;

    const coverLetterIndex = _.findIndex(list, {
      config: { uiId: 'cover' },
    });

    if (this.coverLettersRequired && this.coverLettersRequired.length) {
      if (coverLetterIndex !== -1) {
        list = [...list.slice(0, coverLetterIndex), ...list.slice(coverLetterIndex + 1)];
      }

      list = list?.filter(item => !item.isRequiredCoverSheet);

      const coverLettersFiltered = this.setupService.filterCoverLettersRequired(
        this.coverLettersRequired,
        this.menuPagesList
      );

      if (coverLettersFiltered && coverLettersFiltered.length) {
        this.store.dispatch(
          coverSheetLandscape({
            payload: false,
          })
        );
      }

      coverLettersFiltered.forEach(letter => {
        if (letter?.customFields) {
          list = [this.setupService.getConfigLikeObject(letter), ...list];
        }
      });

      if (this.plansService.checkOnAnnuityProducts()) {
        list = list.filter(page => page.internalId !== 99);
      }

      return list;
    }

    return this.setupService.moveOrRemoveCoverLetterByIndex(list, this.coverLetter);
  }

  updateStaticPages(staticPageUiId: string, redirect = true): void {
    const updatedPage: PageConfig = _.find(this.presentationConfigs, ['config.uiId', staticPageUiId]);
    this.store.dispatch(
      ConfigsActions.updateShowPreview({
        uiId: staticPageUiId,
        checkedState: !updatedPage.config.showPreview,
      })
    );
    this.getPageConfig(this.presentationConfigs);

    if (redirect) {
      this.selectPage(updatedPage);
    }
  }

  setNavbarStaticPages(): void {
    this.navbarStaticPages = this.setupService.filterNavbarStaticPages(this.menuPagesList);
  }

  onActivate(event: any): void {
    if (event) {
      this.routerOutletComponent = event;
      this.setActiveRouterEvents(this.routerOutletComponent);
    }
  }

  onDeactivate(): void {
    this.removeActiveRouterEvents();
  }

  collapseViewNavbar(first: boolean): void {
    if (!first && this.routerOutletComponent && this.routerOutletComponent.updateVisualizationEventHandler) {
      setTimeout(() => this.routerOutletComponent.updateVisualizationEventHandler(), 800);
    }
  }

  handleDisabledPages(menuPagesList: PageConfig[]): PageConfig[] {
    if (!(this.presentationConfigs && this.presentationConfigs[1].config.activePlans.length)) return this.menuPagesList;

    const currentDisabledPages = this.setupService.disableVisualizationsConfig(
      this.global.getCurrentCarrierPlans,
      this.presentationConfigs
    );

    // TODO [Configs]: handle default disabled state
    // menuPagesList.forEach((page) => {
    //   page.isDisabled = false;
    // });
    // indicate the pages with no data for using in navBar component (include/exclude toggle disabling)
    let list = [];
    menuPagesList.forEach(config => {
      list = currentDisabledPages.includes(config.config.uiId)
        ? [
            ...list,
            {
              ...config,
              config: { ...config.config, showPreview: false },
              isDisabled: true,
            },
          ]
        : [...list, { ...config }];
    });
    this.menuPagesList = list;

    return list;
  }

  protected setPresentationView(value: boolean): void {
    this.global.isPresentationView = value;
  }
  private getActualPagesConfig(configs: GlobalConfig[] & PageConfig[]): void {
    if (this.global.getCurrentCarrierPlans && this.global.getCurrentCarrierPlans.length) {
      this.menuPagesList = configs;
    }

    // we don't use global config for menu items
    this.menuPagesList = this.menuPagesList.filter(page => page.internalId !== 0);

    this.menuPagesList = _.sortBy(this.menuPagesList, ['pageOrder', 'pageName']);
  }

  private scrollToTopPosition(): void {
    if (this.innerContainer) {
      this.innerContainer.nativeElement.scrollTop = 0;
    }

    if (this.global.isSharedPresentation()) {
      const presentationWrapper = document.querySelector('.presentation-view-wrapper .new-presentation-wrapper');
      this.scrollToTop(presentationWrapper);
    }

    if (this.global.isPresentationView) {
      const collapsedContainer = document.querySelector('.collapsed-container');
      this.scrollToTop(collapsedContainer);
    }
  }

  private scrollToTop(element: Element | null): void {
    if (element) {
      element.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  private shouldSetSelectedPage(stayOnPage: boolean): boolean {
    return (!this.selectedPage || !this.global.selectedPlanId) && !stayOnPage;
  }

  private goToPlan(planOption: PlanOption): void {
    this.global.selectedPlanId = planOption?.id;

    if (planOption?.id) {
      this.router.navigate(['/presentation', this.global.getActivePresentationId, 'setup', 'plans', planOption.id]);
    }
  }

  private goToEndPageOrRequired(page: any, replaceUrl: boolean): void {
    this.global.selectedPlanId = null;
    this.selectedPage = page;
    this.store.dispatch(changeSelectedPageId({ payload: this.selectedPage.config.uiId }));
    this.store.dispatch(changeSelectedPage({ payload: page }));
    const localRoute = this.global.isPresentationView ? 'view' : 'setup';
    const isSharedPresentation = this.global.isSharedPresentation();
    const uiIdOrToken = isSharedPresentation ? this.global.getSharedToken() : this.global.getActivePresentationId;
    const commands = [
      isSharedPresentation ? '/shared-presentation' : '/presentation',
      uiIdOrToken,
      localRoute,
      ...this.setupService.getCustomPageRoute(page),
    ];
    commands.push(page.config.uiId);
    this.router.navigate(commands, { replaceUrl });
  }

  private setActiveRouterEvents(component: Component): void {
    if (component instanceof TargetPremiumComponent) {
      this.activeRouterSubscriptions = [
        component.removePageEvent.subscribe(() => {
          const pageToSelect = this.setupService
            .sortPagesByOrder<PageConfig>(this.menuPagesList)
            .filter(page => isNumber(page.pageOrder))[0];
          this.setupService.setSelectedPageId(pageToSelect.config.uiId);
          this.updateStaticPages('target_premium', false);
          this.gaService.sendRemovePageEvent('Target Premium');
        }),
      ];
    }
  }

  private removeActiveRouterEvents(): void {
    if (this.activeRouterSubscriptions) {
      for (const subscription of this.activeRouterSubscriptions) {
        if (subscription && subscription.unsubscribe) {
          subscription.unsubscribe();
        }
      }
    }
  }

  private getPages(): void {
    this.getNavbarDependentPages$ = this.store.pipe(
      select(getNavbarDependentPages),
      filter(pages => pages.length > 0),
      map(data => _.cloneDeep(data))
    );

    this.getNavbarEndPages$ = this.store.pipe(
      select(getNavbarEndPages),
      // filter(endPages => endPages.length > 0),
      map(data => _.cloneDeep(data)),
      switchMap(endPages => this.global.filterEndPages(endPages || []))
    );

    this.store
      .pipe(select(getNavbarCustomPages), takeUntil(this.unsubscribe$))
      .subscribe((data: NavbarCustomPagesData) => {
        this.salesConcepts = data.salesConcepts;
        this.dependentPages = data.dependentPages;
      });
  }

  private setPlansNavOptions(plansData: CareerPlan[] = this.global.getCurrentCarrierPlans): void {
    const newDndList = this.setupService.buildPlansNav(plansData);

    this.plansOptions = {
      ...this.plansOptions,
      dndList: newDndList,
      navId: setupConstant.plansNavId,
    };
  }

  private hasIllustrationFiles(plans: any) {
    return plans.some((plan: any) => plan.pdfIllustration.linkHash);
  }

  private getHeaderHeight(): void {
    this.headerHeight = document.querySelector('.details-header.container-full')
      ? document.querySelector('.details-header.container-full').clientHeight
      : 0;
  }

  private triggerEmptyPagesRedirect(): void {
    const pages = this.menuPagesList.filter(page => isNumber(page.pageOrder));

    if (!pages.length) {
      const plans = this.setupService.sortPagesByOrder(this.global.getCurrentCarrierPlans, 'order');
      this.goToPlan(plans[0]);
    }
  }

  private watchRouteEvents(): void {
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event: Event): void => {
      if (event instanceof GuardsCheckEnd) {
        if (event.shouldActivate) {
          this.compilePage = true;
          this.showSpinner = true;
        }
      } else if ([NavigationCancel, NavigationEnd, NavigationError].some(e => event instanceof e)) {
        this.compilePage = false;
        this.showSpinner = false;
        this.store.dispatch(salesConceptsModalAvailable({ payload: true }));
      }
    });
  }

  private watchForPageRecompile(): void {
    this.customPageService
      .getCompilePage()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: boolean) => (this.showSpinner = value));
  }
}
