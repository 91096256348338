import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { defaultMessage, illustrationMessage } from './not-found.constants';
import { ensightTitle } from '@shared/constants';

@Component({
  selector: 'ensight-not-found',
  styleUrls: ['not-found.component.scss'],
  templateUrl: 'not-found.component.html',
})
export class NotFoundComponent implements OnInit {
  public message = defaultMessage;

  constructor(public route: ActivatedRoute, private titleService: Title) {}

  ngOnInit() {
    this.titleService.setTitle(ensightTitle);
    this.route.params.subscribe(({ type }) => {
      if (type === 'illustration') {
        this.message = illustrationMessage;
      }
    });
  }
}
