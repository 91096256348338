import { Injectable } from '@angular/core';

import {
  filter,
  sortBy,
  cloneDeep,
  isUndefined,
  difference,
  get,
  findIndex,
  forIn,
  find,
  isNumber,
  minBy,
  remove,
  snakeCase,
} from 'lodash-es';
import { from, fromEvent, Observable } from 'rxjs';
import { filter as rxJsFilter, map, mergeMap, scan } from 'rxjs/operators';

import { SetupService } from '../setup/setup.service';
import { Global, MetricsService } from '@shared/services';
import { metricKeys } from '../setup/setup.constant';
import { GlobalConfig, PageConfig,  CustomPageValues, CustomPagesConfig, CustomPagesConfigAdditionFields } from '@shared/models';
import { PresentationService } from '../presentation.service';
import { PRODUCT_TABLE_UIID, PAGES_WITHOUT_PRODUCT_TABLE, MENU_PAGES_IDS } from '@shared/constants';
import { ALLIANZ_UIIDS } from '../modals/cover-sheet-modal/cover-sheet-modal.constants';
import { CustomPageService } from '../setup/custom-page/custom-page.service';
import { CareerPlan } from '@core/model';
import {isDefined} from "@core/utils";

//TODO: need to move to shared folder
@Injectable()
export class PDFGenerationService {
  constructor(
    private setupService: SetupService,
    private global: Global,
    private customPageService: CustomPageService,
    private presentationService: PresentationService,
    private metricsService: MetricsService
  ) {}

  preloadImages(urls: string[]): Observable<HTMLImageElement[]> {
    return from(urls).pipe(
      mergeMap((url: string) => {
        const img = new Image();

        img.src = url;

        return fromEvent(img, 'load').pipe(map(e => e.target));
      }),
      scan((acc, curr) => [...acc, curr], []),
      rxJsFilter((images: HTMLImageElement[]) => images.length === urls.length)
    );
  }

  public filterOnlySelectedPages(
    pages: PageConfig[],
    renderOnlyPages: string[],
    pdfPageParentUiId: string
  ): PageConfig[] {
    let filteredPages = pages;
    if (renderOnlyPages && renderOnlyPages.length) {
      filteredPages = pages.filter(page => {
        const isExist = renderOnlyPages.includes(page.config.uiId);

        if (page.isDependentPage && pdfPageParentUiId) {
          return isExist && page.parentUiId === pdfPageParentUiId;
        }

        return isExist;
      });
    }
    return filteredPages;
  }

  public getPageConfig(
    coverLetter: any,
    coverLettersRequired: CustomPageValues[],
    endPages: CustomPageValues[],
    dependentPages: CustomPageValues[],
    dependentPagesConfig: CustomPagesConfig[],
    salesConceptPages: CustomPageValues[],
    configs: (GlobalConfig & PageConfig)[],
    renderOnlyPages?: string[],
    pdfPageParentUiId?: string,
    filterStaticPages = true
  ): PageConfig[] {
    let pages = this.filterPagesList(salesConceptPages, configs);
    if (filterStaticPages) {
      this.setupService.filterNavbarStaticPages(pages);
    }
    const hasCoverLetter = this.presentationService.setCoverLetter(pages, coverLetter);
    this.addOrder(dependentPagesConfig, configs);
    this.addDPOrder(salesConceptPages, dependentPages);
    this.extendEndPages(pages, endPages);
    this.extendDependentPages(pages, dependentPages, dependentPagesConfig, renderOnlyPages, pdfPageParentUiId, salesConceptPages, configs);
    this.extendConfigByProductTable(pages, coverLetter, hasCoverLetter, renderOnlyPages);

    // update retirement pageName (add productName and companyName to name)
    const hasRetirementView = pages.find(item => item.config.uiId === 'retirement_shortfall');
    if (hasRetirementView) {
      const activePlanId =
        hasRetirementView.config.activePlans[0] && hasRetirementView.config.activePlans[0].carrierPlanId;
      const selectedPlan = this.global.getCurrentCarrierPlans.find((plan: CareerPlan) => plan.id === activePlanId);
      if (selectedPlan) {
        const { product_name, company_name } = selectedPlan.configjson.metadata;
        hasRetirementView.pageName += ` (${product_name}/${company_name})`;
      }
    }
    pages = this.setCoverLetter(pages, coverLettersRequired);
    remove(
      pages,
      page => page.config.uiId === 'custom_visualization' && !this.getCustomVisualizationMetrics(page).length
    );
    remove(pages, this.setupService.checkIfHasNoSelectedPlan);
    return this.filterOnlySelectedPages(pages, renderOnlyPages, pdfPageParentUiId);
  }

  private addDPOrder(salesConcept: any[], dependentPages: CustomPageValues[]) {
    dependentPages?.length &&
      dependentPages.forEach(page => {
        const concept = salesConcept?.length && salesConcept.find(item => item.config.uiId === page.parentUiId);
        if (concept) {
          const dpItem = concept.dependentPages.find(conf => conf.uiId === page.config.uiId);
          page.order = dpItem?.order;
        }
      });
  }

  private filterPagesList(salesConceptPages: CustomPageValues[], configs: (GlobalConfig & PageConfig)[]): PageConfig[] {
    // const menuPagesList = filter(sortBy(configs, ['pageOrder']), 'config.showPreview').filter((config: GlobalConfig & PageConfig) => {
    //   return config.config.hasOwnProperty('isPermitted') ? config.config.isPermitted : true;
    //
    // });
    const menuPagesList = sortBy(configs, ['pageOrder']).filter((page) => {
      if(page.config.isSalesConcept) {
        const salesConcept = find(salesConceptPages, { config: { uiId: page.config.uiId } });

        return !salesConcept || !isUndefined(page.config.isParentEnabled) && !page.config.isParentEnabled ? false :
          salesConcept?.dependentPagesLocked || salesConcept?.isPageLockedAndEnabled || page.config.showPreview;
      } else {
        return page.config.showPreview;
      }
    })
    .filter((config: GlobalConfig & PageConfig) => {
      return config.config.hasOwnProperty('isPermitted') ? config.config.isPermitted : true;
    });

    return this.filterSalesConcepts(menuPagesList, salesConceptPages, configs);
  }

  private filterSalesConcepts(
    pages: PageConfig[],
    salesConceptPages: CustomPageValues[],
    configs: (GlobalConfig & PageConfig)[]
  ): PageConfig[] {
    return filter(pages, page => {
      let showPage = true;

      if (page.config.isSalesConcept) {
        const salesConcept = find(salesConceptPages, { config: { uiId: page.config.uiId } }) as CustomPageValues;

        if (salesConcept) {
          const missedPlansMessageShown = this.customPageService.showDependentPageMissedPlansMessage(salesConcept, configs);

          const hasEligibleProducts = salesConcept?.isEligibleForDataSources
          ? this.customPageService.hasEnoughEligibleProducts(salesConcept)
          : true;

          showPage = !isUndefined(page.config.isParentEnabled) && !page.config.isParentEnabled ? false  : (!missedPlansMessageShown && hasEligibleProducts) || salesConcept.dependentPagesLocked || salesConcept.isPageLockedAndEnabled;
        }
      }

      return !page.config.isSalesConcept || showPage;
    });
  }

  public addOrder(dependentPagesConfig: CustomPagesConfig[], configs: (GlobalConfig & PageConfig)[]): void {
    // TODO: need to change interface
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    forIn(dependentPagesConfig, (pageConf: CustomPagesConfigAdditionFields) => {
      const conf = find(configs, item => item.config.uiId === pageConf.uiId);

      if (conf) {
        pageConf.pageOrder = conf.pageOrder;
        pageConf.showPreview = conf.config.showPreview;

        if(isDefined(conf.config.isParentEnabled)) {
          pageConf.isParentEnabled = conf.config.isParentEnabled;
        }
      }
    });
  }

  // TODO: duplicate in presentation.component. Try to find 'plan.pdfIllustration.linkHash' this expression repeat in many place
  // https://assuranceapp.atlassian.net/browse/DAT-7450
  public filterIllustrationFiles(plans: CareerPlan[]): CareerPlan[] {
    return plans.filter(plan => plan.pdfIllustration.linkHash);
  }

  public isDefaultTheme() {
    return isUndefined(this.global.getIsDefaultTheme)
      ? this.global.currentTheme.default
      : this.global.getIsDefaultTheme;
  }

  /**
   * Remove pages from [pages] according to [hiddenVisualizationId].
   */
  public hideVisualization(hiddenVisualizationId: string, menuPagesList: PageConfig[]): PageConfig[] {
    return menuPagesList.filter(page => page.config.uiId !== hiddenVisualizationId);
  }

  /**
   * Extend [pages] array with metrics.
   */
  public printAllMetrics(
    plans: CareerPlan[],
    menuPagesList: PageConfig[],
    configs: (GlobalConfig & PageConfig)[]
  ): void {
    const confForMetric = (config: PageConfig, metric: string, activePlans?: any[]): PageConfig => {
      const clonedConfig = cloneDeep(config);
      clonedConfig.config.selectedMetricKey = metric;
      if (activePlans) {
        clonedConfig.config.activePlans = activePlans;
      }
      clonedConfig.config.uiId += `-${snakeCase(metric)}`;
      return clonedConfig;
    };

    for (const [key, value] of Array.from(metricKeys)) {
      const configIndex = menuPagesList.findIndex(item => item.config && item.config.uiId === key);
      if (configIndex === -1 || key === 'charges') {
        continue;
      }
      const config = menuPagesList[configIndex];
      const metricsConfigs = [];
      let metricsKeys = value;

      if (key === 'custom_visualization') {
        metricsKeys = this.getCustomVisualizationMetrics(config);
      }

      const activePlans = this.setupService.getActivePlans(plans, [configs[0], config], config.config.uiId);
      for (const metric of difference(metricsKeys, this.metricsService.getDisabledMetricsFields(activePlans))) {
        if (config) {
          metricsConfigs.push(confForMetric(config, metric));
        }
      }
      menuPagesList.splice(configIndex, 1, ...metricsConfigs);
    }

    // charges metrics
    const metricsConfigs: PageConfig[] = [];
    const chargesViewIndex = menuPagesList.findIndex(item => item.config && item.config.uiId === 'charges');
    if (chargesViewIndex !== -1) {
      for (const metric of menuPagesList[chargesViewIndex].config.planMetrics) {
        if (metric.included) {
          metricsConfigs.push(confForMetric(menuPagesList[chargesViewIndex], metric.key));
        }
      }
      menuPagesList.splice(chargesViewIndex, 1, ...metricsConfigs);
    }
    // single view metrics
    const singleViews = menuPagesList.filter(item => item.config && item.config.isSinglePolicy);
    for (const singleViewConfig of singleViews) {
      const configIndex = menuPagesList.findIndex(
        item => item.config && item.config.uiId === singleViewConfig.config.uiId
      );
      const config = menuPagesList[configIndex];
      const metricsConfigs = [];

      const planMetrics = singleViewConfig.config.planMetrics;
      const mainActivePlans = singleViewConfig.config.activePlans;

      const tabs = planMetrics.reduce(
        (result, metric) =>
          result.find(tab => tab.tabTitle === metric.tabTitle)
            ? result
            : [
                ...result,
                {
                  tabTitle: metric.tabTitle,
                  metrics: planMetrics.filter(planMetric => planMetric.tabTitle === metric.tabTitle),
                },
              ],
        []
      );

      for (const tab of this.setupService.getActiveTabs(plans[0].configjson.data, tabs)) {
        const activePlans = tabs
          .find(tabItem => tabItem.tabTitle === tab)
          ?.metrics?.map(metric => {
            return {
              isShown: mainActivePlans.find(activePlan => (activePlan.carrierPlanId || activePlan.metricId) === metric.key)?.isShown ?? true,
              metricId: metric.key,
              tabTitle: metric.tabTitle,
            }
          });
        metricsConfigs.push(confForMetric(config, tab, activePlans));
      }
      menuPagesList.splice(configIndex, 1, ...metricsConfigs);
    }
  }

  /**
   * Extend [pages] array with end pages.
   */
  public extendEndPages(menuPagesList: Array<PageConfig | CustomPageValues>, endPages: CustomPageValues[]): void {
    if (endPages && endPages.length) {
      menuPagesList.push(
        ...endPages.map(page => {
          return { ...page, pageName: page.label };
        })
      );
    }
  }

  /**
   * Extend [menuPagesList] array with dependent pages.
   */
  public extendDependentPages(
    menuPagesList: Array<PageConfig | CustomPageValues>,
    pages: CustomPageValues[],
    dependentPagesConfig: CustomPagesConfigAdditionFields[],
    renderOnlyPages?: string[],
    pdfPageParentUiId?: string,
    salesConceptPages?: CustomPageValues[],
    notFilteredConfigs?:  Array<PageConfig>
  ): void {
    if (pages && pages.length) {
      const insertResult: Array<{ uiId: string; after: CustomPageValues[]; }> = [];

      menuPagesList.forEach(item => {
        const uiId: string = get(item, 'config.uiId');
        const dependentPages = filter(pages, page => page.parentUiId === uiId);

        if (dependentPages && dependentPages.length) {
          const currentSaleConcept = (salesConceptPages || []).find(item => item.uiId === uiId.split('.')[0]);
          const availableDependentPages = this.getAvailableDependentPages(uiId, dependentPages, dependentPagesConfig, currentSaleConcept);
          const after = sortBy(availableDependentPages, ['order']);
          dependentPages.forEach(page => {
            page.pageOrder = item.pageOrder;
            page.parentUiId = uiId;
          });
          insertResult.push({ uiId, after });
        }
      });

      insertResult.forEach(item => {
        const index: number = findIndex(menuPagesList, {
          config: { uiId: item.uiId },
        });
        if (index !== -1) {
          menuPagesList.splice(index + 1, 0, ...item.after);
        }
      });

      this.insertDependentPagesForDisabledConfig(
        menuPagesList,
        pages,
        filter(dependentPagesConfig, page => page.isParentEnabled && !page.showPreview),
        notFilteredConfigs
      );

      if (renderOnlyPages && pdfPageParentUiId) {
        const filteredPages = pages.filter(
          item =>
            renderOnlyPages.includes(item.config.uiId) &&
            !menuPagesList.find(page => page.config.uiId === item.config.uiId)
        );
        menuPagesList.push(...filteredPages);
      }
    }
  }

  public insertDependentPagesForDisabledConfig(
    menuPagesList: Array<PageConfig | CustomPageValues>,
    pages: CustomPageValues[],
    dependentPagesConfig: CustomPagesConfigAdditionFields[],
    notFilteredConfigs: Array<PageConfig>
  ): void {
    for (const value of dependentPagesConfig) {
      const uiId = value.uiId;
      const checkedPages = filter(value.pages, item => {
        const found = pages.find(page => page.uiId == item.uiId);
        const concept = notFilteredConfigs.find(c => c.config.uiId === found?.parentUiId);

        return concept && isDefined(concept.config.isParentEnabled) && !concept.config.isParentEnabled ? false : found?.isPageLockedAndEnabled || item.isSelected;
      });
      const pagesKeys = checkedPages.map(el => el.uiId);
      const index = findIndex(menuPagesList, (obj, i) => {
        const nextOrder = get(menuPagesList[i + 1], 'pageOrder');
        return obj.pageOrder < value.pageOrder && (!isNumber(nextOrder) || nextOrder > value.pageOrder);
      });
      let dependentPages = filter(pages, page => {
        return pagesKeys.includes(page.config.uiId) && page.parentUiId === uiId;
      });
      dependentPages.forEach(item => {
        item.pageOrder = value.pageOrder;
        item.parentUiId = value.uiId;
      });

      if (dependentPages.length) {
        dependentPages = dependentPages.filter(page => !menuPagesList.find(item => item.isDependentPage ? item.uiId === page.uiId && item.parentUiId === page.parentUiId : false ));
        menuPagesList.splice(index + 1, 0, ...sortBy(dependentPages, ['order']));
      }
    }
  }

  public setCoverLetter(pages: PageConfig[], coverLettersRequired: CustomPageValues[]): PageConfig[] {
    const coverLetterIndex = findIndex(pages, {
      config: { uiId: 'cover' },
    });

    if (coverLettersRequired && coverLettersRequired.length) {
      if (coverLetterIndex !== -1) {
        pages.splice(coverLetterIndex, 1);
      }

      const coverLettersFiltered = this.setupService.filterCoverLettersRequired(coverLettersRequired, pages);

      coverLettersFiltered.forEach((letter: any) => {
        if (letter && letter.customFields) {
          pages.unshift(this.setupService.getConfigLikeObject(letter));
        }
      });
    }

    return pages;
  }

  private getAvailableDependentPages(
    uiId: string,
    dependentPages: CustomPageValues[],
    dependentPagesConfig: CustomPagesConfigAdditionFields[],
    currentSaleConcept: CustomPageValues,
  ): CustomPageValues[] {
    if (dependentPagesConfig?.length) {
      const selectedDependentPageConfig = find(dependentPagesConfig, { uiId }) as CustomPagesConfigAdditionFields;

      return dependentPages.filter(page => {
        const hasConfig = selectedDependentPageConfig.pages.find(config => config.uiId === page.config.uiId);
        // return hasConfig ? hasConfig.isSelected : true;
        if(currentSaleConcept?.dependentPagesLocked || page.isPageLockedAndEnabled) {
          return true;
        } else {
          return hasConfig ? hasConfig.isSelected : true;
        }
      });
    } else {
      return dependentPages;
    }
  }

  private extendConfigByProductTable(
    pages: PageConfig[],
    coverLetter: any,
    hasCoverLetter: boolean,
    renderOnlyPages?: string[]
  ): void {
    const productTableConfig = pages.find(page => page.config.uiId === PRODUCT_TABLE_UIID);
    const enabledPageWithTableExists = this.checkExistenceOfEnabledPageWithTable(pages, coverLetter, hasCoverLetter);

    if ((productTableConfig && enabledPageWithTableExists) || renderOnlyPages?.includes(PRODUCT_TABLE_UIID)) {
      if (!productTableConfig.config.showPreview) {
        const productTablePageIndex = this.getProductTablePageIndex(pages, hasCoverLetter);
        pages.splice(productTablePageIndex, 0, productTableConfig);
      }
    }
  }

  private checkExistenceOfEnabledPageWithTable(
    pages: PageConfig[],
    coverLetter: any,
    hasCoverLetter: boolean
  ): boolean {
    return (
      !!pages.find(
        page =>
          !page.config.isSalesConcept &&
          !PAGES_WITHOUT_PRODUCT_TABLE.includes(page.config.uiId) &&
          !page.config.isSinglePolicy &&
          page.config &&
          page.config.showPreview
      ) || this.checkIfCoverLetterIsNotAllianz(coverLetter, hasCoverLetter)
    );
  }

  // if spreadsheet is the first draggable page, product table will be placed right after spreadsheet
  // otherwise
  // the first page will be product table
  private getProductTablePageIndex(pages: PageConfig[], hasCoverLetter: boolean): number {
    let productTablePageIndex;
    const firstPage = minBy(pages, page => page.pageOrder);
    const isSpreadsheetFirst = firstPage && firstPage.internalId === MENU_PAGES_IDS.spreadsheet;

    productTablePageIndex = isSpreadsheetFirst
      ? pages.findIndex(page => page.internalId === MENU_PAGES_IDS.spreadsheet) + 1
      : 0;

    productTablePageIndex = hasCoverLetter && !isSpreadsheetFirst ? productTablePageIndex + 1 : productTablePageIndex;

    return productTablePageIndex;
  }

  private checkIfCoverLetterIsNotAllianz(coverLetter: any, hasCoverLetter: boolean): boolean {
    return hasCoverLetter && !ALLIANZ_UIIDS.includes(coverLetter.uiId);
  }

  private getCustomVisualizationMetrics(config: PageConfig): string[] {
    return config.config.planMetrics.filter(metric => metric.isSelected).map(metric => metric.key);
  }
}
