import { ChangeDetectorRef, Component, HostListener, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { combineLatest, forkJoin, Observable, of, Subject, zip } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { debounceTime, delay, finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { cloneDeep, get, isEqual, isNil } from 'lodash-es';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Editor } from 'tinymce';

import { UserService } from '@assurance/um-services';
import { AlertService, ModalService as ModalServiceCommon } from '@se/common';
import { ModalRef } from '@se/common/app/modals/components/modal/modal-ref';

import {
  CUSTOM_PAGE_SETTINGS_LAYOUT_BUTTONS,
  CUSTOM_PAGE_SETTINGS_PRODUCTS_QUANTITY,
  CUSTOM_PAGE_SETTINGS_TYPES,
  CUSTOM_PAGES_LIST_URL,
  DefaultUnsupportedDataSourcesErrorMessage,
  EDITOR_FONT_SIZES,
  hintForProductsOrderFormula,
  PREVIEW_UPLOAD_CONFIG,
  PRODUCT_TYPES,
  UsedInPagesTitle,
} from '../../constants';
import {
  Carrier,
  Carriers,
  CustomElementConfig,
  CustomPage,
  EligibleProductTypes,
  IFilterBlockDataPages,
  Insert,
  InsertIds,
  StyleModal,
  WizardModalResponse,
  CustomPageVersion,
  CustomPageVersions,
  SalesConceptForDependentPage,
  UsedInItemList,
  DataSourcesConfig,
} from '@shared/models';
import { APIService, TinyEditorService, Utils } from '@shared/services';
import {
  CONTENT_TO_INSERT,
  CUSTOM_ELEMENTS_CONFIG,
  CUSTOM_PAGES_TYPES,
  INIT_CONFIG,
  INSERT_PLACEHOLDER_SELECTOR,
  INSERT_TYPES,
  KeyboardKey,
  MCE_MARKER_PATTERN,
  NO_SELECTED_ELEMENT_MSG,
  STYLE_EDITOR_SELECTOR,
  WRAP_ELEMENT,
  WRONG_SELECTED_ELEMENT_MSG,
} from '@shared/constants';
import { PlaceholdersWizardComponent } from '../../modals';
import { ACTIVE_PAGE_ON_EDIT } from '../../constants';
import { AppState } from '../../../../reducers';
import * as PlaceholdersWizardActions from '../../redux/placeholders-wizard.actions';
import * as PlaceholdersWizardSelectors from '../../redux/placeholders-wizard.selectors';
import { StyleEditComponent } from '../../modals';
import { minSelectedCheckboxes, selectedCheckboxes } from '../../utils';
import { INSERT_TYPE } from '@core/enums';
import {
  CustomPageService,
  CustomPageSettingsService,
  ManageVersionsService,
  ModalProviderService,
  PlaceholdersOrderService,
  PlaceholdersWizardService,
} from '../../services';
import { MappedCarrierPlan, MappedCustomPage } from '../../models';
import {
  ChartPlaceholder,
  CommonPlaceholderFields,
  ExtendedPlaceholderMetadata,
  ImagePlaceholder,
  Placeholder,
  PlaceholderFiles,
  ResponseType,
  VariablePlaceholder,
} from '@core/model';

@UntilDestroy()
@Component({
  selector: 'ensight-custom-page-settings',
  templateUrl: './custom-page-settings.component.html',
  styleUrls: ['./custom-page-settings.component.scss'],
})
export class CustomPageSettingsComponent implements OnInit, OnDestroy {
  customPageForm: FormGroup;
  initialValues: any;
  isSaveDisabled = true;
  isHTMLBodyChanged = false;
  salesConceptSettingsForm: FormGroup;
  dependentPageFormSettings: FormGroup;
  endPageFormSettings: FormGroup;
  customPageType: FormArray;
  pageCarriers: FormArray;
  loading = false;
  dependentPagesLoading = false;
  editorConfig = {
    ...INIT_CONFIG,
    height: 600,
    fontsize_formats: EDITOR_FONT_SIZES,
    setup: editor => {
      this.editor = editor;
      this.setupCustomElements();
    },
  };
  pageSettingsTypes = cloneDeep(CUSTOM_PAGE_SETTINGS_TYPES);
  productsQuantityValues = CUSTOM_PAGE_SETTINGS_PRODUCTS_QUANTITY;
  layoutButtons = CUSTOM_PAGE_SETTINGS_LAYOUT_BUTTONS;
  carriers: Carrier[];
  editMode: boolean;
  pageOptions: CustomPage;
  maxRangeForProductsMin: number;
  minRangeForProductMax: number;
  note: FormControl;
  showCarriers = false;
  salesConceptSettingsShown: boolean;
  dependentPageFormShown: boolean;
  selectedCarriers = 0;
  selectedPageType = CUSTOM_PAGES_TYPES.endpage;
  previewUploadConfig = PREVIEW_UPLOAD_CONFIG as any;
  productTypes = PRODUCT_TYPES;
  eligibleProductsActive = false;
  eligibleDataSources = false;
  dataSourcesConfig: DataSourcesConfig[] = [];
  dependentPages: IFilterBlockDataPages[] = [];
  selectedDependentPages: IFilterBlockDataPages[] = [];
  customPageVersions: CustomPageVersions;
  isShowVersions = false;
  pageTypes = CUSTOM_PAGES_TYPES;
  CUSTOM_PAGES_LIST_URL = CUSTOM_PAGES_LIST_URL;
  listOfSalesConceptsForDependentPage: SalesConceptForDependentPage[] = [];
  getPlaceholders$ = this.store.pipe(
    select(PlaceholdersWizardSelectors.placeholdersMetadata),
    map(placeholders => placeholders.filter(placeholder => !placeholder.delete))
  );
  hintForProductsOrderFormula = hintForProductsOrderFormula;

  private isOpen: boolean;
  private editor: Editor;
  private previewFileRemoved: boolean;
  private selectionMode = false;
  private previewFile: File;
  private plansConfigData: MappedCarrierPlan[];
  private plansConfigMeta: MappedCarrierPlan[];
  private insertIds: { [insertIds: string]: string };
  private editorSelectedDomElement: Element;
  private deletedInserts: string[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private fb: FormBuilder,
    private apiService: APIService,
    private userService: UserService,
    private route: ActivatedRoute,
    private customPageSettingsService: CustomPageSettingsService,
    private store: Store<AppState>,
    private placeholdersWizardService: PlaceholdersWizardService,
    private alertService: AlertService,
    private modalServiceCommon: ModalServiceCommon,
    private utils: Utils,
    private modalProvider: ModalProviderService,
    private zone: NgZone,
    private placeholderOrderService: PlaceholdersOrderService,
    private customPageService: CustomPageService,
    private location: Location,
    private manageVersionService: ManageVersionsService,
    private titleService: Title
  ) {}

  @HostListener('window:keyup', ['$event']) saveOnEnter(event: KeyboardEvent) {
    if (event.key === KeyboardKey.Enter && !this.isOpen) {
      this.savePage();
    }
  }

  ngOnInit(): void {
    this.note = new FormControl('');
    this.watchForOpen();
    this.watchForRoute();
    this.getPlansConfig();
  }

  ngOnDestroy(): void {
    this.store.dispatch(PlaceholdersWizardActions.setPlaceholdersDataSuccess({ data: [], metadata: [] }));
  }

  toggleDataSourcesSection(): void {
    this.eligibleDataSources = !this.eligibleDataSources;
    const dataSourceControl = this.salesConceptSettingsForm.get('eligibleDataSourcesConfig') as FormArray;
    const errorMessageControl = this.salesConceptSettingsForm.get('unsupportedDataSourcesErrorMessage') as FormControl;

    if (!dataSourceControl || !errorMessageControl) {
      return;
    }

    if (!this.eligibleDataSources) {
      this.dataSourcesConfig = this.dataSourcesConfig.map(dataSource => ({
        ...dataSource,
        selected: false,
      }));
      dataSourceControl.controls.forEach(control => control.setValue(false));
      dataSourceControl.setValidators([]);

      errorMessageControl.setValue('');
      errorMessageControl.clearValidators();
    } else {
      if (!this.dataSourcesConfig.length) {
        this.getListOfDataSourcesConfig();
      }

      dataSourceControl.setValidators([selectedCheckboxes(this.selectedPageType)]);

      errorMessageControl.setValue(DefaultUnsupportedDataSourcesErrorMessage);
      errorMessageControl.setValidators(Validators.required);
    }

    dataSourceControl.updateValueAndValidity();
    errorMessageControl.updateValueAndValidity();
  }

  toggleProductsSection(): void {
    this.eligibleProductsActive = !this.eligibleProductsActive;
    const productTypesControl = this.salesConceptSettingsForm.controls.eligibleProductTypes;

    if (!this.eligibleProductsActive) {
      this.productTypes = this.productTypes.map(type => ({
        ...type,
        selected: false,
      }));

      this.salesConceptSettingsForm.get('eligibleProductTypes').setValidators([]);
      productTypesControl.updateValueAndValidity();
    } else {
      this.setEligibleProductTypes();
      this.salesConceptSettingsForm
        .get('eligibleProductTypes')
        .setValidators([selectedCheckboxes(this.selectedPageType)]);
      productTypesControl.updateValueAndValidity();
    }
  }

  handleProductTypeSelection(): void {
    const checkboxArray = this.salesConceptSettingsForm.controls.eligibleProductTypes.value;
    this.productTypes = this.productTypes.map((product, i) => ({
      ...product,
      selected: checkboxArray[i],
    }));
  }

  handleDataSourceSelection(): void {
    const checkboxArray = this.salesConceptSettingsForm.controls.eligibleDataSourcesConfig.value;
    this.dataSourcesConfig = this.dataSourcesConfig.map((dataSource, i) => ({
      ...dataSource,
      selected: checkboxArray[i],
    }));
  }

  handlePlaceholdersOrder(direction: string, placeholders, id): void {
    // todo: fix issue with non accessible properties to avoid use of parse/stringify
    placeholders = JSON.parse(JSON.stringify(placeholders.filter(pl => !pl.delete)));
    const selectedPlaceholder = placeholders.find(placeholder => placeholder.id === id);
    const index = placeholders.indexOf(selectedPlaceholder);
    const swapIndex = direction === 'up' ? index - 1 : index + 1;

    const swapIndexOrder = placeholders[swapIndex].order;
    placeholders[index] = placeholders[swapIndex];
    placeholders[index].order = selectedPlaceholder.order;
    placeholders[swapIndex] = selectedPlaceholder;
    placeholders[swapIndex].order = swapIndexOrder;
    this.store.dispatch(
      PlaceholdersWizardActions.setPlaceholdersDataSuccess({
        metadata: this.placeholderOrderService.handleOrdering(placeholders),
      })
    );
  }

  addPreviewImage(fileObj): void {
    this.previewFile = fileObj && fileObj.file;
    this.isSaveDisabled = false;
  }

  removePreviewImage(): void {
    this.previewFile = null;
    this.previewFileRemoved = true;
    this.isSaveDisabled = true;

    if (this.pageOptions?.customFields?.previewFilePath) {
      this.isSaveDisabled = false;
    }
  }

  onCustomPageTypeSelect(page: IFilterBlockDataPages): void {
    this.selectedPageType = page.id;
    this.pageSettingsTypes.forEach(item => (item.selected = item.id === page.id));
    this.isShowVersions = page.id !== CUSTOM_PAGES_TYPES.endpage && this.editMode && !!this.customPageVersions;

    if (page.id !== CUSTOM_PAGES_TYPES.endpage || page.id !== CUSTOM_PAGES_TYPES.salesconcept) {
      this.disableCarriers();
    }

    if (page.id !== CUSTOM_PAGES_TYPES.salesconcept) {
      this.handleSalesConceptSettingsGroup(false);
    }

    switch (page.id) {
      case CUSTOM_PAGES_TYPES.endpage:
        this.getPageCarriers();
        this.handleDependentPageForm(false);
        this.handleEndPagesSettingsGroup();
        break;
      case CUSTOM_PAGES_TYPES.dependentpage:
        this.handleDependentPageForm(true);
        break;
      case CUSTOM_PAGES_TYPES.salesconcept:
        this.handleSalesConceptSettingsGroup(true);
        this.handleDependentPageForm(false);
        break;
    }

    this.cdr.markForCheck();
  }

  onCarrierSelect(): void {
    this.selectedCarriers = this.pageCarriers.value.filter((val: boolean) => val).length;
  }

  savePage(): void {
    if (this.customPageForm.invalid || (this.editMode && !this.pageOptions)) {
      return;
    }

    this.customPageService.setCurrentCustomPageAndVersions(null);
    this.loading = true;
    this.savePlaceholders();
  }

  closeSettings(): void {
    this.editMode ? this.navigateToViewCustomPage(this.pageOptions._id) : this.leaveCustomPage();
  }

  editPlaceholder(placeholder: Placeholder): void {
    this.store.dispatch(
      PlaceholdersWizardActions.setInsertTypeStates({
        id: placeholder.id,
        insertType: placeholder.insertType,
        activePageId: ACTIVE_PAGE_ON_EDIT[placeholder.insertType],
      })
    );

    this.openPlaceholdersWizardModal({
      id: placeholder.id,
      insertType: placeholder.insertType,
      editMode: true,
    })
      .pipe(untilDestroyed(this))
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .subscribe((data?: WizardModalResponse) => {
        if (!data) return;

        if (placeholder.hidden === data.hidden) {
          if (data.hidden) return;

          const newHtml = this.customPageSettingsService.replaceTextContent(
            data.id,
            this.customPageForm.value.htmlBody,
            data,
            placeholder.insertType
          );
          this.isHTMLBodyChanged = true;
          this.customPageForm.controls.htmlBody.setValue(newHtml);

          return;
        }

        if (data.hidden) {
          this.removePlaceholderFromHtml(data.id);

          return;
        }

        this.createHtmlPlaceholder(
          data,
          CUSTOM_ELEMENTS_CONFIG.find(element => element.openWizard),
          this.editor
        );
      });
  }

  deletePlaceholder(placeholder: Placeholder): void {
    this.placeholdersWizardService.deletePlaceholderStateById(placeholder).pipe(untilDestroyed(this)).subscribe();
    this.removePlaceholderFromHtml(placeholder.id);
    this.deletedInserts.push(placeholder.id);
  }

  openDependentPagesModal(): void {
    const valuesBefore = cloneDeep(this.selectedDependentPages);
    const modal = this.modalProvider.openDependentPagesModal({ dependentPages: this.dependentPages });

    modal.afterClosed.pipe(first(), untilDestroyed(this)).subscribe(res => {
      if (res.submitted) {
        const selectedPages = res.selectedPages.filter(item => item.selected);

        if (!isEqual(valuesBefore, selectedPages)) {
          this.isSaveDisabled = false;
        }

        this.selectedDependentPages = selectedPages;
        this.cdr.markForCheck();
      }
    });
  }

  onSelectLayout(event: Record<string, string>): void {
    this.layoutButtons = this.layoutButtons.map(item => ({
      ...item,
      active: event.key === item.key,
    }));
    this.isSaveDisabled = false;
  }

  addPlaceholder(placeholder: ExtendedPlaceholderMetadata): void {
    const data: WizardModalResponse = {
      id: placeholder.id,
      type: INSERT_TYPES.find(item => item.type === placeholder.insertType).label,
      name: (placeholder as ChartPlaceholder).chartName || placeholder.placeholderName || 'Product description',
      height: (placeholder as ImagePlaceholder).height,
      width: (placeholder as ImagePlaceholder).width,
      hidden: (placeholder as VariablePlaceholder).hidden,
      hiddenOnSharedView: (placeholder as VariablePlaceholder).hiddenOnSharedView,
    };

    const config = {
      type: 'basicInsertButton',
      openWizard: true,
      config: {
        text: 'Insert...',
        tooltip: 'Inserts',
      },
    };

    this.createHtmlPlaceholder(data, config, this.editor);
  }

  openUsedInSalesConceptsModal(placeholder: any): void {
    if (placeholder.usedInPages.length) {
      const insertName = placeholder.placeholderName || placeholder.chartName || 'Product Description';

      this.modalProvider.openUsedInModal({
        name: `${insertName} ${UsedInPagesTitle}`,
        itemList: this.addLinkUsedInSalesConcepts(placeholder.usedInPages),
      });
    }
  }

  private addLinkUsedInSalesConcepts(placeholder: any[]): UsedInItemList[] {
    return placeholder.map(customPage => ({
      ...customPage,
      link: `${CUSTOM_PAGES_LIST_URL}/${customPage._id}/edit`,
      versionName: `${customPage.versionName}`,
    }));
  }

  private disableCarriers(): void {
    this.showCarriers = false;
    this.selectedCarriers = 0;
    this.customPageForm.removeControl('pageCarriers');
  }

  private getDataFromFormArray(control: FormArray, property: string, array: Carrier[]): string[] {
    return (
      control?.value.reduce((acc: string[], val: boolean, index: number) => {
        if (val) {
          acc.push(array[index][property]);
        }

        return acc;
      }, []) || []
    );
  }

  private getPageCarriers(): void {
    if (!this.showCarriers) {
      this.getCarriersList()
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.customPageForm.addControl('pageCarriers', this.createCarriersFormArray());
          this.pageCarriers = this.customPageForm.get('pageCarriers') as FormArray;
          this.showCarriers = true;
          this.cdr.markForCheck();
        });
    } else {
      this.showCarriers = false;
      this.cdr.markForCheck();
    }
  }

  private setEligibleProductTypes(): void {
    const eligibleProductTypes = get(this, 'pageOptions.customFields.eligibleProductTypes', []);

    if (eligibleProductTypes?.length) {
      const types = eligibleProductTypes.map(product => product.productType);
      this.productTypes = this.productTypes.map(type =>
        types.includes(type.productType) ? { ...type, selected: true } : type
      );
    }
  }

  private getProductTypesData(): EligibleProductTypes[] {
    return this.productTypes
      .filter(type => type.selected)
      .map(productType => ({
        label: productType.label,
        productType: productType.productType,
      }));
  }

  private getDataSources(): string[] {
    return this.dataSourcesConfig.filter(type => type.selected).map(productType => productType.key);
  }

  private openStylesModal(args: { data: StyleModal }): { dialogRef: ModalRef; eventBus: Subject<unknown> } {
    const config = { autoFocus: true, customFooter: true, headerTitle: 'Style element' };
    const dialogData = { component: StyleEditComponent, data: args };

    this.utils.fireRefreshEventOnWindow();

    return this.modalServiceCommon.open(config, dialogData);
  }

  private leaveCustomPage(): void {
    this.router.navigate([CUSTOM_PAGES_LIST_URL]);
  }

  private getCarriersList(): Observable<boolean | ResponseType<Carriers>> {
    if (!this.carriers) {
      this.loading = true;

      return this.apiService.getCarriersList().pipe(
        tap((response: ResponseType<Carriers>) => {
          this.loading = false;
          this.carriers =
            response &&
            response.data.data.map((carrier: Carrier) => ({
              ...carrier,
              name: `${carrier.name} (${carrier.code})`,
            }));
        })
      );
    }

    return of(true);
  }

  private getSelectedDependentPages(): IFilterBlockDataPages[] {
    return this.selectedDependentPages.map(item => ({ uiId: item.id, order: item.order }));
  }

  private savePlaceholders(): void {
    combineLatest([
      this.store.pipe(select(PlaceholdersWizardSelectors.placeholdersMetadata)),
      this.store.pipe(select(PlaceholdersWizardSelectors.getPersistImagesList)),
      this.store.pipe(select(PlaceholdersWizardSelectors.getSelectedInserts)),
    ])
      .pipe(
        first(),
        untilDestroyed(this),
        switchMap(([placeholders, persistImagesList, selectedReusedInserts]) => {
          //TODO: need to refact
          return Object.keys(persistImagesList).length
            ? forkJoin(
                placeholders.map(placeholder => {
                  const id = placeholder.id;
                  const imagesList = persistImagesList[id];

                  if (!imagesList) {
                    return of({ id, files: [], filesToDelete: [] });
                  }

                  const uploadImagesList = imagesList.filter(image => image.upload);
                  const imagesLinksList = imagesList
                    .filter(image => !image.upload && !image.delete)
                    .map(image => image.nativeUrl);
                  const filesToDelete = imagesList.filter(image => image.delete).map(image => image.nativeUrl);

                  return uploadImagesList.length
                    ? forkJoin(
                        uploadImagesList.map(image =>
                          this.apiService.downloadFileFromObjectURL(image.url).pipe(
                            map(response => {
                              const blobFile = (response.body as any).data;

                              return new File([blobFile], image.name, {
                                type: image.type,
                              });
                            })
                          )
                        )
                      ).pipe(
                        map(files => ({
                          id,
                          files,
                          filesToDelete,
                          imagesLinksList,
                        }))
                      )
                    : of({ id, files: [], filesToDelete, imagesLinksList });
                })
              ).pipe(map(filesData => [placeholders, filesData, selectedReusedInserts]))
            : of([placeholders, [], selectedReusedInserts]);
        }),
        switchMap(([placeholders, filesData, selectedReusedInserts]) => {
          const requests = this.saveRequestsForPlaceholders(placeholders, filesData, selectedReusedInserts);

          return requests.length ? forkJoin(requests) : of([]);
        }),
        switchMap(() => this.saveCustomPage()),
        switchMap((customPage: CustomPage) => this.deleteInserts(customPage)),
        finalize(() => {
          this.loading = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe((customPage: CustomPage) => this.navigateToViewCustomPage(customPage?._id, { successfulSave: true }));
  }

  private saveCustomPage(): Observable<CustomPage> {
    return combineLatest([
      this.customPageService.handlePreviewImage(
        this.pageOptions?.customFields?.previewFilePath,
        this.previewFile,
        this.previewFileRemoved
      ),
      this.store.pipe(select(PlaceholdersWizardSelectors.placeholdersMetadata)),
    ]).pipe(
      first(),
      map(([res, placeholders]) => {
        const placeholdersIds = placeholders
          .filter(placeholder => !placeholder.delete)
          .map(placeholder => ({ order: placeholder.order, uuid: placeholder.id, _id: placeholder._id }));

        return { location: get(res, 'data.location'), placeholdersIds };
      }),
      switchMap((data: { location: string; placeholdersIds: InsertIds[] }) =>
        this.saveRequestForCustomPage(data.location, data.placeholdersIds)
      )
    );
  }

  private deleteInserts(customPage: CustomPage): Observable<CustomPage> {
    return this.deletedInserts?.length
      ? this.customPageService.deleteInsert(this.deletedInserts.join(','), customPage?._id).pipe(
          switchMap(() => of(customPage)),
          untilDestroyed(this)
        )
      : of(customPage).pipe(untilDestroyed(this));
  }

  private watchForRoute(): void {
    zip(this.route.data, this.route.paramMap)
      .pipe(
        tap(() => (this.loading = true)),
        switchMap(([data, params]) => {
          this.editMode = data.editMode;

          return this.editMode ? this.getCustomPageAndVersions(params.get('id')) : of(null);
        }),
        untilDestroyed(this)
      )
      .subscribe(
        () => {
          this.editMode
            ? this.titleService.setTitle(this.pageOptions.name)
            : this.titleService.setTitle('Create Custom Page');
          this.editMode ? this.createEditForm() : this.createNewForm();
          this.loading = false;
          this.cdr.markForCheck();
        },
        () => this.leaveCustomPage()
      );
  }

  private getCustomPageAndVersions(id: string): Observable<MappedCustomPage> {
    return this.customPageService.getCustomPageAndVersions(id).pipe(
      tap((data: MappedCustomPage) => {
        this.customPageVersions = data.customPageVersions;
        this.setReceivedPageData(data.customPage);
      })
    );
  }

  private getListOfDataSourcesConfig() {
    this.customPageService
      .getDataSourceList()
      .pipe(untilDestroyed(this))
      .subscribe((data: DataSourcesConfig[]) => {
        this.setEligibleDataSources(data);
      });
  }

  private setReceivedPageData(customPage: CustomPage): void {
    this.store.dispatch(PlaceholdersWizardActions.setCurrentCustomPage({ currentCustomPage: customPage }));
    this.pageOptions = customPage;
    this.isShowVersions = this.customPageSettingsService.isNotIncludeEndPage(customPage.labels);
    const placeholders = [];
    this.insertIds = customPage.inserts.reduce((res, insert: Insert) => {
      if (insert.metadata) {
        const { _id } = customPage.customFields.insertIds.find(insrt => insrt.uuid === insert.metadata.id);
        const usedInPages = insert.usedInPages;

        placeholders.push({ create: false, edit: false, delete: false, ...insert.metadata, _id, usedInPages });
        res[insert.metadata.id] = insert._id;
      }

      return res;
    }, {});

    const metadata = this.placeholderOrderService.handleOrdering(placeholders);
    this.store.dispatch(PlaceholdersWizardActions.setPlaceholdersDataSuccess({ data: customPage.inserts, metadata }));
  }

  private removePlaceholderFromHtml(id: string): void {
    const newHtml = this.customPageSettingsService.removePlaceholder(id, this.customPageForm.value.htmlBody);
    this.isHTMLBodyChanged = true;

    this.customPageForm.controls.htmlBody.setValue(newHtml);
  }

  private saveRequestsForPlaceholders(
    placeholders: Placeholder[],
    filesData: PlaceholderFiles[],
    selectedReusedInserts: Insert[]
  ): Observable<Insert>[] {
    const requests = [];
    // TODO: all inserts are sent, even if there have been no changes to them. Need to change. There are additional requests

    for (const placeholder of placeholders) {
      const placeholderFilesData = filesData.find(item => item.id === placeholder.id);

      if (placeholder.delete && !placeholder.reused) {
        requests.push(this.customPageService.deleteInsert(this.insertIds[placeholder.id], this.pageOptions.uiId));

        if (placeholder.insertType === INSERT_TYPE.text || placeholder.insertType === INSERT_TYPE.variable)
          requests.push(
            this.apiService.removeDependentPageConfig(placeholder.id, {
              insert: true,
            })
          );
      } else if (placeholder.create && !placeholder.reused) {
        requests.push(this.customPageService.createInsert(placeholder, placeholderFilesData));
        // TODO: need to change/refatc, reason is inserts send in case of no changes
      } else if (placeholder.edit) {
        // we need to check and get selected reused inserts in case of editing reused insert when creating custom page
        const found = placeholder.reused
          ? selectedReusedInserts?.find((insert: Insert) => insert.metadata.id === placeholder.id)
          : null;
        const id = found ? found._id : this.insertIds[placeholder.id];
        requests.push(this.customPageService.updateInsert(placeholder, placeholderFilesData, id));
      }
    }

    return requests;
  }

  private openPlaceholdersWizardModal(args?: CommonPlaceholderFields): Observable<WizardModalResponse> {
    const config = { customHeader: true, customFooter: true, noOverflow: true, maxWidth: 'auto' };
    const data = {
      component: PlaceholdersWizardComponent,
      data: { plansConfigData: this.plansConfigData, ...args, plansConfigMeta: this.plansConfigMeta },
    };

    const { eventBus, dialogRef } = this.modalServiceCommon.open(config, data);

    return eventBus.pipe(
      // @ts-ignore
      first(),
      tap(() => dialogRef.close())
    );
  }

  private watchForOpen(): void {
    this.store
      .select(PlaceholdersWizardSelectors.selectDynamicFormOpen)
      .pipe(untilDestroyed(this))
      .subscribe((isOpen: boolean) => (this.isOpen = isOpen));
  }

  private getDependentPagesData(): void {
    // dependent pages of current page
    const pages = this.pageOptions?.customFields.dependentPages || [];
    const addedDate = this.getAddedDateToGetDependentPages();
    this.dependentPagesLoading = true;

    this.customPageService
      .getDependentPages(pages, addedDate)
      .pipe(untilDestroyed(this))
      .subscribe((data: IFilterBlockDataPages[]) => {
        this.dependentPages = data;
        this.selectedDependentPages = data.filter(item => item.selected);
        this.dependentPagesLoading = false;
        this.cdr.markForCheck();
      });
  }

  private getAddedDateToGetDependentPages(): string {
    const currentVersion =
      this.customPageVersions &&
      this.customPageVersions.versions.find((version: CustomPageVersion) => version.pageId === this.pageOptions._id);

    return this.manageVersionService.setTimeZone(currentVersion?.startDate || new Date().toString());
  }

  private saveRequestForCustomPage(location: string, placeholdersIds: InsertIds[]): Observable<CustomPage> {
    const dataToSave = this.getFormDataToSave(location, placeholdersIds);

    return this.editMode
      ? this.customPageService.updateCustomPage(dataToSave, this.pageOptions._id)
      : this.customPageService.createCustomPage(dataToSave);
  }

  private getPlansConfig(): void {
    this.customPageService
      .getPlansConfig()
      .pipe(untilDestroyed(this))
      .subscribe((result: MappedCarrierPlan[][]) => {
        const [data, meta] = result;

        this.plansConfigData = data;
        this.plansConfigMeta = meta;
      });
  }

  private navigateToViewCustomPage(id: string, state: Record<string, boolean> = {}) {
    this.store.dispatch(PlaceholdersWizardActions.setPlaceholdersDataSuccess({ data: [], metadata: [] }));
    this.store.dispatch(PlaceholdersWizardActions.resetPlaceholderWizard());
    this.router.navigate([`${CUSTOM_PAGES_LIST_URL}/${id || ''}`], { state });
  }

  private getFormDataToSave(previewImagePath?: string, placeholdersIds?: InsertIds[]): FormData {
    const formDataToSave = new FormData();
    const selectedLayout = this.layoutButtons.find(item => item.active);

    //TODO: get value of form instead of getting each time call this.customPageForm.value

    let htmlBody = this.customPageForm.value.htmlBody.replace(MCE_MARKER_PATTERN, '');
    htmlBody = this.customPageSettingsService.addAttributesToAnchors(htmlBody);

    formDataToSave.append('name', this.customPageForm.value.name);
    formDataToSave.append('label', this.customPageForm.value.label);
    formDataToSave.append('customFields.htmlBody', htmlBody);
    formDataToSave.append('comment', this.note.value);
    formDataToSave.append('customFields.title', this.customPageForm.value.name);
    formDataToSave.append('customFields.layout', selectedLayout?.key);
    formDataToSave.append('portal', 'dvp');
    formDataToSave.append('type', this.editMode ? this.pageOptions.type : CUSTOM_PAGES_TYPES.endpage);
    formDataToSave.append(
      'agencyId',
      this.editMode ? `${this.pageOptions.agencyId}` : `${this.userService.organization.id}`
    );
    formDataToSave.append('userId', this.editMode ? `${this.pageOptions.userId}` : `${this.userService.user.id}`);
    formDataToSave.append('labels', JSON.stringify([this.selectedPageType]));
    formDataToSave.append('urls', JSON.stringify(this.editMode ? this.pageOptions.urls : []));

    if (placeholdersIds) {
      formDataToSave.append('customFields.insertIds', JSON.stringify(placeholdersIds));
    }

    const controls = this.customPageForm.controls;

    if (controls.pageCarriers) {
      formDataToSave.append(
        'customFields.carrierUiIds',
        JSON.stringify(this.getDataFromFormArray(this.pageCarriers, 'code', this.carriers))
      );
    }

    if (controls.salesConceptSettings) {
      formDataToSave.append('salesConceptLabel', this.customPageForm.value.salesConceptLabel);
      formDataToSave.append('customFields.eligibleProductTypes', JSON.stringify(this.getProductTypesData()));
      formDataToSave.append(
        'customFields.eligibleProductsActive',
        controls.salesConceptSettings.get('eligibleProductsActive').value || false
      );
      formDataToSave.append(
        'customFields.isEligibleForDataSources',
        controls.salesConceptSettings.get('eligibleDataSources').value || false
      );
      formDataToSave.append('customFields.eligibleDataSources', JSON.stringify(this.getDataSources()));

      formDataToSave.append(
        'customFields.unsupportedDataSourcesErrorMessage',
        controls.salesConceptSettings.get('unsupportedDataSourcesErrorMessage').value || null
      );

      formDataToSave.append(
        'customFields.dependentPagesLocked',
        controls.salesConceptSettings.get('dependentPagesLocked').value
      );
      formDataToSave.append(
        'customFields.isPageLockedAndEnabled',
        controls.salesConceptSettings.get('isPageLockedAndEnabled').value
      );
      formDataToSave.append(
        'customFields.isEligibleForCarriers',
        controls.salesConceptSettings.get('isEligibleForCarriers').value
      );
      formDataToSave.append('customFields.dependentPages', JSON.stringify(this.getSelectedDependentPages()));
      formDataToSave.append('customFields.productsMin', controls.salesConceptSettings.get('productsMin').value);

      formDataToSave.append('customFields.productsMax', controls.salesConceptSettings.get('productsMax').value);

      if (controls.salesConceptSettings.get('productsOrderFormula').value) {
        formDataToSave.append(
          'customFields.productsOrderFormula',
          controls.salesConceptSettings.get('productsOrderFormula').value
        );
      }

      if (controls.salesConceptSettings.get('insufficientProductsNumberErrorMessage').value) {
        formDataToSave.append(
          'customFields.insufficientProductsNumberErrorMessage',
          controls.salesConceptSettings.get('insufficientProductsNumberErrorMessage').value
        );
      }

      if (controls.salesConceptSettings.get('description').value) {
        formDataToSave.append('description', controls.salesConceptSettings.get('description').value);
      }

      previewImagePath && formDataToSave.append('customFields.previewFilePath', previewImagePath);
    }

    if (this.editMode) {
      formDataToSave.append('customFields.uiId', this.pageOptions.uiId);
    }

    if (controls.dependentPageFormSettings) {
      formDataToSave.append('customFields.disabled', controls.dependentPageFormSettings.get('disabled').value);
      formDataToSave.append(
        'customFields.showHideFormula',
        controls.dependentPageFormSettings.get('showHideFormula').value
      );
      formDataToSave.append(
        'customFields.productsOrderFormula',
        controls.dependentPageFormSettings.get('productsOrderFormula').value
      );
      formDataToSave.append(
        'customFields.isPageLockedAndEnabled',
        controls.dependentPageFormSettings.get('isPageLockedAndEnabled').value
      );
    }

    if (controls.endPageFormSettings) {
      formDataToSave.append('customFields.hideOnPDF', controls.endPageFormSettings.get('hideOnPDF').value);
      formDataToSave.append(
        'customFields.hideOnShareableLink',
        controls.endPageFormSettings.get('hideOnShareableLink').value
      );
      formDataToSave.append('customFields.orderRank', controls.endPageFormSettings.get('orderRank').value);
    }

    return formDataToSave;
  }

  private createCarriersFormArray(): FormArray {
    const carrierUiIds = this.pageOptions?.customFields?.carrierUiIds;

    return this.fb.array(
      carrierUiIds
        ? this.carriers.map((carrier: Carrier) => new FormControl(carrierUiIds.includes(carrier.code)))
        : this.carriers.map(() => new FormControl(false)),
      [Validators.required, selectedCheckboxes(this.selectedPageType)]
    );
  }

  private getCustomPageTypeArray(): FormArray {
    const controlsArray = this.pageSettingsTypes.map((item: IFilterBlockDataPages) => new FormControl(item.selected));

    if (get(this, 'pageOptions.labels')) {
      this.pageSettingsTypes.forEach(item => (item.selected = this.pageOptions.labels.includes(item.id)));
      this.selectedPageType = this.pageSettingsTypes.find(item => item.selected)?.id;
    } else {
      this.getPageCarriers();
    }

    return this.fb.array(controlsArray, minSelectedCheckboxes());
  }

  private createEditForm(): void {
    this.customPageForm = this.fb.group({
      name: [this.pageOptions.name, [Validators.required, Validators.maxLength(100)]],
      label: [this.pageOptions.label, [Validators.required, Validators.maxLength(100)]],
      versionId: [this.pageOptions._id],
      customPageType: this.getCustomPageTypeArray(),
      htmlBody: [this.pageOptions.customFields.htmlBody, [Validators.required]],
    });

    this.customPageType = this.customPageForm.get('customPageType') as FormArray;
    this.layoutButtons = this.layoutButtons.map(item => ({
      ...item,
      active: item.key === this.pageOptions.customFields.layout,
    }));

    if (this.pageOptions.labels.includes(CUSTOM_PAGES_TYPES.salesconcept)) {
      this.handleSalesConceptSettingsGroup(true);
    }

    if (this.pageOptions.labels.includes(CUSTOM_PAGES_TYPES.dependentpage)) {
      this.handleDependentPageForm(true);
      this.customPageService
        .getSalesConceptsForDependentPage(this.pageOptions._id)
        .pipe(untilDestroyed(this))
        .subscribe((data: SalesConceptForDependentPage[]) => {
          this.listOfSalesConceptsForDependentPage = data;
        });
    }

    if (this.pageOptions.labels.includes(CUSTOM_PAGES_TYPES.endpage)) {
      this.handleEndPagesSettingsGroup();
    }

    if (
      (this.pageOptions.customFields.carrierUiIds && this.pageOptions.customFields.carrierUiIds.length) ||
      this.pageOptions.labels.includes(CUSTOM_PAGES_TYPES.endpage) ||
      (this.pageOptions.labels.includes(CUSTOM_PAGES_TYPES.salesconcept) &&
        this.pageOptions.customFields.isEligibleForCarriers)
    ) {
      this.getPageCarriers();
      this.selectedCarriers = this.pageOptions.customFields.carrierUiIds.length;
    }

    this.initialValues = { ...this.customPageForm.value };

    if (this.editMode) {
      this.customPageForm.valueChanges.subscribe(() => {
        const controls = this.customPageForm.controls;
        const excludedKeys = ['htmlBody', 'customPageType'];

        this.isSaveDisabled = !Object.keys(controls).some(
          key =>
            !excludedKeys.includes(key) && controls[key].value !== this.initialValues[key] && !controls[key].pristine
        );
      });
    }

    this.watchForVersion();
  }

  private createNewForm(): void {
    this.customPageForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      label: ['', [Validators.required, Validators.maxLength(100)]],
      customPageType: this.getCustomPageTypeArray(),
      htmlBody: ['', [Validators.required]],
    });
    this.customPageType = this.customPageForm.get('customPageType') as FormArray;

    this.handleEndPagesSettingsGroup();
  }

  private watchForVersion(): void {
    this.customPageForm
      .get('versionId')
      .valueChanges.pipe(
        untilDestroyed(this),
        tap(() => (this.loading = true)),
        tap(() => (this.salesConceptSettingsForm = null)),
        switchMap((value: string) => this.customPageService.getCustomPage(value)),
        tap((data: CustomPage) => this.location.replaceState(`${CUSTOM_PAGES_LIST_URL}/${data._id}/edit`)),
        tap((data: CustomPage) => this.setReceivedPageData(data)),
        tap(() => this.getDependentPagesData()),
        delay(500),
        tap(() => this.createEditForm())
      )
      .subscribe(() => {
        this.loading = false;
        this.cdr.markForCheck();
      });
  }

  private handleSalesConceptSettingsGroup(checked: boolean = this.salesConceptSettingsShown): void {
    this.salesConceptSettingsShown = checked;

    if (!this.dependentPages.length && checked) {
      this.getDependentPagesData();
    }

    this.customPageForm.addControl(
      'salesConceptLabel',
      this.fb.control(get(this, 'pageOptions.salesConceptLabel', ''), [Validators.maxLength(100), Validators.required])
    );

    this.customPageForm.removeControl('endPageFormSettings');

    if (!this.salesConceptSettingsShown) {
      this.customPageForm.removeControl('salesConceptLabel');
      this.salesConceptSettingsForm?.disable();
      this.removePreviewImage();

      return;
    }

    if (this.salesConceptSettingsForm) {
      this.salesConceptSettingsForm.enable();

      return;
    }

    const productsMin = get(this, 'pageOptions.customFields.productsMin') || this.productsQuantityValues.min;
    const productsMax = get(this, 'pageOptions.customFields.productsMax') || this.productsQuantityValues.min;
    const dependentPagesLocked = get(this, 'pageOptions.customFields.dependentPagesLocked') || false;
    const isPageLockedAndEnabled = get(this, 'pageOptions.customFields.isPageLockedAndEnabled') || false;
    const isEligibleForCarriers = get(this, 'pageOptions.customFields.isEligibleForCarriers') || false;
    const eligibleProductsActive = get(this, 'pageOptions.customFields.eligibleProductsActive', false);
    const eligibleDataSources = get(this, 'pageOptions.customFields.isEligibleForDataSources', false);

    this.eligibleProductsActive = eligibleProductsActive;
    this.setEligibleProductTypes();
    this.eligibleDataSources = eligibleDataSources;

    this.salesConceptSettingsForm = this.fb.group({
      dependentPagesLocked: [dependentPagesLocked, [Validators.required]],
      isEligibleForCarriers: [isEligibleForCarriers, [Validators.required]],
      isPageLockedAndEnabled: [isPageLockedAndEnabled],
      eligibleProductsActive,
      eligibleProductTypes: this.fb.array(this.productTypes.map(type => new FormControl(type.selected))),
      eligibleDataSources,
      eligibleDataSourcesConfig: this.fb.array([]),
      productsMin: [productsMin, [Validators.required]],
      productsMax: [productsMax, [Validators.required]],
      description: [get(this, 'pageOptions.description', ''), [Validators.maxLength(1000)]],
      productsOrderFormula: [get(this, 'pageOptions.customFields.productsOrderFormula', '')],
      unsupportedDataSourcesErrorMessage: [
        get(this, 'pageOptions.customFields.unsupportedDataSourcesErrorMessage', ''),
      ],
      insufficientProductsNumberErrorMessage: [
        get(this, 'pageOptions.customFields.insufficientProductsNumberErrorMessage', ''),
      ],
    });

    this.initializeDataSourcesConfig();

    if (!this.dataSourcesConfig.length) {
      this.getListOfDataSourcesConfig();
    }

    if (eligibleDataSources) {
      this.salesConceptSettingsForm.get('unsupportedDataSourcesErrorMessage').setValidators(Validators.required);
    }

    this.previewUploadConfig.image.src = get(this.pageOptions, 'customFields.previewFilePath');

    this.customPageForm.addControl('salesConceptSettings', this.salesConceptSettingsForm);

    this.setDynamicLimitsForProductsQuantity(productsMin, productsMax);

    this.salesConceptSettingsForm.valueChanges.pipe(debounceTime(100), untilDestroyed(this)).subscribe(res => {
      if (this.selectedPageType === CUSTOM_PAGES_TYPES.salesconcept) {
        res.isEligibleForCarriers ? this.showCarriers || this.getPageCarriers() : this.disableCarriers();
      }

      this.setDynamicLimitsForProductsQuantity(res.productsMin, res.productsMax);
    });
  }

  handleEndPagesSettingsGroup(): void {
    this.endPageFormSettings = this.fb.group({
      hideOnPDF: [get(this, 'pageOptions.customFields.hideOnPDF') || false],
      hideOnShareableLink: [get(this, 'pageOptions.customFields.hideOnShareableLink', false)],
      orderRank: [
        get(this, 'pageOptions.customFields.orderRank') || 1,
        [Validators.required, Validators.pattern(/^\d+$/), Validators.min(1)],
      ],
    });

    this.customPageForm.addControl('endPageFormSettings', this.endPageFormSettings);
  }

  private handleDependentPageForm(checked: boolean): void {
    const isPageLockedAndEnabled = get(this, 'pageOptions.customFields.isPageLockedAndEnabled') || false;
    this.dependentPageFormShown = checked;

    this.dependentPageFormSettings = this.fb.group({
      disabled: [get(this, 'pageOptions.customFields.disabled') || false],
      showHideFormula: [get(this, 'pageOptions.customFields.showHideFormula') || ''],
      productsOrderFormula: [get(this, 'pageOptions.customFields.productsOrderFormula') || ''],
      isPageLockedAndEnabled: [isPageLockedAndEnabled],
    });

    if (checked) {
      this.customPageForm.addControl('dependentPageFormSettings', this.dependentPageFormSettings);
      this.customPageForm.removeControl('endPageFormSettings');
    } else {
      this.customPageForm.removeControl('dependentPageFormSettings');
    }
  }

  private setupCustomElements(): void {
    CUSTOM_ELEMENTS_CONFIG.forEach((elementConfig: CustomElementConfig) => {
      this.editor.ui.registry.addButton(elementConfig.type, {
        ...elementConfig.config,
        onAction: () => {
          if (elementConfig.openWizard) {
            this.createPlaceholder(elementConfig);
          } else if (elementConfig.modifySelection) {
            this.switchSelection();
          } else if (elementConfig.type === STYLE_EDITOR_SELECTOR) {
            this.zone.run(() => {
              this.editStyles();
            });
          } else if (elementConfig.type === WRAP_ELEMENT) {
            this.wrapElement();
          } else {
            this.editor.insertContent(CONTENT_TO_INSERT[elementConfig.type]());
          }
        },
      });
    });

    this.editor.on('NodeChange', () => {
      if (this.selectionMode || this.isOpen) return;

      this.editorSelectedDomElement = this.editor.selection.getContent() ? this.editor.selection.getNode() : null;
    });

    this.editor?.on('change', () => {
      this.isHTMLBodyChanged = true;
    });
  }

  private wrapElement(): void {
    const content = this.editor.selection.getContent();

    if (!this.checkTags(content)) {
      const span = `<span>${content}</span>`;
      this.editor.selection.setContent(span);
    } else {
      this.alertService.openAlert({
        type: 'neutral',
        body: WRONG_SELECTED_ELEMENT_MSG,
        autoClose: 5000,
      });
    }
  }

  private checkTags(content: string): boolean {
    const tagsRegEx = /(<([^>]+)>)/gi;

    return tagsRegEx.test(content);
  }

  private editStyles(): void {
    if (!this.editorSelectedDomElement) {
      this.alertService.openAlert({
        type: 'neutral',
        body: NO_SELECTED_ELEMENT_MSG,
        autoClose: 5000,
      });

      return;
    }

    const existingStyleId = this.editorSelectedDomElement?.getAttribute('data-style-id');

    const styleElement =
      existingStyleId &&
      this.editorSelectedDomElement?.parentNode.querySelector(`style[data-style-id="${existingStyleId}"]`);

    const newStyleId = existingStyleId ? null : TinyEditorService.generateId();
    const isInsertPlaceholder = this.editorSelectedDomElement.classList.contains(INSERT_PLACEHOLDER_SELECTOR);

    this.store.dispatch(PlaceholdersWizardActions.setDynamicFormOpen({ payload: true }));
    const { eventBus, dialogRef } = this.openStylesModal({
      data: existingStyleId
        ? {
            slyleString: styleElement?.textContent || '',
          }
        : {
            slyleString: isInsertPlaceholder
              ? this.customPageSettingsService.generateSelectorForStyles(
                  newStyleId,
                  (this.editorSelectedDomElement as HTMLElement)?.dataset?.mceStyle
                )
              : `${this.editorSelectedDomElement.nodeName.toLowerCase()} {${
                  (this.editorSelectedDomElement as HTMLElement)?.dataset?.mceStyle || ''
                }}`,
          },
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    eventBus.pipe(first(), untilDestroyed(this)).subscribe((data: { success: boolean; result: string }) => {
      dialogRef.close();
      this.store.dispatch(PlaceholdersWizardActions.setDynamicFormOpen({ payload: false }));

      if (!data.success || !data.result) {
        return;
      }

      if (existingStyleId) {
        styleElement.textContent = data.result;
      } else if (isInsertPlaceholder) {
        const newStyleElement = document.createElement('style');
        newStyleElement.setAttribute('data-style-id', newStyleId);
        newStyleElement.textContent = data.result;
        this.editorSelectedDomElement.before(newStyleElement);
        this.editorSelectedDomElement.setAttribute('data-style-id', newStyleId);
        this.editorSelectedDomElement.removeAttribute('style');
        this.editorSelectedDomElement.removeAttribute('data-mce-style');
      } else {
        const result = data.result.match(/[\{](.*)[\}]/);

        if (result && !isNil(result[1])) {
          (this.editorSelectedDomElement as HTMLElement).dataset.mceStyle = result[1];
          (this.editorSelectedDomElement as HTMLElement).style.cssText = result[1];
          this.editor.selection.setContent(this.editorSelectedDomElement.outerHTML);
        }
      }

      this.isHTMLBodyChanged = true;
      this.customPageForm.controls.htmlBody.setValue(this.editor.getContent());
    });
  }

  private switchSelection(): void {
    if (this.selectionMode) {
      this.cleanListeners(this.editor);
      this.selectionMode = false;
    } else {
      this.editor.on('mousemove', e => {
        this.selectionMode = true;

        try {
          this.editor.selection.select(e.target);
          this.editorSelectedDomElement = e.target;
        } catch (err) {
          console.log('Incorect element');
        }
      });
      this.editor.on('click', () => {
        if (this.editorSelectedDomElement) {
          this.editor.selection.select(this.editorSelectedDomElement);
          this.cleanListeners(this.editor);
          this.selectionMode = false;
        }
      });
    }
  }

  private cleanListeners(editor: Editor): void {
    editor.off('mousemove');
    editor.off('click');
  }

  private createPlaceholder(elementConfig: CustomElementConfig): void {
    this.editor.insertContent('');
    // // TODO: need to change way of open the modal
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.zone
      .run<Observable<WizardModalResponse>>(() => this.openPlaceholdersWizardModal())
      .subscribe((data?: WizardModalResponse) => {
        if (!data || data.hidden) return;

        this.createHtmlPlaceholder(data, elementConfig, this.editor);
      });
  }

  private createHtmlPlaceholder(data: WizardModalResponse, elementConfig: CustomElementConfig, editor: Editor): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const type: InsertType = INSERT_TYPES.find(insertType => insertType.label === data.type)?.type;

    const content = TinyEditorService.extendContent(
      elementConfig.type,
      CONTENT_TO_INSERT[elementConfig.type](type),
      data
    );

    type === INSERT_TYPE.text || type === INSERT_TYPE.variable
      ? editor.selection.setContent(content)
      : editor.insertContent(content);
    this.isHTMLBodyChanged = true;
    this.customPageForm.controls.htmlBody.setValue(editor.getContent());
    this.cdr.markForCheck();
  }

  private setDynamicLimitsForProductsQuantity(min: number, max: number): void {
    this.maxRangeForProductsMin = max;
    this.minRangeForProductMax = min;
    this.cdr.markForCheck();
  }

  private setEligibleDataSources(data: DataSourcesConfig[]): void {
    const eligibleDataSources = get(this, 'pageOptions.customFields.eligibleDataSources', []);
    this.dataSourcesConfig = data.map(source => ({
      ...source,
      selected: eligibleDataSources.includes(source.key),
    }));

    this.initializeDataSourcesConfig();
  }

  private initializeDataSourcesConfig(): void {
    const eligibleDataSourcesConfig = this.salesConceptSettingsForm.get('eligibleDataSourcesConfig') as FormArray;
    eligibleDataSourcesConfig.clear();

    if (this.dataSourcesConfig.length) {
      this.dataSourcesConfig.forEach(config => {
        eligibleDataSourcesConfig.push(this.fb.control(config.selected));
      });
    }
  }
}
