import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { PresentationSharingComponent } from './presentation-sharing.component';
import { PresentationSharingService } from './presentation-sharing.service';
import { ButtonsModule, CommonSeModule } from '@se/common';
import { IconModule, InputModule, ModalModule } from '@assurance/bootstrap';
import { UserListModule } from '../user-list/user-list.module';

@NgModule({
  declarations: [PresentationSharingComponent],
  exports: [PresentationSharingComponent],
  imports: [
    CommonModule,
    CommonSeModule,
    ModalModule,
    InputModule,
    IconModule,
    ButtonsModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    UserListModule,
  ],
  providers: [PresentationSharingService],
})
export class PresentationSharingModule {}
