import { Injectable } from '@angular/core';

import { forkJoin, Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';

import {
  CopyCustomPage,
  CustomPage,
  DependentPage,
  HomeTableRequestParams,
  IFilterBlockDataPages,
  Insert,
  MappedPresentationCount,
  Plan,
  PlansQuantity,
  PresentationData,
  TableRequestParams,
  CustomPageVersions,
  CustomPageVersion,
  SalesConceptForDependentPage,
  DataSourcesConfig,
} from '@shared/models';
import { CustomPageApiService } from '../custom-page-api/custom-page-api.service';
import { CustomPageAdapterService } from '../custom-page-adapter/custom-page-adapter.service';
import { CustomPageSettingsService } from '../custom-page-settings/custom-page-settings.service';
import { CustomPageHistoryApiService } from '../custom-page-history-api/custom-page-history-api.service';
import { CustomPageHistoryAdapterService } from '../custom-page-history-adapter/custom-page-history-adapter.service';
import { CustomPageEditTextAdapterService } from '../custom-page-edit-text-adapter/custom-page-edit-text-adapter.service';
import {
  CarrierPlanOptional,
  CustomPageHistory,
  CustomPagesLists,
  MappedCarrierPlan,
  MappedCustomPage,
  MappedEditTextCustomPage,
  MappedHistoryCustomPage,
} from '../../models';
import { Placeholder, Presentation, Presentations, ResponseType } from '@core/model';
import { APIService, InsertsApiService } from '@shared/services';
import { getEmptyCustomPagesLists, getEmptyVersions } from '../../constants';
import { CUSTOM_PAGES_TYPES } from '@shared/constants';

@Injectable()
export class CustomPageService {
  //TODO: need to change because of have page in store
  private currentCustomPageAndVersions: MappedCustomPage;

  constructor(
    private customPageApiService: CustomPageApiService,
    private customPageAdaptService: CustomPageAdapterService,
    private insertsApiService: InsertsApiService,
    private customPageSettingService: CustomPageSettingsService,
    private apiService: APIService,
    private customPageHistoryApiService: CustomPageHistoryApiService,
    private customPageHistoryAdapterService: CustomPageHistoryAdapterService,
    private customPageEditTextAdapterService: CustomPageEditTextAdapterService
  ) {}

  getCurrentCustomPageAndVersions(): MappedCustomPage {
    return this.currentCustomPageAndVersions;
  }

  setCurrentCustomPageAndVersions(data: MappedCustomPage): void {
    this.currentCustomPageAndVersions = data;
  }

  createCustomPage(formDataToSave: FormData): Observable<CustomPage> {
    return this.customPageApiService.createCustomPage(formDataToSave).pipe(catchError(() => of({})));
  }

  copyCustomPage(dataToSave: CopyCustomPage): Observable<CustomPage> {
    return this.customPageApiService.copyCustomPage(dataToSave).pipe(catchError(() => of({})));
  }

  updateCustomPage(formDataToSave: FormData, id: string): Observable<CustomPage> {
    return this.customPageApiService.updateCustomPage(formDataToSave, id).pipe(catchError(() => of({})));
  }

  getCustomPage(id: string): Observable<CustomPage> {
    return this.customPageApiService.getCustomPage(id).pipe(
      map((customPage: CustomPage) => this.customPageAdaptService.adaptCustomPage(customPage)),
      catchError(() => of({}))
    );
  }

  getCustomPageAndVersions(id: string): Observable<MappedCustomPage> {
    const currentCustomPageAndVersions = this.getCurrentCustomPageAndVersions();
    const customPageAndVersions = this.getCustomPage(id).pipe(
      switchMap((customPage: CustomPage) =>
        this.getCustomPageVersions(customPage).pipe(
          map((customPageVersions: CustomPageVersions) => ({ customPageVersions, customPage }))
        )
      ),
      tap((data: MappedCustomPage) => (this.currentCustomPageAndVersions = data))
    );

    return currentCustomPageAndVersions ? of(this.currentCustomPageAndVersions) : customPageAndVersions;
  }

  getCustomPageVersions(customPage: CustomPage): Observable<CustomPageVersions> {
    const isNotIncludeEndPage = this.customPageSettingService.isNotIncludeEndPage(customPage.labels);

    return isNotIncludeEndPage
      ? this.customPageApiService
          .getCustomPageVersions(customPage.uiId)
          .pipe(map((versions: CustomPageVersions) => this.customPageAdaptService.adaptVersions(versions)))
      : of(getEmptyVersions(customPage.uiId));
  }

  saveVersions(uuId: string, versions: CustomPageVersion[]): Observable<CustomPageVersions> {
    return this.customPageApiService
      .updateVersions(uuId, versions)
      .pipe(catchError(() => of({ customPageUUID: uuId, versions: [] })));
  }

  saveNewVersions(uuId: string, versions: CustomPageVersion[]): Observable<CustomPageVersion[]> {
    const newVersions = versions.filter((version: CustomPageVersion) => !version.pageId);
    const existedVersions = versions.filter((version: CustomPageVersion) => version.pageId);
    const requests = newVersions.map(version => this.getNewVersion(uuId, version.versionName));

    if (!newVersions.length) {
      return of(existedVersions);
    }

    return forkJoin(requests).pipe(
      map((data: CustomPageVersion[]) => {
        const mappedNewVersions = newVersions.map((version: CustomPageVersion, i: number) => {
          return {
            ...version,
            pageId: data[i].pageId,
          };
        });

        return [...existedVersions, ...mappedNewVersions];
      })
    );
  }

  getPlansConfig(): Observable<MappedCarrierPlan[][]> {
    return forkJoin([
      this.customPageApiService.getCarrierPlansConfigData(),
      this.customPageApiService.getCarrierPlansConfigMeta(),
    ]).pipe(
      map((result: CarrierPlanOptional[][]) => {
        return result.map((config: CarrierPlanOptional[]) => this.customPageAdaptService.adaptCarrierPlans(config));
      }),
      catchError(() => of([]))
    );
  }

  getDependentPages(dependentPages: DependentPage[], addedDate: string): Observable<IFilterBlockDataPages[]> {
    return this.customPageApiService.getDependentPages(addedDate).pipe(
      map((data: CustomPage[]) => this.customPageAdaptService.adaptDependentPages(data, dependentPages)),
      catchError(() => of([]))
    );
  }

  handlePreviewImage(filePath: string, file: File, isFileRemoved: boolean): Observable<ResponseType<any>> {
    if (file && !filePath) {
      return this.uploadPreviewFile(file);
    } else if (!file && isFileRemoved && filePath) {
      return of({ data: { location: null } } as ResponseType<any>);
    } else if (file && isFileRemoved && filePath) {
      return this.uploadPreviewFile(file);
    } else {
      return of({ data: { location: filePath } } as ResponseType<any>);
    }
  }

  createInsert(placeholder: Placeholder, placeholderFilesData): Observable<Insert> {
    const insert = this.customPageAdaptService.getFormDataForPlaceholder(placeholder, placeholderFilesData);

    return this.insertsApiService
      .createInsert(insert)
      .pipe(map((response: ResponseType<Insert>) => (response.data ? response.data : ({} as Insert))));
  }

  updateInsert(placeholder: Placeholder, placeholderFilesData, id: string): Observable<Insert> {
    const insert = this.customPageAdaptService.getFormDataForPlaceholder(placeholder, placeholderFilesData);

    return this.insertsApiService
      .updateInsert(insert, id)
      .pipe(
        map((response: ResponseType<Insert>) => (response.success && response.data ? response.data : ({} as Insert)))
      );
  }

  deleteInsert(insertUUID: string, customPageUIID: string): Observable<void> {
    //customPageUIID param send to check whether an insert use in other custom page
    return this.insertsApiService.deleteInsert(insertUUID, customPageUIID);
  }

  getPresentations(params: HomeTableRequestParams): Observable<MappedPresentationCount> {
    return this.apiService.getPresentations(params).pipe(
      concatMap((data: ResponseType<Presentations>) => {
        const ids = data.data.presentations.map((item: Presentation) => item.id);

        return forkJoin([
          of(data.data.presentations),
          this.getPlansCountByIds(ids),
          this.getPresentationsCount(params),
        ]);
      }),
      map((data: PresentationData) => this.customPageAdaptService.adaptPresentations(data))
    );
  }

  getCustomPageForEditTextPage(id: string): Observable<MappedEditTextCustomPage> {
    return this.getCustomPageAndVersions(id).pipe(
      map((data: MappedCustomPage) => this.customPageEditTextAdapterService.adaptCustomPageForEditText(data))
    );
  }

  getCustomPageForHistory(id: string): Observable<MappedHistoryCustomPage> {
    return this.getCustomPageAndVersions(id).pipe(
      map((data: MappedCustomPage) => this.customPageHistoryAdapterService.adaptCustomPageForHistory(data))
    );
  }

  getCustomPageHistory(
    customPageUUID: string,
    labels: string[],
    params: TableRequestParams
  ): Observable<CustomPageHistory> {
    return this.customPageHistoryApiService.getCustomPageHistory(customPageUUID, params).pipe(
      map((data: CustomPageHistory) => this.customPageHistoryAdapterService.adaptHistory(data, labels)),
      catchError(() => of({} as CustomPageHistory))
    );
  }

  updateCustomPageHtmlBody(id: string, htmlBody: string): Observable<void> {
    const data = {
      htmlBody: this.customPageEditTextAdapterService.removeSpanFromHtml(htmlBody),
    };

    return this.customPageApiService.updateCustomPageHtmlBody(id, data).pipe(catchError(() => of(null)));
  }

  removeCustomPage(id: string, uiId: string, labels: string[]): Observable<void | null[]> {
    return forkJoin([
      this.apiService.removeContentServiceEntity(id).pipe(map((response: any) => response.data)),
      this.apiService.removeDependentPageConfig(uiId),
      labels && labels.includes(CUSTOM_PAGES_TYPES.salesconcept)
        ? this.apiService.removeCustomPageConfigsFromPresentations(uiId)
        : of(null),
    ]);
  }

  getPagesList(params: TableRequestParams): Observable<CustomPagesLists> {
    return this.customPageApiService.getPagesList(params).pipe(
      catchError(() => of(getEmptyCustomPagesLists())),
      map((data: CustomPagesLists) => this.customPageAdaptService.adaptCustomPagesLists(data))
    );
  }

  getDependentPagesOfPage(id: string): Observable<CustomPage[]> {
    return this.customPageApiService.getDependentPagesOfPage(id);
  }

  getSalesConceptsForDependentPage(id: string): Observable<SalesConceptForDependentPage[]> {
    return this.customPageApiService.getSalesConceptsForDependentPage(id);
  }

  getDataSourceList(): Observable<DataSourcesConfig[]> {
    return this.customPageApiService.getDataSourcesConfig();
  }

  private uploadPreviewFile(previewFile: File): Observable<ResponseType<any>> {
    const formData: FormData = new FormData();
    formData.append('imageToUpload', previewFile);

    return this.customPageApiService.uploadCustomPageImage(formData);
  }

  private getNewVersion(uuId: string, versionName: string): Observable<CustomPageVersion> {
    return this.customPageApiService
      .createNewVersion(uuId, versionName)
      .pipe(catchError(() => of({} as CustomPageVersion)));
  }

  private getPlansCountByIds(ids: number[]): Observable<Plan[]> {
    return this.apiService.getPlansCountByIds(ids).pipe(map((plans: ResponseType<PlansQuantity>) => plans.data.data));
  }

  private getPresentationsCount(params: HomeTableRequestParams): Observable<number> {
    return this.apiService.getPresentationsQty(params).pipe(map((data: ResponseType<number>) => data.data));
  }
}
