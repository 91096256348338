<ng-container *ngIf="!isAutocomplete; else autocompleteList">
  <div class="invited-user-list">
    <div
      class="invited-user-item d-flex flex-row justify-content-between pb-3"
      *ngFor="let user of users"
    >
      <div class="d-flex flex-row align-items-center">
        <div class="user-avatar d-flex">
          <img
            *ngIf="user.avatarUrl"
            [src]="user.avatarUrl"
            alt="User Avatar"
          />
          <div
            *ngIf="!user.avatarUrl"
            class="d-flex justify-content-center align-items-center"
          >
            {{ user.avatarInitial }}
          </div>
        </div>
        <div class="user-info">
          <div class="user-name">{{ user.firstName }} {{ user.lastName }}</div>
          <span class="user-email">{{ user.email || user.receiverEmail }}</span>
          <img
            *ngIf="user.isInvalidEmail"
            class="pl-2"
            src="../../../../../assets/icons/warning-icon.svg"
            alt="Warning invalid email Icon"
            title="Invalid email address"
          />
        </div>
      </div>
      <ng-container *ngIf="showRemoveButton; else inviteInfo">
        <se-button class="delete" (click)="onRemoveUser(user.email)">
          Remove
        </se-button>
      </ng-container>
      <ng-template #inviteInfo>
        <div *ngIf="showInviteInfo" class="d-flex flex-column align-items-end">
          <span>
            Invited on:
            <strong>{{ user.invitedOn | date: 'MM/dd/yyyy h:mm a' }}</strong>
          </span>
          <span>
            Opened on:
            <strong>
              {{ (user.openedOn | date: 'MM/dd/yyyy h:mm a') || '-' }}
            </strong>
          </span>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>

<ng-template #autocompleteList>
  <ul
    class="autocomplete-list"
    [style.top.px]="listPositionTop"
    [style.left.px]="listPositionLeft"
    [style.width.px]="listWidth"
  >
    <li
      *ngFor="let user of users; let i = index"
      role="option"
      [attr.aria-selected]="i === selectedIndex"
    >
      <div
        class="user-item d-flex flex-row align-items-center"
        [ngClass]="{
          'user-item-extended': user.firstName,
          selected: i === selectedIndex
        }"
        (click)="onSelectUser(user)"
      >
        <div class="user-avatar d-flex">
          <img
            *ngIf="user.avatarUrl"
            [src]="user.avatarUrl"
            alt="User Avatar"
          />
          <div
            *ngIf="!user.avatarUrl"
            class="d-flex justify-content-center align-items-center"
          >
            {{ user.avatarInitial }}
          </div>
        </div>
        <div class="user-info">
          <div *ngIf="user.firstName" class="user-name">
            {{ user.firstName }} {{ user.lastName }}
          </div>
          <div class="user-email">{{ user.email }}</div>
        </div>
      </div>
    </li>
  </ul>
</ng-template>
