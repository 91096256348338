import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewRef,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { cloneDeep, debounce, defer, isNil, merge } from 'lodash-es';
import { Store } from '@ngrx/store';
import { NvD3Component } from '@assurance/ng2-nvd3/build/lib/ng2-nvd3.component';

import { mouseUpReset } from './chart.actions';
import { AppState } from '../../../../reducers';
import { ChartService } from './chart.service';
import {
  C3JSDefaultOffSet,
  C3JSMobileOffSet,
  COMBO_Y_AXIS_LABEL_DISTANCE,
  DEFAULT_OPTIONS,
  DISPLAYABLE_PIN_TYPES,
  HOVERED_BAR_SELECTOR,
  NON_DISPLAYABLE_PIN_TYPE,
  NVD3TooltipOffset,
  PIN_BLOCK_PADDING,
  PIN_BLOCK_WIDTH,
  PIN_MARGIN,
  PIN_MIN_POSITION,
  PIN_POINT_DIAMETER,
  SELECTED_BAR_SELECTOR,
  STACKED_CHART_TYPES,
  TRANSFORM_SCALE_COEF,
  Y_AXIS_LABEL_DISTANCE,
} from './chart.constants';
import { getMouseUp } from './chart.selectors';
import { C3ChartComponent } from './c3-chart/c3-chart.component';
import { ColorScheme } from '@shared/models';
import {
  ChartData,
  ChartOptions,
  ChartPoints,
  ChartThemes,
  ChartTooltipValues,
  DataTarget,
  PinType,
  PinValues,
  TotalPinChart,
  XAxisConfig,
} from '@core/model';
import { ChartTypes } from '@core/enums';

@UntilDestroy()
@Component({
  selector: 'ensight-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ChartService],
})
//TODO: need to refact the entire component
export class ChartComponent implements OnChanges, OnInit, OnDestroy {
  @Input() debounceTime = 0;
  @Input() data: ChartData[];
  @Input() options: ChartOptions;
  @Input() colorScheme: ColorScheme[] | string[];
  @Input() chartKey: string;
  @Input() pinType: PinType;
  @Input() tooltipTotalOptions: TotalPinChart;
  @Input() chartWidth: number;
  @Input() chartFontSize: number;
  @Input() chartTheme: ChartThemes;
  @Input() xAxisConfig: XAxisConfig;
  @Input() optimalVerticalPosition = false;
  @Input() metricDataSource: DataTarget;

  @Output() pinMove = new EventEmitter();
  @Output() pinValueChange = new EventEmitter();
  @Output() chartIsReady = new EventEmitter();

  @ViewChild('pin') pin: ElementRef;
  @ViewChild('pinNumber') pinNumber: ElementRef;
  @ViewChild('pinLine') pinLine: ElementRef;
  @ViewChild('tooltipNumber') tooltipNumber: ElementRef;
  @ViewChild('tooltipContainer') tooltipContainer: ElementRef;
  @ViewChild('chart') set chartEl(chart: NvD3Component) {
    if (chart) {
      this.chart = chart;
      this.afterChartInit();

      const offset = this.chartService.getOffsetValue(this.options.id);
      this.setPinPositionByValueAndHoverBar();
      this.setTooltipPosition();
      this.topPosition = true;
      this.topForTopPosition = NVD3TooltipOffset;

      this.chartService.applyStyleToPin(this.options, `#${chart.options.id} .nvd3-svg .nv-x.nv-axis`, offset);
    }
  }

  @ViewChild('c3chart') set c3ChartEl(chart: C3ChartComponent) {
    if (chart) {
      this.chart = chart.chartC3;
      this.afterChartInit();

      this.setPinPositionByValueAndHoverBar();
      this.setTooltipPosition();
      this.setPinContainerWidth();

      const distance = this.chartService.checkTransformProperty(true) ? C3JSMobileOffSet : C3JSDefaultOffSet;
      this.chartService.applyStyleToPin(this.options, `#c3Chart-${chart.options.id} .c3-axis-x`, distance);
    }
  }

  pinBlockWidth: number;
  NVD3PinWidth = PIN_BLOCK_WIDTH;
  tooltipDataByPinPosition: ChartTooltipValues<Observable<string>>[];
  chart: any; // NvD3Component | ElementRef
  pinMoved = false;
  pinPosition = 0;
  tooltipPositionLeft = true;
  chartData: ChartData[];
  chartOptions: ChartOptions;
  mobileDevice = false;
  debouncer = new Subject<unknown>();
  pinValueDebouncer = new Subject<unknown>();
  isS3JSChart = false;
  isClusteredBarChart = false;
  isSinglePolicyOrRTEOrCharges = false;
  topPosition: boolean;
  topForBottomPosition: string;
  topForTopPosition: string;

  private optionsEvents = {
    chart: {
      interactiveLayer: {
        dispatch: {
          elementClick: this.linesClickHandler.bind(this),
        },
      },
      stacked: {
        dispatch: {
          elementClick: this.elementClickHandler.bind(this),
        },
      },
      callback: this.onChartReadyCallback.bind(this),
      multibar: {
        dispatch: {
          elementClick: this.multiBarClickHandler.bind(this, SELECTED_BAR_SELECTOR),
          elementMouseover: this.multiBarMouseoverHandler.bind(this, HOVERED_BAR_SELECTOR),
          elementMouseout: this.multiBarMouseoutHandler.bind(this),
        },
      },
    },
  };

  constructor(private chartService: ChartService, private cdr: ChangeDetectorRef, private store: Store<AppState>) {}

  ngOnChanges(): void {
    this.initChart();
  }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(this.debounceTime), untilDestroyed(this))
      .subscribe(value => this.pinMove.emit(value));

    this.pinValueDebouncer.pipe(debounceTime(250), untilDestroyed(this)).subscribe(value => {
      this.pinValueChange.emit(value);
      this.setTooltipTopPosition();
    });

    this.mobileDevice = this.chartService.isMobileDevice();
    this.setStoreSubscribers();
    // TODO: Why do we need to call this method again, if we call this in ngOnChanges
    this.initChart();

    this.isSinglePolicyOrRTEOrCharges =
      this.options.id.includes('single_policy') ||
      this.options.id.includes('custom_visualization') ||
      this.options.id.includes('charges-total_charges');
  }

  ngOnDestroy(): void {
    this.store.dispatch(mouseUpReset());
  }

  multiBarMouseoutHandler(): void {
    this.chartService.resetHoverBar(this.options.id, HOVERED_BAR_SELECTOR);
  }

  stopPin(): void {
    this.pinMoved = false;
    this.store.dispatch(mouseUpReset());
  }

  chartIsReadyEmit(): void {
    let event: PinValues[] = this.getChartDataForNoDataState();

    if (this.options && this.data.length) {
      // this.setPinPositionByValueAndHoverBar();
      this.checkPinValue();
      this.setTooltipDataByPinPosition(this.options.pinValue);
      event = this.getChartDataByPinPosition(this.options.pinValue);
    }

    if (!this.chartIsReady.isStopped) {
      this.chartIsReady.emit(event);
    }

    (this.cdr as ViewRef).destroyed || this.cdr.detectChanges();
  }

  mouseEventHandle(apply: boolean): void {
    this.pinMoved = apply;
  }

  touchEventHandle(apply: boolean): void {
    this.pinMoved = apply;

    if (!apply) {
      this.chartService.resetHoverBar(this.options.id, HOVERED_BAR_SELECTOR);
      this.chartService.resetHoverBar(this.options.id, 'hover');
    }
  }

  onPinMoveByClickingOnChart(event: MouseEvent): void {
    this.handleMoveEvent(event.pageX, event.clientX);
    this.cdr.detectChanges();
  }

  clickEventHandleC3(e: HTMLElement): void {
    const datum = d3.select(e).datum();

    if (!datum) return;

    const point = this.chartService.getPositionByValue(datum?.x, this.options.id, this.options.isPDF);

    if (!isNil(point?.value)) {
      this.setPin(point.x + COMBO_Y_AXIS_LABEL_DISTANCE);
      this.setPinValue(point.value);
      this.chartService.highlightBar(datum.x, this.options.id, SELECTED_BAR_SELECTOR, this.options?.isPDF, true);
    }
  }

  mouseMoveEventHandleC3(e: MouseEvent): void {
    if (this.pinMoved) {
      this.handleMoveEvent(e.pageX, e.clientX);
    }
  }

  mouseoverEventHandleC3(e: HTMLElement): void {
    const datum = d3.select(e).datum();

    datum?.x &&
      this.chartService.highlightBar(datum.x, this.options.id, HOVERED_BAR_SELECTOR, this.options?.isPDF, true);
  }

  chartIsReadyEmitC3(): void {
    this.onChartReadyCallback();
  }

  elementTouchmoveHandler(e: TouchEvent): void {
    if (this.pinMoved) {
      this.handleMoveEvent(e.targetTouches[0].pageX, e.targetTouches[0].clientX);
    }
  }

  private setPinContainerWidth(): void {
    setTimeout(() => {
      if (this.tooltipNumber) {
        this.pinBlockWidth = this.tooltipNumber?.nativeElement.offsetWidth + PIN_BLOCK_PADDING;
      }

      if (this.pinLine && this.pinNumber) {
        const isTransformed = this.chartService.checkTransformProperty();
        const bottomPosition = this.pinLine?.nativeElement.getBoundingClientRect().height;
        const topPosition = this.pinNumber?.nativeElement.getBoundingClientRect().height + PIN_POINT_DIAMETER;

        this.topForBottomPosition = isTransformed
          ? `${-bottomPosition / TRANSFORM_SCALE_COEF}px`
          : `${-bottomPosition}px`;
        this.topForTopPosition = isTransformed ? `${topPosition / TRANSFORM_SCALE_COEF}px` : `${topPosition}px`;
      }

      this.setTooltipTopPosition();
    });
  }

  private setTooltipTopPosition(): void {
    this.topPosition = true;
    this.cdr.detectChanges();

    if (this.optimalVerticalPosition && this.tooltipContainer) {
      const tooltipRect = this.tooltipContainer.nativeElement.getBoundingClientRect();
      const tooltipWidth = this.chartService.checkTransformProperty()
        ? tooltipRect.width / TRANSFORM_SCALE_COEF
        : tooltipRect.width;

      const points = this.getAllPointsWithPositions(tooltipWidth);

      const pointsCoveredByTooltip = points.filter(
        point =>
          point.boundingClientRect?.top >= tooltipRect.top && point.boundingClientRect?.bottom <= tooltipRect.bottom
      ).length;

      this.topPosition = pointsCoveredByTooltip <= points.length / 2;
      this.cdr.detectChanges();
    }
  }

  private getAllPointsWithPositions(tooltipWidth: number): ChartPoints[] {
    const { x: xScale, y: yScale, main: chart } = this.chart.internal;

    const points = this.chartData.flatMap(series => {
      return series.values.map((d, index) => {
        const pointElement = chart.select(`.c3-target-data${series.key} .c3-circle-${index}`).node();
        const rect = pointElement?.getBoundingClientRect();

        return {
          data: d,
          xPosition: xScale(d.x),
          yPosition: yScale(d.y),
          boundingClientRect: rect,
        };
      });
    });

    const lastPoint = points.find(point => point.data.x === this.options.pinValue);
    const tooltipPinXPosition = this.tooltipPositionLeft
      ? lastPoint.xPosition + tooltipWidth
      : lastPoint.xPosition - tooltipWidth;

    return points
      .filter(
        point =>
          this.compareXPosition(point.xPosition, tooltipPinXPosition, lastPoint.xPosition) &&
          point.yPosition !== 0 &&
          (point.data.x !== 0 ? point.data.y !== 0 : true)
      )
      .map(point => ({ boundingClientRect: point.boundingClientRect }));
  }

  private compareXPosition(pointX: number, tooltipPinX: number, lastPointX: number): boolean {
    return this.tooltipPositionLeft
      ? pointX <= tooltipPinX && pointX >= lastPointX
      : pointX >= tooltipPinX && pointX <= lastPointX;
  }

  private isComboChart(): boolean {
    return this.chartOptions.chart.type === ChartTypes.comboChart;
  }

  private initChart(): void {
    if (this.options && this.data && this.colorScheme) {
      this.isS3JSChart = [
        ChartTypes.comboChart,
        ChartTypes.pie,
        ChartTypes.donut,
        ChartTypes.clusteredBarChart,
      ].includes(this.options?.chart?.type);
      this.isClusteredBarChart = this.options?.chart?.type === ChartTypes.clusteredBarChart;
      this.chartOptions = this.getChartOptions(this.options, this.data);
      this.chartData = this.getChartData();

      this.cdr.markForCheck();

      setTimeout(() => {
        this.checkPinValue();
        this.setPinPositionByValueAndHoverBar();
        this.setTooltipPosition();
      }, 500);

      defer(() => this.chartService.setOffsetXDistinction(this.options.id, this.isComboChart()));
    }
  }

  private handleColors(): void {
    this.data.forEach((item: ChartData, i: number) => {
      const color = (<ColorScheme>this.colorScheme[i])?.color || this.colorScheme[i];

      item.color = item.isTotalLTCBalanceUnlimited ? 'transparent' : (color as string);
      item.colorBase = color as string;
    });
  }

  private getChartData(): ChartData[] {
    this.handleColors();
    const normalizedData = this.chartService.normalizeData(cloneDeep(this.data), this.options.chart.type);

    return normalizedData.filter((item: ChartData) => !item.disable);
  }

  private getChartOptions(options: ChartOptions, data: ChartData[]): ChartOptions {
    const defaultOptions = cloneDeep(DEFAULT_OPTIONS);

    if (data.length) {
      const enables = data.filter((item: ChartData) => !item.disable);
      const isOneSeries = enables.length === 1 && enables[0].isTotalLTCBalance && enables[0].isTotalLTCBalanceUnlimited;
      const isUnlimited = data.find((item: ChartData) => item.isTotalLTCBalanceUnlimited);

      defaultOptions.chart.forceY = isOneSeries ? [-1, 0] : this.chartService.getForceY(data);
      defaultOptions.chart.yAxis['tickFormat'] = isOneSeries ? () => '' : options.chart.yAxis['tickFormat'];

      if (isUnlimited && !isOneSeries) {
        const range = this.chartService.getDataRange(data);

        defaultOptions.chart['yDomain'] = [range.minY, range.maxY + (range.maxY - range.minY) * 0.15];
      }
    }

    return merge({ chart: { width: this.chartWidth } }, options, defaultOptions, this.optionsEvents, this.xAxisConfig);
  }

  private afterChartInit(): void {
    if (this.chart) {
      const el = this.chart.el || this.chart.element;
      this.chartService.drawUnlimitedLine(this.chart, this.chartData, this.options?.isPDF);
      this.assignEventListeners(el);
    }

    if (this.pin) {
      const pinElement = this.pin.nativeElement;
      this.assignEventListeners(pinElement);
    }
  }

  private assignEventListeners(element: HTMLElement): void {
    element.onmousemove = this.elementMousemoveHandler.bind(this);
    element.onmouseup = this.elementMouseupHandler.bind(this);
    element.ontouchmove = debounce(this.elementTouchmoveHandler.bind(this), 10);
  }

  private elementMousemoveHandler(e: MouseEvent): void {
    if (this.pinMoved) {
      this.handleMoveEvent(e.pageX, e.clientX);
    }
  }
  private handleMoveEvent(pageX: number, clientX: number): void {
    const isPageTransformed = this.chartService.checkTransformProperty(true);
    const xPosition = isPageTransformed ? pageX / TRANSFORM_SCALE_COEF : pageX;

    const point = this.chartService.getValueByPointPosition(xPosition, this.options.id);

    if (point.x && !isNil(point.value)) {
      this.setPin(point.isBarChart || point.isComboChart ? point.x : clientX - this.chartService.offsetXDistinction);
      this.setPinValue(point.value);
      (point.isBarChart || point.isComboChart) &&
        this.chartService.highlightBar(
          point.value,
          this.options.id,
          SELECTED_BAR_SELECTOR,
          this.options?.isPDF,
          this.isComboChart()
        );
    }
  }

  private multiBarClickHandler(className: string, e: any): void {
    if (e.data.x) {
      const result = this.chartService.getPositionByValue(e.data.x, this.options.id, this.options.isPDF);

      this.setPin(result.x + Y_AXIS_LABEL_DISTANCE);
      this.setPinValue(e.data.x);

      setTimeout(() => {
        this.chartService.highlightBar(e.data.x, this.options.id, className, this.options?.isPDF, this.isComboChart());
      }, 0);
    }
  }

  private multiBarMouseoverHandler(className: string, e: any): void {
    if (e.data.x) {
      this.chartService.highlightBar(e.data.x, this.options.id, className, this.options?.isPDF, this.isComboChart());
    }
  }

  private elementMouseupHandler(): void {
    this.stopPin();
  }

  private setStoreSubscribers(): void {
    this.store
      .select(getMouseUp)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.stopPin());
  }

  private checkPinValue(): void {
    if (this.options && !isNil(this.options.pinValue)) {
      const dataRange = this.chartService.getDataRange(this.data);
      this.options.pinValue < dataRange.minX && (this.options.pinValue = dataRange.minX);
      this.options.pinValue > dataRange.maxX && (this.options.pinValue = dataRange.maxX);
    }
  }

  private onChartReadyCallback(): void {
    if (!isNil(this.options.pinValue)) {
      this.checkPinValue();
    }

    this.chartIsReadyEmit();
  }
  private elementClickHandler(e: any): void {
    const point = this.chartService.getValueByPointPosition(e.event.pageX, this.options.id);

    if (!point.x || isNil(point.value)) {
      point.x = e.event.pageX;
      point.value = e.point.x;
    }

    this.setPin(e.event.clientX - this.chartService.offsetXDistinction);
    this.setPinValue(point.value);
  }

  private linesClickHandler(e: any): void {
    const xValue = Math.round(e.pointXValue);
    const point = this.chartService.getPositionByValue(xValue, this.options.id, this.options.isPDF);

    this.setPinValue(point.value);
    this.setPin(point.x + Y_AXIS_LABEL_DISTANCE);
  }

  private setPin(offsetX: number): void {
    // this.setPinValue(value);
    this.setPinPosition(offsetX);

    (this.cdr as ViewRef).destroyed || this.cdr.detectChanges();
  }

  private setPinPosition(value: number, includeMargin = true): void {
    if (!this.options.pinDisable) {
      const minPinPosition = ((this.pinBlockWidth || this.NVD3PinWidth) / 2) * 1.2;
      const position = value - (includeMargin ? (this.pinBlockWidth || this.NVD3PinWidth) / 2 : 0);
      this.pinPosition = position > minPinPosition || this.isComboChart() ? position : minPinPosition;

      this.setTooltipPosition();
      this.cdr.markForCheck();
    }
  }

  private setTooltipPosition(): void {
    const pinMargin = this.options?.isPDF ? PIN_MARGIN : 0;
    const pinMinPosition = this.chartService.checkTransformProperty()
      ? PIN_MIN_POSITION / TRANSFORM_SCALE_COEF
      : PIN_MIN_POSITION;
    this.tooltipPositionLeft = this.pinPosition < this.NVD3PinWidth * 2 + pinMinPosition + pinMargin;
  }

  private setPinValue(value: number): void {
    if (this.options.pinValue !== value) {
      this.pinValueDebouncer.next(value);
    }

    this.options.pinValue = value;

    if (!this.options?.isPDF && !this.options?.pinDisable) {
      const event = this.getChartDataByPinPosition(value);
      this.debounceTime ? this.debouncer.next(event) : this.pinMove.emit(event);
    }

    this.setTooltipDataByPinPosition(value);
    this.cdr.markForCheck();
  }

  private getChartDataByPinPosition(x: number): PinValues[] {
    return this.data.map((item: ChartData) => {
      return {
        chartKey: this.chartKey,
        planId: item.planId,
        metricId: item.metricId,
        color: item.colorBase,
        x,
        y: this.chartService.getYByX(x, item.values),
        disable: item.disable,
      };
    });
  }

  private getChartDataForNoDataState(): PinValues[] {
    return [
      {
        chartKey: this.chartKey,
      },
    ];
  }

  private setTooltipDataByPinPosition(x: number): void {
    if (
      !this.chartData ||
      this.pinType === NON_DISPLAYABLE_PIN_TYPE ||
      !DISPLAYABLE_PIN_TYPES.includes(this.pinType) ||
      this.options.pinDisable
    ) {
      return;
    }

    const isStacked = STACKED_CHART_TYPES.includes(this.chart?.chartType);
    const isStackedMetric = this.chartData.some(metric => metric.stackedMetric === true);

    const data = cloneDeep(
      isStacked || isStackedMetric
        ? this.data.sort((a, b) => Number(b.key) - Number(a.key))
        : this.chartData.sort((a, b) => Number(a.order) - Number(b.order))
    );

    this.tooltipDataByPinPosition = this.chartService.getTooltipDataByPinPosition(
      data,
      x,
      this.pinType,
      this.options.chart.type,
      this.tooltipTotalOptions,
      this.metricDataSource
    );
  }

  private setPinPositionByValueAndHoverBar(): void {
    if (this.options && !this.options.pinDisable && this.data) {
      setTimeout(() => {
        const point = this.chartService.getPositionByValue(this.options.pinValue, this.options.id, this.options.isPDF);

        if (!isNil(point.value)) {
          this.setPinValue(point.value);
        }

        if (point.x) {
          const yAxisLabelDistance = this.isComboChart() ? COMBO_Y_AXIS_LABEL_DISTANCE : Y_AXIS_LABEL_DISTANCE;
          this.setPin(point.x + yAxisLabelDistance);
        }

        this.chartService.hoverBar(
          this.options.pinValue,
          this.options.id,
          SELECTED_BAR_SELECTOR,
          this.options?.isPDF,
          this.isComboChart()
        );
      }, 100);
    }
  }
}
