export const UserButtons = [
  {
    label: 'Add User',
    key: 'addUser',
    classes: 'secondary',
    active: true,
    id: null,
    type: null,
    disabled: true,
  },
  {
    label: 'Invited Users',
    key: 'invitedUsers',
    classes: 'secondary',
    active: false,
    id: null,
    type: null,
    disabled: true,
  },
];

export const DefaultInvitedUsersPaginationRequestParams = {
  PAGE: 1,
};
