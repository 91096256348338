import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { combineLatest, forkJoin, fromEvent, Observable, of } from 'rxjs';
import { defaultIfEmpty, filter, first, map, switchMap, tap, throttleTime } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { DragulaService } from 'ng2-dragula';
import * as _ from 'lodash-es';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ModalService } from '@assurance/bootstrap';

import { getDependentPermissions, getPdfGeneration } from '@ngrx-app/global.selectors';
import { AppState } from '../../../../reducers';
import { pagesWithoutSettings } from './navbar.constants';
import * as setupConstant from '../../../../components/presentation/setup/setup.constant';
import {
  PRESENTATION_SETUP_ROUTER_REGEXP,
  PRESENTATION_VIEW_ROUTER_REGEXP,
} from '../../../../components/presentation/setup/setup.constant';
import { APIService, GAService, Global, NavbarPagesService, TimeService, Utils } from '@shared/services';
import { NavbarService } from './navbar.service';
import { InfoModalComponent } from '../modal/info-modal/info-modal.component';
import { CoverSheetModalComponent } from '../../../../components/presentation/modals/cover-sheet-modal/cover-sheet-modal.component';
import { coverSheetModalSize } from '../../../../components/presentation/modals/cover-sheet-modal/cover-sheet-modal.constants';
import { presentationHeaderDisabled } from '../../../../components/presentation/presentation.actions';
import { SalesConceptModalComponent } from '../../../../components/presentation/modals/sales-concept-modal/sales-concept-modal.component';
import {
  CoverLetterWithRequired,
  CustomPage,
  CustomPageInsert,
  CustomPagesConfig,
  CustomPagesItemConfig,
  CustomPageValues,
  GlobalConfig,
  Insert,
  InsertByCustomPageUUID,
  NavbarCustomPagesData,
  PageConfig,
  PlansOptions,
  UpdatedVersionDate,
} from '@shared/models';
import { CustomPageService } from '../../../../components/presentation/setup/custom-page/custom-page.service';
import { getCoverLetterWithRequired } from '../../../../components/presentation/modals/cover-sheet-modal/cover-sheet-modal.selectors';
import * as ConfigsActions from '../../../../components/presentation/redux/configs/actions';
import { getPresentationConfigs } from '../../../../components/presentation/redux/configs/selectors';
import {
  getPresentationConfigPending,
  getPresentationPlans,
} from '../../../../components/presentation/presentation.selectors';
import { PdfExportService } from '@core/service';
import { toggleSettingsModal } from '../../../../components/modals/redux/page-settings.actions';
import {
  ANCHOR_CLASS,
  AUTH_KEYS,
  chain,
  CHANGE_LOCKED_MSG,
  getLockedByDistributionMsg,
  getLockedPresentationHeader,
  KeyboardKey,
  OperatorKey,
} from '@shared/constants';
import {
  getDependentPagesConfig,
  getDependentPagesPendingRequests,
  getNavbarCustomPages,
  getNavbarSalesConceptsPages,
  getSalesConcepts,
  getSalesConceptsCount,
  getSalesConceptsModalAvailable,
  getSelectedPage,
  pendingRequests,
} from '../../../../components/presentation/setup/setup.selectors';
import {
  changeSelectedPageId,
  getDependentPagesConfigLoadingSuccess,
  getSalesConceptsLoadingPending,
  salesConceptModalRequestsPending,
  salesConceptModalRequestsSuccess,
  salesConceptsModalAvailable,
  setNavbarCustomPages,
  setSetupLoading,
} from '../../../../components/presentation/setup/setup.actions';
import { DOCUMENT_TOKEN, WINDOW_TOKEN, LOCATION_TOKEN } from '@core/constant';
import { PresentationRoutes, INSERT_TYPE, PermissionKeyEnum } from '@core/enums';
import { PlansService } from '../../../../components/presentation/presentation-plans/plans.service';
import { detectIE, isDefined } from '@core/utils';
import { AddedPageResponse, NavDirection, NavDirections } from './navbar.model';
import { CareerPlan, ResponseType, VariablePlaceholder } from '@core/model';
import { SetupService } from '../../../../components/presentation/setup/setup.service';
import { ModalProviderService } from 'src/app/components/presentation/shared/services';
import { AuthService, IAMService } from '@assurance/um-services';

@UntilDestroy()
@Component({
  providers: [NavbarService],
  selector: 'ensight-navbar',
  styleUrls: ['navbar.component.scss'],
  templateUrl: 'navbar.component.html',
})
export class NavbarComponent implements OnChanges, OnInit, OnDestroy {
  @Input() isNavbarShown: boolean;
  @Input() navName: string;
  @Input() staticPages: any[];
  @Input() dndList: PageConfig[];
  @Input() selectedPlanId: any;
  @Input() createPlan: boolean;
  @Input() disabled = false;
  @Input() hideControls = false;
  @Input() preview = false;
  @Input() listId: string;
  @Input() plansOptions: PlansOptions;
  @Input() endPages: CustomPageValues[];
  @Input() dependentPages: CustomPageValues[];

  @Output() updateStaticPages = new EventEmitter();
  @Output() dndDragend = new EventEmitter();
  @Output() dndSelect = new EventEmitter();
  @Output() planScratch = new EventEmitter();
  @Output() planCsv = new EventEmitter();
  @Output() collapseViewNavbar = new EventEmitter();
  @Output() showRotateDeviceModal = new EventEmitter<boolean>();
  @Output() changeVersionAddedDate = new EventEmitter<UpdatedVersionDate>();

  @ViewChild(InfoModalComponent) infoModalComponent: InfoModalComponent;

  selectedPage: any;
  plans: CareerPlan[];
  mergedNavbarPages = [];
  selectedItem;
  presentationId: number;
  isModalAvailable = this.store.select(getSalesConceptsModalAvailable);
  toggleSpinner: { [key: string]: boolean } = {};
  sidebarOffsetStyles: Record<string, unknown>;
  dragulaIsReady = false;
  dependentPagesConfig: CustomPagesConfig[] = [];
  isPresentationLocked: boolean;
  configs: (GlobalConfig & PageConfig)[];
  pdfGeneration$ = this.store.select(getPdfGeneration);
  collapsedNavbar: boolean;
  dependentPermissions: Record<string, string[] | null>;
  pendingRequests: boolean;
  navNext = NavDirections.next;
  navBack = NavDirections.back;
  isSharedPresentation = false;
  isAnnuityProduct = false;
  isCoverLetterExist: any;
  isTargetPremiumBtnShown = false;
  OperatorKey = OperatorKey;
  isShowSettingsButton: boolean;
  isShowSalesConceptSettings: boolean;
  isRotateDeviceModalShown = false;
  versionAddedDate: Date;
  currentVersionName: string;
  navbarCustomPagesData: NavbarCustomPagesData;
  hasUserPermissionToChangeVersion = false;
  isMenuOpen = false;
  canEditPresentation = false;

  private salesConcepts: CustomPageValues[];
  private salesConceptsCount: number;
  private salesConceptLoading = false;
  private fetchSalesConcepts = false;
  private presentationEditRestricted = false;
  private initialWidth: number;
  private compiledSalesConcepts: CustomPageValues[];

  constructor(
    public global: Global,
    private cdr: ChangeDetectorRef,
    private navbarService: NavbarService,
    private dragulaService: DragulaService,
    private router: Router,
    private utils: Utils,
    private apiService: APIService,
    private store: Store<AppState>,
    private modal: ModalService,
    private customPageService: CustomPageService,
    private presentationViewService: PdfExportService,
    private gaService: GAService,
    private plansService: PlansService,
    private setupService: SetupService,
    private navbarPagesService: NavbarPagesService,
    private modalProvider: ModalProviderService,
    private iamService: IAMService,
    private authService: AuthService,
    private timeService: TimeService,
    @Inject(DOCUMENT_TOKEN) private document: Document,
    @Inject(WINDOW_TOKEN) private window: Window,
    @Inject(LOCATION_TOKEN) private location: Location
  ) {}

  @HostListener('window:scroll', ['$event']) onScroll() {
    const headerHeight = this.navbarService.getHeaderHeight();
    const scrolled = this.navbarService.getScrollValuePx();
    const valueOffset = headerHeight - scrolled;
    const sidebarOffsetTop = valueOffset >= 0 ? valueOffset : 0;
    this.sidebarMovementStyle(sidebarOffsetTop);
  }

  @HostListener('window:keydown', ['$event']) navigatePages(event: KeyboardEvent): void {
    if (event.key === KeyboardKey.RightArrow && this.isNavigationAvailable(NavDirections.next) && this.preview) {
      this.handlePageNavigation(NavDirections.next);
    }

    if (event.key === KeyboardKey.LeftArrow && this.isNavigationAvailable(NavDirections.back) && this.preview) {
      this.handlePageNavigation(NavDirections.back);
    }
  }

  @HostListener('window:click', ['$event']) onClick(event) {
    if (event.target.classList.contains(ANCHOR_CLASS)) {
      this.navigateToThePageByLink(event.target.href);
      event.preventDefault();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    changes.dependentPages && this.setDependentPagesShowPreview();
    changes.dndList && this.hideSalesConcepts();

    if (changes.staticPages) {
      this.setIsTargetPremiumBtnShown();
    }

    if (this.dndList && this.endPages) {
      this.mergedNavbarPages = this.navbarService.mergeAllPages(
        this.dndList,
        this.dndList.map(item => this.mergeCustomPages(item, this.preview)),
        this.endPages
      );
    }
  }

  ngOnInit(): void {
    this.watchForSelectedPage();
    this.isSharedPresentation = this.global.isSharedPresentation();

    if (this.isSharedPresentation && this.utils.readCookie('loggedIn')) {
      this.getPermissionForSharedLink()
        .pipe(untilDestroyed(this))
        .subscribe(authenticated => {
          if (authenticated) {
            this.hasUserPermissionToChangeVersion = this.iamService.hasUserAccess(
              PermissionKeyEnum.define_custom_page_versions
            );
          }
        });

      if (this.global.getPresentation?.id) {
        this.canEditPresentation =
          this.iamService.hasUserAccess(PermissionKeyEnum.open_presentation_setup) &&
          this.iamService.hasUserAccess(PermissionKeyEnum.prsnt_edit);
      }
    }

    if (
      this.isSharedPresentation &&
      window.innerHeight > window.innerWidth &&
      !sessionStorage.getItem('isDismissedModal')
    ) {
      this.isRotateDeviceModalShown = true;
      this.showRotateDeviceModal.emit(true);
    }

    this.isAnnuityProduct = this.plansService.checkOnAnnuityProducts();
    this.setIsTargetPremiumBtnShown();
    this.watchForPresentationConfig();
    this.watchForCompiledSaleConcepts();

    const presentation = this.global.getPresentation;

    if (presentation.isTemplate) {
      this.presentationEditRestricted = this.global.isEditRestricted(presentation.userid, presentation.agencyId);
    }

    this.getDependentPageConfig();
    this.watchForCoverSheet();
    this.presentationId = this.global.getActivePresentationId;
    this.watchForDropModel();

    if (this.plansOptions && this.plansOptions.navId) {
      this.dragulaService.find(this.plansOptions.navId) ||
        this.dragulaService.setOptions(this.plansOptions.navId, {
          invalid: () => this.isPresentationLocked || this.global.pageLoading,
          moves: () => !(this.isPresentationLocked || this.global.pageLoading),
          accepts: () => !(this.isPresentationLocked || this.global.pageLoading),
        });
    }

    this.dragulaService.setOptions(this.listId, {
      invalid: (el: any) => {
        if (this.isPresentationLocked || this.global.pageLoading) {
          return true;
        }

        if (this.closest(el, '.side-bar-item')) {
          return !this.closest(el, '.side-bar-item').classList.contains('dragged');
        }
      },
      moves: (el: any) => {
        if (this.isPresentationLocked || this.global.pageLoading) {
          return false;
        }

        return !el.classList.contains('dragged-disabled');
      },
      accepts: (_el: HTMLElement, _source: HTMLElement, _handle: HTMLElement, sibling: HTMLElement) => {
        if (this.isPresentationLocked || this.global.pageLoading) {
          return false;
        }

        if (sibling) {
          return !sibling.classList.contains('dragged-disabled');
        } else {
          return true;
        }
      },
    });

    this.initialWidth = this.window.innerWidth;
    this.dragulaIsReady = true;
    this.collapsedNavbar = this.navbarService.getNavbarCollapseState();
    this.collapseViewNavbar.emit({ state: !this.collapsedNavbar, first: true });

    if (this.isSharedPresentation) {
      this.fetchNavbarCustomPages();
    }

    this.watchFroWindowResize();
    this.watchForSalesConceptsInfo();
    this.watchForPresentationPlans();
    this.watchForPendingRequests();
  }

  ngOnDestroy(): void {
    this.dragulaService.destroy(this.listId);

    if (_.get(this.plansOptions, 'dndList.length')) {
      this.dragulaService.destroy(this.plansOptions.navId);
    }

    if (this.global.getUploadedCsvFile) {
      this.global.setUploadedCsvFile = null;
    }
  }

  openSetUp(): void {
    this.isMenuOpen = false;
    this.window.open(`${this.location.origin}/presentation/${this.global.getPresentation.id}/setup`, '_blank');
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  clickOutside(): void {
    this.isMenuOpen = false;
  }

  downloadPDFBackend(): void {
    if (this.isSharedPresentation) {
      this.presentationViewService.sendPDFExportShared();
    }

    this.gaService.sendDownloadPdfEvent();
  }

  presentationSetupSelect(): void {
    this.global.leavePresentationView();
    this.gaService.sendExitPresentationViewEvent();
  }

  handleSettingsOpen(): void {
    this.store.dispatch(toggleSettingsModal({ open: true }));
  }

  openSharingModal(): void {
    const data = { presentationId: this.global.getActivePresentationId, presentationInfo: this.global.getPresentation };
    this.modalProvider.openSharedPresentationModal(data);
  }

  private showSettingsButton(): void {
    this.isShowSettingsButton =
      pagesWithoutSettings.indexOf(this.selectedPage.config.uiId) === -1 &&
      pagesWithoutSettings.indexOf(this.selectedPage.label) === -1 &&
      !this.selectedPage?.isDependentPage &&
      !this.selectedPage?.isSalesConcept &&
      !this.selectedPage?.config.isSalesConcept &&
      !this.selectedPage?.isEndPage;

    this.cdr.markForCheck();
  }

  private getPermissionForSharedLink(): Observable<boolean> {
    return this.authService.auth(AUTH_KEYS);
  }

  private showSalesConceptSettings(): void {
    if (this.compiledSalesConcepts) {
      const uiId = this.selectedPage.isDependentPage ? this.selectedPage.parentUiId : this.selectedPage.config.uiId;
      const salesConcept = this.compiledSalesConcepts.find(salesConcept => salesConcept.config.uiId === uiId);

      const variableInserts = salesConcept?.inserts?.filter(
        insert => insert.metadata.insertType === INSERT_TYPE.variable
      );

      this.isShowSalesConceptSettings =
        this.global.isSharedPresentationView() &&
        variableInserts?.length &&
        variableInserts.some(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (insert: CustomPageInsert<VariablePlaceholder>) =>
            !insert.metadata.hiddenOnSharedView && !insert.metadata.hidden
        );
    } else {
      this.isShowSalesConceptSettings = false;
    }

    this.cdr.markForCheck();
  }

  getSettingsLabel(): string {
    return this.selectedPage.config.uiId === 'retirement_shortfall' ? 'Assumptions' : 'Settings';
  }

  fullScreenIsEnabled(): boolean {
    return this.document.fullscreen || this.document['mozFullscreenEnabled'] || this.document['webkitIsFullScreen'];
  }

  handleFullScreen(): void {
    if (!this.fullScreenIsEnabled()) {
      if (detectIE() <= 11) {
        (this.document.querySelector('body > .common-container') as HTMLElement).style.overflow = 'auto';
      }

      setupConstant.activateFullScreen();
      this.gaService.sendPresentationEvent({
        eventAction: 'Open Fullscreen',
      });
    } else {
      setupConstant.deactivateFullScreen();
      this.document.querySelector('body > .common-container').removeAttribute('style');

      this.gaService.sendPresentationEvent({
        eventAction: 'Exit Fullscreen',
      });
    }
  }

  collapseNavbar(): void {
    this.customPageService.setScrollPagePosition();
    this.collapseViewNavbar.emit({ state: this.collapsedNavbar, first: false });
    this.collapsedNavbar = !this.collapsedNavbar;
    this.gaService.sendPresentationEvent({
      eventAction: `${this.collapsedNavbar ? 'Collapse' : 'Expand'} Sidebar`,
    });
  }

  isDragged(order: any): boolean {
    return !(_.isNull(order) || _.isUndefined(order));
  }

  private selectedDndItem(item: any): boolean {
    return (
      this.selectedPage.config.uiId === item.config.uiId &&
      this.selectedPage.config.isSalesConcept === item.config.isSalesConcept &&
      this.selectedPage.isEndPage === item.isEndPage &&
      ((item.parentUiId && this.selectedPage.parentUiId === item.parentUiId) || !item.parentUiId)
    );
  }

  isSelectedPageIncluded(itemId: string): boolean {
    if (!this.isDependentPagesShown()) return false;

    const selectedDependent = this.dependentPages.find(el => el.config.uiId === this.selectedPage.config.uiId);

    if (!selectedDependent) {
      return false;
    }

    return this.selectedPage.parentUiId === itemId;
  }

  openCoverSheetModal(): void {
    this.modal.open(CoverSheetModalComponent, {
      width: coverSheetModalSize.width,
      height: coverSheetModalSize.height,
      panelClass: 'add-cover-sheet',
    });
  }

  openSalesConceptModal(): void {
    const data = {
      salesConcepts: this.getSalesConcepts(),
      plans: this.navbarService.extendPlanOptions(this.plansOptions.dndList, this.plans),
      presentationConfig: this.configs,
      customPagesConfigs: this.dependentPagesConfig,
      dataSources: this.customPageService.getDataSourceLabels(),
    };

    const modal = this.modal.open(SalesConceptModalComponent, {
      data,
      width: coverSheetModalSize.widthForSalesConceptsModal,
      maxWidth: coverSheetModalSize.widthForSalesConceptsModal,
      panelClass: 'add-sale-story',
    });

    let addedPageResponse: AddedPageResponse;

    modal.afterClosed
      .pipe(
        first(),
        switchMap((res?: AddedPageResponse) => {
          if (!res?.submitted) {
            return of(null);
          }

          if (!res.uiId) {
            this.setDependentPagesShowPreview();
            this.updateDependentConfig();

            return of(null);
          }

          this.store.dispatch(getSalesConceptsLoadingPending());
          this.store.dispatch(salesConceptModalRequestsPending());
          this.salesConceptLoading = true;
          addedPageResponse = res;
          const { uiId, internalId } = this.customPageService.getSalesConceptIds(res.uiId);

          return this.customPageService.getPages({ uiId }, { uiId, isSalesConcept: true, internalId }).pipe(
            first(),
            switchMap(pages => {
              const dependentPages: CustomPagesConfig[] = _.get(pages, 'response[0].customFields.dependentPages') || [];
              // const saleConceptInserts: Insert[] = _.get(pages, 'response[0].inserts') || [];
              const uiIds = dependentPages.map(page => page.uiId);

              return forkJoin([
                this.customPageService.getInsertsOfDependPages(uiIds),
                this.apiService.getCustomPagesInserts({ uiId }),
                // TODO: will remove when implement this on the backend. We need to update it on the backend side
                this.getDependentPages(uiIds),
              ]);
            }),
            switchMap((result: [InsertByCustomPageUUID[], InsertByCustomPageUUID[], CustomPage[]]) => {
              // saleConceptInserts and dependentPagesInserts - not compiled inserts
              const [dependentPagesInserts, saleConceptInserts, dependentPages] = result;
              const getInsert = insert => {
                // const defaultDropdownOption = Number(insert.metadata?.defaultOption) || 0;
                // const dropdownValue = insert.metadata?.dropdownOptions?.[defaultDropdownOption]?.value
                //   ?.toString()
                //   ?.trim();
                // TODO: Evaluate alternative methods to set dependent pages config to eliminate redundant data

                return {
                  uiId: insert.metadata.id,
                  value:
                    // dropdownValue ||
                    insert.metadata?.defaultOption ||
                    insert.metadata.placeholderDefaultValue ||
                    insert.metadata.placeholderText ||
                    insert.metadata.placeholderSelectedValue ||
                    insert.metadata.placeholderSelectedProduct ||
                    insert.metadata.placeholderSelectedTab,
                  ...(insert.metadata.insertType === INSERT_TYPE.chart ? { isChartInsert: true } : {}),
                  // index: dropdownValue ? defaultDropdownOption : 0,
                };
              };

              const mappedDepPage: any[] = dependentPages.map((dependentPage: CustomPage) => {
                const { customFields } = dependentPage;
                const found = dependentPagesInserts.find(item => item.uiId === dependentPage.uiId);

                return {
                  isSelected: !customFields.disabled,
                  uiId: dependentPage.uiId,
                  inserts: (() => {
                    if (found) {
                      return found.inserts.map((insert: Insert) => getInsert(insert));
                    } else {
                      return [];
                    }
                  })(),
                };
              });

              const mappedSaleInserts = saleConceptInserts[0]?.inserts.map(insert => getInsert(insert));

              const insertsToUpdate = res.insertsToUpdate ? res.insertsToUpdate : [];

              const saleInserts: any[] = [
                ...new Map(
                  [...(mappedSaleInserts || []), ...(insertsToUpdate || [])].map(item => [item.uiId, item])
                ).values(),
              ];

              return this.customPageService
                .updateInsertsConfigsDirectPage(saleInserts, this.dependentPagesConfig, res.uiId, mappedDepPage)
                .pipe(switchMap(() => this.customPageService.getSalesConcepts({ uiId: [res.uiId] })));
            })
          );
        }),
        switchMap(() => this.getNavbarCustomPages()),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.salesConceptLoading = false;
        this.store.dispatch(salesConceptModalRequestsSuccess());

        if (addedPageResponse) {
          this.setupService.navigatePage(addedPageResponse.pageConfig);
          this.store.dispatch(changeSelectedPageId({ payload: addedPageResponse.uiId }));
        }

        this.window.scroll(0, 0);
      });
  }

  addTargetPremium(uiId: string): void {
    if (!this.global.pageLoading) {
      this.updateStaticPages.emit(uiId);
      this.gaService.sendAddPageEvent('Target Premium');
    }
  }

  goToPage(page: any): void {
    const isPlanPage = Boolean(page.id);

    if (isPlanPage || page.config.showPreview) {
      this.dndSelect.emit(page);
      this.collapseSidebarOnSharedPresentation();

      return;
    }

    const firstPage = this.getIncludedDependentChecked(page.config.uiId);

    if (firstPage) {
      this.dndSelect.emit(firstPage);
      this.collapseSidebarOnSharedPresentation();
    }
  }

  selectPlan(item: any, event: Event): void {
    this.selectItem(item, item.id === this.selectedPlanId, event, null, false, true);
  }

  selectItem(
    item: any,
    selected: boolean,
    event: Event,
    parentUiId?: string,
    isSelectedPage?: boolean,
    isPlan?: boolean
  ): void {
    event?.stopPropagation();

    selected = isPlan ? selected : this.selectedDndItem(item);

    if (this.disabled) {
      return;
    }

    const page = _.cloneDeep(item);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const toggleElem = event?.target.closest('ensight-simple-toggle');

    if (!selected && !toggleElem) {
      parentUiId && (page.parentUiId = parentUiId);
      page.isParentSalesConcept = !!isSelectedPage;
      this.selectedItem = page;
      this.dndSelect.emit(page);
      this.collapseSidebarOnSharedPresentation();
      // @ts-ignore
      this.window.scroll({ top: 0, left: 0, behavior: 'instant' });
    }
  }

  onSaveNote(note: string, id: number): void {
    const planToUpdate = this.global.getCurrentCarrierPlans.find((carrierPlan: CareerPlan) => carrierPlan.id === id);

    this.store.dispatch(setSetupLoading({ payload: true }));
    this.setupService
      .updatePlanFromPage(planToUpdate, note)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.store.dispatch(setSetupLoading({ payload: false })));
  }

  selectStaticPage(page: PageConfig): void {
    this.dndSelect.emit(page);
  }

  private setIsTargetPremiumBtnShown(): void {
    const found =
      this.staticPages &&
      !this.staticPages.find(item => item.config.uiId === 'target_premium' && item.config.showPreview);

    this.isTargetPremiumBtnShown = this.staticPages && found;
    this.cdr.markForCheck();
  }

  private watchForCoverSheet(): void {
    this.store
      .select(getCoverLetterWithRequired)
      .pipe(untilDestroyed(this))
      .subscribe((data: CoverLetterWithRequired) => {
        this.isCoverLetterExist = data.coverLetter || data.coverLettersRequired.length;
      });
  }

  private watchForDropModel(): void {
    this.dragulaService.dropModel.pipe(untilDestroyed(this)).subscribe((value: any) => {
      if (value[0] === this.plansOptions.navId || value[0] === this.listId) {
        this.dndDragend.emit(value[0]);
      }

      if (value[0] === this.plansOptions.navId) {
        this.gaService.sendDragProductEvent();
      }

      if (value[0] === this.listId) {
        this.gaService.sendPresentationEvent({
          eventAction: 'Drag and drop page',
          pageName: value[1]?.firstChild?.innerText,
        });
      }
    });
  }

  private watchForSalesConceptsInfo(): void {
    combineLatest([
      // this.store.select(getSalesConcepts),
      this.store.select(getNavbarSalesConceptsPages),
      this.store.select(getSalesConceptsCount),
      this.store.select(getDependentPermissions),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([pages, count, dependentPermissions]) => {
        this.salesConceptLoading = false;
        this.salesConcepts = _.cloneDeep(pages);
        this.salesConceptsCount = count;
        this.dependentPermissions = dependentPermissions;
        this.hideSalesConcepts();

        if (this.salesConceptsCount) {
          this.store.dispatch(salesConceptsModalAvailable({ payload: true }));
        }
      });
  }

  private watchFroWindowResize(): void {
    fromEvent(this.window, 'resize')
      .pipe(throttleTime(200), untilDestroyed(this))
      .subscribe(() => {
        // Check to handle mobile behavior where scroll actions can trigger resize events
        const currentWidth = this.window.innerWidth;

        if (this.initialWidth !== currentWidth) {
          this.initialWidth = currentWidth;
          this.collapsedNavbar = this.navbarService.getNavbarCollapseState();

          this.collapseViewNavbar.emit({
            state: !this.collapsedNavbar,
            first: false,
          });

          if (this.isSharedPresentation && window.innerHeight < window.innerWidth) {
            this.onDismissModal();
          } else if (
            this.isSharedPresentation &&
            window.innerHeight > window.innerWidth &&
            !sessionStorage.getItem('isDismissedModal')
          ) {
            this.isRotateDeviceModalShown = true;
            this.showRotateDeviceModal.emit(true);
          }
        }
      });
  }

  private watchForSelectedPage(): void {
    this.store
      .select(getSelectedPage)
      .pipe(untilDestroyed(this))
      .subscribe(page => {
        this.selectedPage = page;
        this.showSettingsButton();
        this.showSalesConceptSettings();
        this.setIsTargetPremiumBtnShown();

        if (this.isSharedPresentation) {
          this.setCurrentAddedDate();
          this.updateCurrentVersionName();
        }
      });
  }

  private setCurrentAddedDate(): void {
    const foundConfig = this.dependentPagesConfig.find(
      config => config.uiId === (this.selectedPage.parentUiId || this.selectedPage.config?.uiId)
    );

    this.versionAddedDate = this.timeService.getVersionAddedDate(foundConfig?.addedDate);
  }

  private fetchNavbarCustomPages(): void {
    this.store
      .pipe(
        select(getNavbarCustomPages),
        map(data => {
          const clonedData = _.cloneDeep(data) as NavbarCustomPagesData;
          this.navbarCustomPagesData = clonedData;
          this.updateCurrentVersionName();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private updateCurrentVersionName(): void {
    if (this.navbarCustomPagesData) {
      const selectedUiId = this.selectedPage.config.uiId;
      const pageList = this.selectedPage.config.isSalesConcept
        ? this.navbarCustomPagesData.salesConcepts
        : this.navbarCustomPagesData.dependentPages;

      const foundPage = pageList?.find(page => page.config?.uiId === selectedUiId);
      this.currentVersionName = foundPage?.versionName;
    }
  }

  private watchForCompiledSaleConcepts(): void {
    this.store
      .select(getSalesConcepts)
      .pipe(untilDestroyed(this))
      .subscribe((concepts: CustomPageValues[]) => {
        this.compiledSalesConcepts = concepts;
        this.showSalesConceptSettings();
        this.showSettingsButton();
      });
  }

  private watchForPendingRequests(): void {
    combineLatest([
      this.store.select(pendingRequests),
      this.store.select(getDependentPagesPendingRequests),
      this.store.select(getPresentationConfigPending),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([pendingRequests, dependentPagesPendingRequests, getPresentationConfigPending]) => {
        this.pendingRequests = pendingRequests || dependentPagesPendingRequests || getPresentationConfigPending;
      });
  }

  private watchForPresentationPlans(): void {
    this.store.pipe(select(getPresentationPlans), untilDestroyed(this)).subscribe(plans => {
      this.plans = plans;
      this.fetchSalesConcepts = true;
    });
  }

  private watchForPresentationConfig(): void {
    this.store.pipe(select(getPresentationConfigs), untilDestroyed(this)).subscribe(configs => {
      this.configs = configs;
      this.isPresentationLocked = configs?.[0].config.locked;
    });
  }

  mergeCustomPages(page: any, isPreview = false) {
    const dependentPages = this.getLinkedPagesByPosition(page.config.uiId, isPreview);

    return dependentPages.length ? [page, ...dependentPages.filter(item => item)] : null;
  }

  getPageLabel(page: any, mainPage: any): string {
    const salesConcept = this.salesConcepts.find(item => item.config.uiId === page.config.uiId);

    return salesConcept ? salesConcept.label : page.label || mainPage.pageName;
  }

  getLinkedPages(itemId: string): CustomPageValues[] {
    return !this.isDependentPagesShown() ? [] : this.dependentPages.filter(el => el.parentUiId === itemId);
  }

  handlePageNavigation(direction: NavDirection): void {
    const index = this.navbarService.findIndexOfPage(this.mergedNavbarPages, this.selectedPage);

    const selectedPage =
      direction === NavDirections.next ? this.mergedNavbarPages[index + 1] : this.mergedNavbarPages[index - 1];

    if (selectedPage.isDependentPage) {
      this.selectItem(
        selectedPage,
        this.selectedDndItem(selectedPage),
        null,
        selectedPage.parentUiId || selectedPage.config.uiId,
        true
      );
    } else if (selectedPage.isEndPage) {
      this.dndSelect.emit(selectedPage);
      this.collapseSidebarOnSharedPresentation();
    } else {
      this.goToPage(selectedPage);
    }

    this.gaService.sendPresentationEvent({
      eventAction: direction === NavDirections.next ? 'Next Page' : 'Previous Page',
    });
  }

  isNavigationAvailable(direction: NavDirection): boolean {
    const index = this.navbarService.findIndexOfPage(this.mergedNavbarPages, this.selectedPage);
    const navigationConditions = {
      next: index < this.mergedNavbarPages.length - 1,
      back: index > 0,
    };

    return navigationConditions[direction];
  }

  showLockedPresentationMsg(): void {
    if (this.presentationEditRestricted || this.global.pageLoading) return;

    const msg = this.global.getPresentationSettings.isDistributed
      ? getLockedByDistributionMsg(this.global.getPresentation.createdByName)
      : CHANGE_LOCKED_MSG;

    this.infoModalComponent.openModal({
      resolve: {
        data: {
          header: getLockedPresentationHeader(this.global.getPresentationSettings.isDistributed),
          message: msg,
        },
      },
      windowClass: 'aapp-modal',
    });
  }

  isDependentPagesLocked(page: PageConfig): boolean {
    return this.navbarService.isDependentPagesLocked(page, this.salesConcepts);
  }

  isSaleConceptLockedAndEnabled(page: PageConfig): boolean {
    return this.navbarService.isSaleConceptLockedAndEnabled(page, this.salesConcepts);
  }

  isDependentPageChecked(concept: PageConfig, page: CustomPageValues): boolean {
    // if (!concept.config.showPreview) {
    //   return concept.config.showPreview;
    // }

    if (this.isDependentPagesLocked(concept)) {
      return true;
    }

    if (page.config.isSalesConcept) {
      return this.isSaleConceptLockedAndEnabled(concept) || concept.config.showPreview;
    } else if (page.isDependentPage) {
      return page.isPageLockedAndEnabled || page.showPreview;
    }
  }

  getIncludedDependentChecked(parentId: string): CustomPageValues {
    const dependentPages = _.cloneDeep(this.dependentPages);

    if (!dependentPages) {
      return null;
    }

    const pageConfig = _.find(this.configs, conf => conf.config.uiId === parentId);
    const parentPage = _.find(this.salesConcepts, { config: { uiId: parentId } }) as CustomPageValues;
    const dependentPage = _.cloneDeep(
      dependentPages.find(el => {
        const isShowPreviewExist = _.isUndefined(el.showPreview)
          ? this.isDependentPagesLocked(pageConfig) || el.isPageLockedAndEnabled || el.disabled
          : el.showPreview;

        return (
          el.parentUiId === parentId &&
          isShowPreviewExist &&
          (!parentPage || !this.customPageService.showDependentPageMissedPlansMessage(parentPage, this.configs))
        );
      })
    );
    dependentPage && (dependentPage.parentUiId = parentId);

    return dependentPage;
  }

  toggleCustomPage(page, uiId, checked): void {
    // const parentConfig = page.isDependentPage ? _.find(this.configs, { config: { uiId: page.parentUiId } }) : page;

    page.isDependentPage ? this.toggleDependentPage(page, uiId, checked) : this.toggleSaleConcept(page, uiId, checked);

    // if (page.isDependentPage) {
    //   this.updateDependentPageConfig(page, uiId, checked);
    // } else {
    //   const isParentEnabled = !this.getIncludedDependentChecked(uiId) && !checked ? false : page.config.isParentEnabled;
    //
    //   this.updateConfig(uiId, checked, true, isParentEnabled);
    // }
    //
    // // when need to turn of parent page(section) in case of all pages are turn off
    // if (!checked && !this.getIncludedDependentChecked(uiId)) {
    //   this.toggleSection(parentConfig, false, true, false);
    // }
    //
    // //  when need to turn on parent page(section) in case of all pages are turn off
    // if (checked && isDefined(parentConfig.config.isParentEnabled) && !parentConfig.config.isParentEnabled) {
    //   this.toggleSection(parentConfig, parentConfig.config.showPreview, true, true);
    // }

    this.gaService.sendPresentationEvent({
      eventAction: checked ? 'Enable Page' : 'Disable Page',
      pageName: page.label ?? page.pageName,
    });
  }

  toggleSaleConcept(page, uiId, checked): void {
    if (checked) {
      this.updateConfig(uiId, checked, true, true);
    }

    if (!checked) {
      if (!this.getIncludedDependentChecked(uiId)) {
        this.updateConfig(uiId, checked, true, false);
      } else {
        this.updateConfig(uiId, checked, true, page.config.isParentEnabled);
      }
    }
  }

  toggleDependentPage(page, uiId, checked): void {
    const parentConfig = _.find(this.configs, { config: { uiId: page.parentUiId } });

    this.updateDependentPageConfig(page, uiId, checked);

    if (!this.getIncludedDependentChecked(uiId) && !parentConfig.config.showPreview) {
      this.toggleSection(parentConfig, false, true, false);
    }

    if (checked && isDefined(parentConfig.config.isParentEnabled) && !parentConfig.config.isParentEnabled) {
      this.toggleSection(parentConfig, parentConfig.config.showPreview, true, true);
    }
  }

  toggleSection(
    mainPage: any,
    checked: boolean,
    updateParent?: boolean,
    checkedParentState?: boolean,
    isToggleLinkedPage?: boolean
  ): void {
    if (this.isPresentationLocked) {
      this.showLockedPresentationMsg();

      return;
    }

    const { uiId } = mainPage.config;

    this.updateConfig(uiId, checked, updateParent, checkedParentState);

    if (isToggleLinkedPage) {
      this.toggleLinkedDependent(uiId, checked);
      this.updateDependentConfig(uiId);
    }

    this.gaService.sendPresentationEvent({
      eventAction: checked ? 'Enable Page' : 'Disable Page',
      pageName: mainPage.pageName,
    });
  }

  onDismissModal(): void {
    this.isRotateDeviceModalShown = false;
    this.showRotateDeviceModal.emit(false);
    sessionStorage.setItem('isDismissedModal', 'true');
  }

  changePageVersion(): void {
    const selectedPageUiId = this.selectedPage.config.uiId.replace(/\..*$/, '');
    const data = {
      versionAddedDate: this.versionAddedDate,
      versionName: this.currentVersionName,
      uiId: selectedPageUiId,
      parentUiId: this.selectedPage.parentUiId ? this.selectedPage.parentUiId.replace(/\..*$/, '') : selectedPageUiId,
    };

    const modal = this.modalProvider.openSelectedVersionModal(data);

    modal.afterClosed.pipe(first(), untilDestroyed(this)).subscribe(result => {
      if (result.submitted) {
        this.versionAddedDate = result.versionAddedDate;
        const uiId = this.selectedPage.parentUiId || this.selectedPage.config.uiId;

        this.changeVersionAddedDate.emit({ uiId: uiId, date: result.versionAddedDate });
      }
    });
  }

  private sidebarMovementStyle(sidebarOffsetTop: any): void {
    this.sidebarOffsetStyles = {
      height: `calc(100% - ${sidebarOffsetTop}px)`,
      top: `${sidebarOffsetTop}px`,
    };
  }

  private collapseSidebarOnSharedPresentation(): void {
    if (this.isSharedPresentation && this.window.innerWidth < 1200) {
      this.collapseViewNavbar.emit({ state: this.collapsedNavbar, first: false });
      this.collapsedNavbar = true;
      this.gaService.sendPresentationEvent({ eventAction: 'Collapse Sidebar' });
    }
  }

  private isDependentPagesShown(): undefined | number {
    return this.dependentPages && this.dependentPages.length;
  }

  private getDependentPageConfig(): void {
    this.store
      .select(getDependentPagesConfig)
      .pipe(
        filter(data => {
          return _.isNull(data) || !!_.size(data);
        }),
        untilDestroyed(this)
      )
      .subscribe((data: any) => {
        _.get(data, 'configs') ? (this.dependentPagesConfig = _.cloneDeep(data.configs)) : this.updateDependentConfig();
        this.setDependentPagesShowPreview();
      });
  }

  private updateDependentPageConfig(page: CustomPageValues, uiId: string, checked: boolean): void {
    if (this.isPresentationLocked) {
      this.showLockedPresentationMsg();

      return;
    }

    this.toggleSpinnerValue(uiId, true);
    page.showPreview = checked;
    this.updateDependentConfig(uiId);
  }

  private updateDependentConfig(uiId?: string): void {
    this.buildDependentPageConfig();

    if (!this.isSharedPresentation && !this.isPresentationLocked) {
      this.apiService.putDependentPageConfig(this.global.getActivePresentationId, this.dependentPagesConfig).subscribe(
        response => {
          this.store.dispatch(
            getDependentPagesConfigLoadingSuccess({
              payload: response.data.configs,
            })
          );
          this.toggleSpinnerValue(uiId, false);
        },
        () => this.toggleSpinnerValue(uiId, false)
      );
    }
  }

  private toggleSpinnerValue(uiId?: string, value?: boolean): void {
    if (uiId) {
      this.toggleSpinner[uiId] = value;
    }
  }

  private buildDependentPageConfig(): void {
    const keys: string[] = _.reduce(
      this.configs,
      (results: string[], item) => {
        item.config.uiId && results.push(item.config.uiId);

        return results;
      },
      []
    );

    if (this.dependentPagesConfig) {
      keys.forEach((key: string) => {
        const parentConfig = _.find(this.configs, { config: { uiId: key } });
        let pageConfigs = _.find(this.dependentPagesConfig, { uiId: key });

        if (!pageConfigs) {
          pageConfigs = { uiId: key, pages: [] };
          this.dependentPagesConfig.push(pageConfigs);
        }

        const pages = pageConfigs.pages;
        pageConfigs.pages = [];
        const dependentByUiId = this.getLinkedPages(key);
        dependentByUiId.forEach(page => {
          const pageConfig = _.find(pages, { uiId: page.config.uiId }) || ({} as CustomPagesItemConfig);
          pageConfigs.pages.push(
            _.merge(pageConfig, {
              uiId: page.config.uiId,
              isSelected: this.getSelectedFlag(page, pageConfig, parentConfig),
            })
          );
        });
      });
    }
  }

  private updateConfig(
    uiId: string,
    checkedState: boolean,
    updateParent?: boolean,
    checkedParentState?: boolean
  ): void {
    if (this.isPresentationLocked) {
      this.showLockedPresentationMsg();

      return;
    }

    const payload = updateParent ? { uiId, checkedState, isParentEnabled: checkedParentState } : { uiId, checkedState };

    this.toggleSpinnerValue(uiId, true);
    this.store.dispatch(presentationHeaderDisabled({ disabled: true }));
    this.store.dispatch(ConfigsActions.updateShowPreview(payload));

    // after effect
    this.toggleSpinnerValue(uiId, false);
  }

  private hideSalesConcepts(): void {
    this.customPageService.hideSalesConcepts(this.dependentPermissions, this.dndList);

    if (!this.salesConceptLoading) {
      this.customPageService.hideUnavailableSalesConcepts(this.salesConcepts, this.dndList);
    }
  }

  private toggleLinkedDependent(parentId: string, checked: boolean): void {
    const includedDependent = this.getLinkedPages(parentId);
    this.toggleSpinnerValue(parentId, true);
    includedDependent.map(page => {
      if (page.showPreview !== checked) {
        page.showPreview = checked;
      }
    });
  }

  private getSelectedFlag(page: CustomPageValues, pageConfig: CustomPagesItemConfig, parentConfig: PageConfig): any {
    if (page.hasOwnProperty('showPreview')) {
      return page.showPreview;
    }

    if (pageConfig.hasOwnProperty('isSelected')) {
      return pageConfig.isSelected;
    }

    return parentConfig.config.showPreview;
  }

  private closest(el: Element, selector: string): Element | null {
    const matchesFn = this.utils.getMatchesFunction();
    let parent;

    while (el) {
      parent = el.parentElement;

      if (parent && (parent as any)[matchesFn](selector)) {
        return parent;
      }

      el = parent;
    }

    return null;
  }

  private getSalesConcepts(): Observable<CustomPageValues[]> {
    if (this.isSharedPresentation || !this.dndList) {
      return of([]);
    }

    const existingIds = this.salesConcepts.map(page => page.uiId);
    const permission = this.dependentPermissions.salesConcepts || [];
    const diff = _.difference(permission, this.fetchSalesConcepts ? [] : existingIds);

    if (diff.length) {
      this.fetchSalesConcepts = false;

      return this.customPageService.getSalesConceptsForModal().pipe(
        defaultIfEmpty([]),
        first(),
        map((salesConceptData: CustomPageValues[]) => {
          const result = [...salesConceptData, ...this.salesConcepts];

          return chain(result)
            .filter(item => !this.customPageService.hasSalesConceptTail(item))
            .uniqBy(item => item.config.uiId)
            .value();
        })
      );
    } else {
      return of(_.cloneDeep(_.filter(this.salesConcepts, item => !this.customPageService.hasSalesConceptTail(item))));
    }
  }

  private getLinkedPagesByPosition(uiId: string, isPreview: boolean) {
    if (!this.dependentPages) {
      return [];
    }

    let pages: CustomPageValues[];
    const parentPage = _.find(this.salesConcepts, { config: { uiId } }) as CustomPageValues;

    if (isPreview) {
      pages = this.dependentPages.filter(el => {
        return (
          el.parentUiId === uiId &&
          (parentPage.dependentPagesLocked || el.isPageLockedAndEnabled || el.showPreview) &&
          parentPage.dependentPages
        );
      });
    } else {
      pages = this.dependentPages.filter(el => el.parentUiId === uiId || _.isNull(el.parentUiId));
    }

    const dpOrder = _.orderBy(parentPage?.dependentPages, 'order');

    return dpOrder?.map(item => _.find(pages, { config: { uiId: item.uiId } })) || pages;
  }

  // private setDependentPagesShowPreview(): void {
  //   if (!this.dependentPages || !this.dependentPagesConfig.length) {
  //     return;
  //   }
  //
  //   this.dependentPages.forEach((page: CustomPageValues) => {
  //     const pageConfig = _.find(this.configs, conf => conf.config.uiId === page.parentUiId);
  //
  //     if (pageConfig) {
  //       const dependentPageConfig = _.find(this.dependentPagesConfig, conf => conf.uiId === page.parentUiId);
  //       const itemConfig = _.find(_.get(dependentPageConfig, 'pages'), { uiId: page.config.uiId });
  //       const showPreview = isDefined(itemConfig) ? itemConfig.isSelected : !page.disabled;
  //
  //       page.showPreview = _.isUndefined(showPreview) ? pageConfig.config.showPreview : showPreview;
  //     }
  //   });
  //   this.cdr.detectChanges();
  // }

  private setDependentPagesShowPreview(): void {
    if (!this.dependentPages || !this.dependentPagesConfig.length) {
      return;
    }

    this.dependentPages.forEach((page: CustomPageValues) => {
      const pageConfig = _.find(this.configs, conf => conf.config.uiId === page.parentUiId);

      if (pageConfig) {
        const { isParentEnabled } = pageConfig.config;
        const isDependentPagesLocked = this.isDependentPagesLocked(pageConfig);
        const dependentPageConfig = _.find(this.dependentPagesConfig, conf => conf.uiId === page.parentUiId);
        const itemConfig = _.find(_.get(dependentPageConfig, 'pages'), { uiId: page.config.uiId });
        const showPreviewChild = isDefined(itemConfig) ? itemConfig.isSelected : !page.disabled;
        // const showPreviewFromConfig = _.isUndefined(showPreviewChild) ? showPreview : showPreviewChild;
        // const parentUnCheckOrAllPageUnChecked = !isParentEnabled || !this.getIncludedDependentChecked(uiId);

        if (!_.isUndefined(isParentEnabled) && !isParentEnabled) {
          page.showPreview = false;
        } else {
          page.showPreview = isDependentPagesLocked || page.isPageLockedAndEnabled || showPreviewChild;
          // page.showPreview = page.isPageLockedAndEnabled || showPreviewFromConfig;
        }
      }
    });

    this.cdr.detectChanges();
  }

  private navigateToThePageByLink(href: string): void {
    const paths = href.split('/').splice(3);
    const routes = this.router.url.split('/');
    const isPath = (regex: RegExp) => `/${paths.join('/')}`.search(regex) !== -1;
    const getInternalId = (index: number) => routes[routes.length - index].split('.')[1];
    const pageId = paths[paths.length - 1];
    let selectedPage: any;

    if (this.global.isPresentationViewRoute() && isPath(PRESENTATION_SETUP_ROUTER_REGEXP)) {
      paths[paths.indexOf(PresentationRoutes.setup)] = PresentationRoutes.view;
    } else if (this.global.isPresentationSetupRoute() && isPath(PRESENTATION_VIEW_ROUTER_REGEXP)) {
      paths[paths.indexOf(PresentationRoutes.view)] = PresentationRoutes.setup;
    }

    if (paths.includes('sales-concept')) {
      selectedPage = this.dndList.find(item => item.config.uiId === `${pageId.split('.')[0]}.${getInternalId(2)}`);
    } else if (paths.includes('dependent-page')) {
      const index = routes.includes('dependent-page') ? 2 : 1;
      selectedPage = this.dependentPages.find(
        item => item.config.uiId === pageId && item.parentUiId === routes[routes.length - index]
      );
    }

    this.selectItem(
      selectedPage,
      this.selectedDndItem(selectedPage),
      event,
      selectedPage?.parentUiId || selectedPage?.config?.uiId,
      selectedPage?.config?.isSalesConcept
    );
  }

  private getDependentPages(uiIds: string[]): Observable<CustomPage[]> {
    return uiIds.length === 0
      ? of([] as CustomPage[])
      : this.apiService
          .getPages({ uiId: uiIds.join(',') })
          .pipe(map((res: ResponseType<{ data: CustomPage[]; count: number }>) => res.data.data));
  }

  private getNavbarCustomPages(): Observable<any> {
    return combineLatest([
      this.store.select(getDependentPermissions),
      this.store.select(getPresentationConfigs),
      this.store.select(getDependentPagesConfig),
    ]).pipe(
      first(),
      switchMap(configs => {
        const [permissions, presentationConfigs, dependentPagesConfig] = configs;
        const salesConceptsUiIds = { uiId: permissions?.salesConcepts || [] };
        const uiIds = this.customPageService.getCommonUiIds(salesConceptsUiIds, presentationConfigs);

        return this.navbarPagesService.getNavbarConceptsAndDependentPages(
          presentationConfigs,
          dependentPagesConfig.configs,
          uiIds
        );
      }),
      tap((data: NavbarCustomPagesData) => this.store.dispatch(setNavbarCustomPages({ payload: data })))
    );
  }
}
