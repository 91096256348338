import { AfterContentInit, Component, OnInit, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';

import { filter, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { UserService, EventService, AuthService } from '@assurance/um-services';

import { isPDF } from '@core/utils';
import { md5 } from '@core/utils';
import { ZenDeskWidgetService, Global, TimeService } from '@shared/services';
import { userManagementUrl, ensightTitle, userManagementTitle } from '@shared/constants';
import { HttpErrorHandlerService } from '@core/service';

@UntilDestroy()
@Component({
  selector: 'ensight-app',
  templateUrl: 'app.component.html',
  providers: [HttpErrorHandlerService],
})
export class AppComponent implements AfterContentInit, OnInit {
  isDefaultRoute = true;
  isSharedRoute = false;
  isNotFoundRoute = false;

  constructor(
    public authService: AuthService,
    private eventService: EventService,
    private userService: UserService,
    private global: Global,
    private router: Router,
    private httpErrorHandlerService: HttpErrorHandlerService,
    private zenDeskWidgetService: ZenDeskWidgetService,
    private titleService: Title,
    private timeService: TimeService,
    //TOD: After deleting this, was found out that we need this for assurance-modal (ModalServices)
    public viewContainerRef: ViewContainerRef
  ) {
    /*
      Dimensions:
      dimension1: Presentation ID
      dimension2: Presentation Name
      dimension3: Template ID
      dimension4: Template Name
      dimension5: User ID (shared presentation -> user ID that has created shared link to the presentation)
      dimension6: Agency ID (shared presentation -> agency ID that is owner of the presentation)
      dimension7: Agency Name (shared presentation -> agency ID that is owner of the presentation)
      dimension8: Presentation Page
      dimension9: Element Name
      dimension10: Version
     */

    this.watchForRouter();
    this.initRoute(location.hash);
  }

  ngOnInit(): void {
    this.checkHash();
    this.authWatcher();
  }

  ngAfterContentInit(): void {
    this.prefillZenDeskWidget();
  }

  private watchForRouter(): void {
    this.router.events
      .pipe(
        untilDestroyed(this),
        tap(event => {
          if (event instanceof NavigationEnd && event.url.includes(userManagementUrl)) {
            this.titleService.setTitle(userManagementTitle);
          }
        }),
        filter(() => isPDF)
      )
      .subscribe(event => {
        if (event instanceof NavigationEnd && !event.urlAfterRedirects.includes('/AssuranceAPI/auth/')) {
          this.global.userIdHash =
            this.global.userIdHash || (this.authService.isLogged ? md5(this.userService.user.emails[0]) : undefined);

          gtag('event', 'page_view', {
            page_path: event.urlAfterRedirects,
            EventTimestamp: this.timeService.getPstTimeForGa4(),
          });
          event.url.includes(userManagementUrl)
            ? this.titleService.setTitle(userManagementTitle)
            : this.titleService.setTitle(ensightTitle);
        }
      });
  }

  // - redirect hash route to path route
  // - parse query params (if exist) and extend redirection route
  private checkHash(): void {
    let isCheckForAnchorPassed = true;
    const hash = window.location.hash;

    if (this.global.isSharedPresentation()) {
      const fullUrl = window.location.href;
      const urlWithoutHash = fullUrl.split('#')[0];
      isCheckForAnchorPassed = urlWithoutHash + hash !== fullUrl;
    }

    if (hash && isCheckForAnchorPassed) {
      const [path, queryString] = hash.split('?');
      const queryParams = {};
      const queryParamsArray = queryString?.split('&');

      if (queryParamsArray) {
        queryParamsArray.forEach(item => {
          const [key, value] = item.split('=');

          if (key && value) {
            queryParams[key] = value;
          }
        });
      }

      this.router.navigate([path.replace(/#/, '')], {
        queryParams,
      });
    }
  }

  private prefillZenDeskWidget(): void {
    if (this.authService.isLogged) {
      this.zenDeskWidgetService.prefill({
        name: {
          value: `${this.userService.user.firstName} ${this.userService.user.lastName}`,
        },
        email: {
          value: this.userService.user.emails[0],
        },
      });
    }
  }

  private authWatcher(): void {
    this.eventService.httpErrors$
      .pipe(
        untilDestroyed(this),
        filter(item => item.status === 401)
      )
      .subscribe((error: HttpErrorResponse) => this.httpErrorHandlerService.handleError(error));
  }

  private initRoute(location: string): void {
    this.isSharedRoute = location.includes('shared-presentation');
    this.isNotFoundRoute = location.includes('not-found');
    this.isDefaultRoute = !this.isSharedRoute && !this.isNotFoundRoute;
  }
}
