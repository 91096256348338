import { Injectable } from '@angular/core';

import * as moment from 'moment-timezone';
import { Moment, unitOfTime } from 'moment';

import { TimeFormat, TimeZones } from '@core/constant';
import { TimeDate } from '@core/model';

@Injectable({
  providedIn: 'root',
})
export class TimeService {
  getFormattedDate(date?: TimeDate, format: string = TimeFormat.MMDDYYYY): string {
    const d = date ? moment(date) : moment();

    return d.format(format);
  }

  getUTCFormattedDate(date: TimeDate = moment(), format: string = TimeFormat.MMDDYYYY): string {
    return moment.utc(date).format(format);
  }

  getDiff(a: TimeDate, b: TimeDate): number {
    return moment(b).diff(a);
  }

  setTimeZone(date: TimeDate, zone: string, keepLocalTime?: boolean): Moment {
    return moment(date).tz(zone, keepLocalTime);
  }

  setStartOf(date: TimeDate, unitOfTime: unitOfTime.Base): Moment {
    return moment(date).startOf(unitOfTime);
  }

  getPstTimeForGa4(): string {
    return this.getFormattedDate(this.setTimeZone(new Date(), TimeZones.PST), TimeFormat.GA4_YYYYMMDDhhmmss);
  }

  convertToEDT(date: string): Date {
    const edtMoment = this.getFormattedDate(this.setTimeZone(date, TimeZones.EDT), TimeFormat.YYYYMMDDhmma);

    return new Date(edtMoment);
  }

  //TODO: need to refact
  getUTCOffsetFormattedDate(date: TimeDate, format: string): string {
    return moment.utc(date).utcOffset(-new Date().getTimezoneOffset()).format(format);
  }

  getFormattedDateWithTimezone(newAddedDate: Date): string {
    return moment(newAddedDate).tz(TimeZones.EDT).startOf('day').format();
  }

  getVersionAddedDate(date: TimeDate): Date {
    return moment.isMoment(date) ? date.toDate() : new Date(date || new Date());
  }

  normalizeDate(date: string): Date {
    return new Date(new Date(date).setHours(0, 0, 0, 0));
  }
}
