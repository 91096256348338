import { Component, ViewChild, ElementRef, OnInit, HostListener } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PresentationSharingService } from './presentation-sharing.service';
import { AlertService } from '@se/common';
import { APIService, GAService, Global, Utils } from '@shared/services';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, switchMap } from 'rxjs/operators';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { ShareableLinkResponse, TablePaginationOptions, TableRequestParams } from '@shared/models';
import { IAMService, UserService } from '@assurance/um-services';
import { ModalConfig, ModalRef } from '@assurance/bootstrap';
import { PermissionKeyEnum } from '@core/enums';
import { UserButtons } from './presentation-sharing-contants';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ExtendedInvitedUsersList, ReceiverEmails, SendEmailResponse, User } from './presentation-sharing.interfaces';

@UntilDestroy()
@Component({
  selector: 'ensight-presentation-sharing',
  styleUrls: ['presentation-sharing.scss'],
  templateUrl: 'presentation-sharing.html',
  providers: [PresentationSharingService],
})
export class PresentationSharingComponent implements OnInit {
  @ViewChild('sharedLinkElem') sharedLinkElem: ElementRef;
  @ViewChild('emailInput', { read: ElementRef }) emailInput: ElementRef;
  @ViewChild('copyBtn') copyBtn: ElementRef;

  sharedLink: string;
  presentationData: any;
  sharePresentationForm: FormGroup;
  selectedTab: 'addUser' | 'invitedUsers' = 'addUser';
  filteredUsersList: User[] = [];
  receiverUsersList: User[] = [];
  invitedUsersList: ExtendedInvitedUsersList[] = [];
  showSpinner = true;
  hasPermissionToShareViaEmail = false;
  hasPermissionToViewUsers = false;
  isAlreadyShared = false;
  isSendBtnDisabled = true;
  hasCheckedAlreadyShared = false;
  pageTabs = UserButtons;

  listPositionTop = 0;
  listPositionLeft = 0;
  listWidth = 0;
  selectedIndex = -1;
  paginationOptions: TablePaginationOptions;

  private presentationId: number;
  private clientNamePlaceholder = '';

  constructor(
    public modal: ModalRef,
    public config: ModalConfig,
    public global: Global,
    private sharingService: PresentationSharingService,
    private userService: UserService,
    private apiService: APIService,
    private iamService: IAMService,
    private gaService: GAService,
    private alertService: AlertService,
    private fb: FormBuilder,
    private utils: Utils
  ) {}

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    if (this.hasPermissionToShareViaEmail) {
      const target = event.target as HTMLElement;

      if (target.closest('#email')) {
        this.sharePresentationForm.patchValue({ recipientEmail: event.clipboardData.getData('text') });
        this.handlePasteEvent(event);
      }
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const target = event.target as HTMLInputElement;

    if (target.tagName.toLowerCase() === 'input' && target.getAttribute('placeholder') === 'email') {
      const keyActions = {
        ArrowUp: () => this.navigateList('up'),
        ArrowDown: () => this.navigateList('down'),
        Enter: () => this.selectCurrentUser(),
        Escape: () => this.resetSelection(),
      };

      if (event.key in keyActions) {
        event.preventDefault();
        keyActions[event.key]();
      }
    }
  }

  ngOnInit() {
    this.presentationId = this.config.data?.presentationId;
    this.presentationData = this.config.data?.presentationInfo;

    if (this.utils.readCookie('loggedIn')) {
      this.hasPermissionToShareViaEmail = this.iamService.hasUserAccess(PermissionKeyEnum.share_presentation_by_email);
      this.hasPermissionToViewUsers = this.iamService.hasUserAccess(PermissionKeyEnum.view_users_list);
    }

    this.fetchSharedToken();

    if (this.hasPermissionToShareViaEmail) {
      this.watchForRequestParams();
      this.setFormValues();
    }

    this.setupCopyListener();
  }

  onModalClose(): void {
    this.modal.close({ submitted: false });
  }

  onSendPresentationViaEmail(): void {
    const emails: ReceiverEmails[] = this.receiverUsersList.map(user => ({
      email: user.email,
      userId: user.id || '',
    }));

    this.showSpinner = !this.showSpinner;

    this.sharingService
      .sendShareableLinkViaEmail(
        emails,
        this.sharePresentationForm.value.shareNote,
        this.presentationId,
        this.global.isSharedPresentation()
      )
      .pipe(
        finalize(() => {
          this.showSpinner = !this.showSpinner;
        })
      )
      .subscribe(
        (response: SendEmailResponse) => {
          if (this.config.data?.isDashboard) {
            this.global.setActivePresentationId = this.presentationId;
            this.global.setPresentation = this.presentationData;
          }

          this.gaService.sendPresentationEvent({
            eventAction: 'Shared by email',
          });
          this.modal.close({ submitted: true });

          if (response?.failedEmails?.length) {
            this.handleError('Something went wrong. Please try again.');
          } else if (response.success) {
            this.showSuccessAlert('The email has been successfully sent.');
          }
        },
        error => {
          this.modal.close({ submitted: false });
          this.handleError(error);
        }
      );
  }

  copyLinkToClipboard(): void {
    const clientName = this.clientNamePlaceholder || this.presentationData.clientname;

    this.sharingService
      .copyToClipboard(this.sharedLink, clientName)
      .pipe(
        switchMap(() => {
          this.showSuccessAlert('Link copied.');

          if (!this.global.isSharedPresentation()) {
            return this.apiService.createHistoryEvent(this.presentationId, {
              eventName: 'shareable_link_copied_to_clipboard',
            });
          }

          return of(null);
        }),
        catchError(() => {
          this.handleError('We could not copy the presentation link to clipboard, please do this manually.');

          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.copyBtn?.nativeElement?.blur();
      });
  }

  selectTab(event: Record<string, string>): void {
    this.filteredUsersList = [];
    this.selectedTab = event.key as 'addUser' | 'invitedUsers';
    this.pageTabs = this.pageTabs.map(item => ({ ...item, active: event.key === item.key }));
  }

  onRemoveUserFromReceivers(emailToRemove: string): void {
    this.receiverUsersList = this.receiverUsersList.filter(user => user.email !== emailToRemove);
    this.isSendBtnDisabled = !this.receiverUsersList.length || this.receiverUsersList.some(user => user.isInvalidEmail);
  }

  selectUser(user: User): void {
    this.filteredUsersList = [];
    this.sharePresentationForm.patchValue({
      recipientEmail: '',
    });
    user.isInvalidEmail = !this.sharingService.isValidEmail(user.email);

    if (!this.receiverUsersList.some(existingUser => existingUser.email === user.email)) {
      this.receiverUsersList.push(user);
    }

    this.isSendBtnDisabled = this.receiverUsersList.some(user => user.isInvalidEmail);
  }

  setPaginationParams(options: TablePaginationOptions): void {
    this.showSpinner = !this.showSpinner;
    this.sharingService.setTableRequestParams(options);
  }

  private handlePasteEvent(event: ClipboardEvent): void {
    event.preventDefault();
    this.filteredUsersList = [];
    const pastedText = event.clipboardData.getData('text');
    const emails = pastedText
      .split(',')
      .map(email => email.trim())
      .filter(Boolean);

    this.handleMultipleEmails(emails).subscribe(users => {
      if (emails.length > 1) {
        users.forEach(user => {
          const avatar = this.sharingService.getAvatar(user.email, user.firstName, user.avatarName);

          const updatedUser = {
            ...user,
            avatarUrl: avatar.url,
            avatarInitial: avatar.initial,
          };

          this.selectUser(updatedUser);
        });

        this.sharePresentationForm.patchValue({ recipientEmail: '' });
      } else {
        this.filteredUsersList.push(users[0]);
        this.sharePresentationForm.patchValue({ recipientEmail: emails[0] });
      }
    });
  }

  private handleMultipleEmails(emails: string[]): Observable<User[]> {
    if (!this.hasPermissionToViewUsers) {
      return of(emails.map(email => ({ email, avatarInitial: this.sharingService.getAvatar(email).initial })));
    }

    const requests = emails.map(email =>
      this.sharingService
        .getUsersByEmail(email)
        .pipe(
          map(users =>
            users.length ? users : [{ email, avatarInitial: this.sharingService.getAvatar(email).initial }]
          )
        )
    );

    return forkJoin(requests).pipe(
      map(results => results.flat()),
      catchError(() => {
        return of([]);
      })
    );
  }

  private setupCopyListener(): void {
    this.sharedLinkElem?.nativeElement.addEventListener('copy', () => {
      if (this.config.data?.isDashboard) {
        const agencyId = this.userService.organization.id;
        this.sharingService
          .getAgencyNames([agencyId])
          .pipe(untilDestroyed(this))
          .subscribe(response => {
            const name = response.data[0]?.name;
            this.gaService.sendShareableLinkEvent(name, agencyId);
          });
      } else {
        this.gaService.sendShareableLinkEvent(this.userService.organization.name, this.userService.organization.id);
      }

      this.gaService.sendPresentationEvent({ eventAction: 'Copy Shareable Link' });
    });
  }

  private fetchSharedToken(): void {
    if (!this.presentationData.shareableToken && !this.global.isSharedPresentation()) {
      this.sharingService
        .getSharedToken(this.presentationId)
        .pipe(
          untilDestroyed(this),
          catchError((err: any) => {
            this.handleError(`We could not complete your request. ${err}`);

            return of(null);
          }),
          finalize(() => {
            if (!this.hasPermissionToShareViaEmail) {
              this.showSpinner = !this.showSpinner;
            }
          })
        )
        .subscribe((data: ShareableLinkResponse) => {
          if (data) {
            this.sharedLink = this.sharingService.generateSharedLink(data.token);
            this.clientNamePlaceholder = data.clientName2
              ? `${data.clientName} and ${data.clientName2}`
              : data.clientName;
          }
        });
    } else {
      this.sharedLink = this.sharingService.generateSharedLink(this.presentationData.shareableToken);

      if (!this.hasPermissionToShareViaEmail) {
        this.showSpinner = !this.showSpinner;
      }
    }
  }

  private watchForRequestParams(): void {
    this.sharingService
      .watchForRequestParams()
      .pipe(
        switchMap((params: TableRequestParams) =>
          this.sharingService.getExtendedinvitedUsersList(
            this.presentationId,
            this.global.isSharedPresentation(),
            params.PAGE
          )
        ),
        untilDestroyed(this)
      )
      .subscribe(({ data, totalCount }) => {
        this.setInvitedUsersData(data, totalCount);
        this.checkIfAlreadySharedOnce(totalCount);
      });
  }

  private checkIfAlreadySharedOnce(totalCount: number): void {
    if (!this.hasCheckedAlreadyShared) {
      this.hasCheckedAlreadyShared = true;

      if (this.isAlreadyShared) {
        this.prepareTabs(totalCount);
      }
    }
  }

  private setInvitedUsersData(extendedInvitedUsers: ExtendedInvitedUsersList[], totalCount: number): void {
    extendedInvitedUsers = extendedInvitedUsers.map(user => {
      const avatar = this.sharingService.getAvatar(user.receiverEmail, user.firstName, user.avatarName);

      return {
        ...user,
        avatarUrl: avatar.url,
        avatarInitial: avatar.initial,
      };
    });
    this.invitedUsersList = extendedInvitedUsers;
    this.isAlreadyShared = !!this.invitedUsersList.length;
    this.paginationOptions = {
      currentPage: this.sharingService.getPaginationParams().PAGE,
      totalElements: totalCount,
      itemsPerPage: 5,
    };

    this.showSpinner = !this.showSpinner;
  }

  private setFormValues(): void {
    this.sharePresentationForm = this.fb.group({
      recipientEmail: [''],
      shareNote: [''],
    });

    this.sharePresentationForm
      .get('recipientEmail')
      .valueChanges.pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        debounceTime(300),
        filter((value: string) => value.trim() !== ''),
        switchMap((value: string) => {
          this.updatePortalPosition();

          return this.hasPermissionToViewUsers ? this.sharingService.getUsersByEmail(value) : of([]);
        })
      )
      .subscribe((users: User[]) => {
        this.filteredUsersList = this.handleFilteredUsersList(users);
      });
  }

  private handleFilteredUsersList(users: User[]): User[] {
    if (users.length) {
      return users.map(user => {
        const avatar = this.sharingService.getAvatar(user.email, user.firstName, user.avatarName);

        return {
          ...user,
          avatarUrl: avatar.url,
          avatarInitial: avatar.initial,
        };
      });
    }

    const email = this.sharePresentationForm.get('recipientEmail').value;

    return [
      {
        email: email,
        avatarUrl: '',
        avatarInitial: this.sharingService.getAvatar(email).initial,
      },
    ];
  }

  private prepareTabs(totalCount: number): void {
    this.pageTabs = this.pageTabs.map(btn => {
      return {
        ...btn,
        label: btn.key === 'invitedUsers' ? `${btn.label} (${totalCount})` : btn.label,
        disabled: false,
      };
    });
  }

  private updatePortalPosition(): void {
    if (this.emailInput) {
      const { bottom, left, width } = this.emailInput?.nativeElement?.getBoundingClientRect() || {};

      this.listPositionTop = bottom;
      this.listPositionLeft = left;
      this.listWidth = width;
    }
  }

  private navigateList(direction: 'up' | 'down'): void {
    if (!this.filteredUsersList.length) {
      return;
    }

    const directionActions = {
      up: () => {
        this.selectedIndex = this.selectedIndex > 0 ? this.selectedIndex - 1 : this.filteredUsersList.length - 1;
      },
      down: () => {
        this.selectedIndex = this.selectedIndex < this.filteredUsersList.length - 1 ? this.selectedIndex + 1 : 0;
      },
    };

    directionActions[direction]();
  }

  private selectCurrentUser(): void {
    if (this.selectedIndex !== -1 && this.selectedIndex <= this.filteredUsersList.length) {
      this.selectUser(this.filteredUsersList[this.selectedIndex]);
      this.resetSelection();
    }
  }

  private resetSelection(): void {
    this.selectedIndex = -1;
    this.filteredUsersList = [];
  }

  private handleError(message: string): void {
    this.alertService.openAlert({
      type: 'error',
      body: message,
      autoClose: 5000,
    });
  }

  private showSuccessAlert(message: string): void {
    this.alertService.openAlert({
      type: 'success',
      body: message,
      autoClose: 5000,
    });
  }
}
