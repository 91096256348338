import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { AlertService } from '@se/common';
import { select, Store } from '@ngrx/store';
import { AuthService } from '@assurance/um-services';

import { AppState } from '../../../reducers';
import { getOrganizationsSwitchData, getOrganizationsSwitchDataPending } from '@ngrx-app/global.selectors';
import { ensightTitle } from '@shared/constants';

@Component({
  selector: 'ensight-organizations-switch',
  styleUrls: ['organizations-switch.component.scss', '../login.scss'],
  templateUrl: 'organizations-switch.component.html',
})
export class OrganizationsSwitchComponent implements OnInit {
  transactionUUID = this.route.snapshot.queryParams.transactionUUID;
  organizationsSwitch = this.authService.isLogged;
  organizationsSwitchPending$ = this.store.pipe(select(getOrganizationsSwitchDataPending));
  response$ = this.store.pipe(select(getOrganizationsSwitchData));

  constructor(
    private router: Router,
    private authService: AuthService,
    private location: Location,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private store: Store<AppState>,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(ensightTitle);
  }
  // TODO: need to change interface
  // submitForm(response: { success: boolean; data: { message: string; error: { message: string[] } } }): void {
  submitForm(response: any): void {
    if (response.success) {
      this.location.replaceState(this.transactionUUID ? `/home?transactionUUID=${this.transactionUUID}` : '/home');
      location.reload();
    } else {
      this.alertService.openAlert({
        type: 'error',
        body: response.data?.error?.message?.toString() || response.data.message,
        autoClose: 5000,
      });
    }
  }

  cancelForm(): void {
    this.router.navigate(['/home']);
  }
}
