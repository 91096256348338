import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import * as _ from 'lodash-es';
import { combineLatest, Observable } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';

import { IAMService, AuthService, UserService } from '@assurance/um-services';

import { APIService, Global, MetricsService } from '@shared/services';
import * as setupConstant from './setup.constant';
import { AppState } from '../../../reducers';
import { getRules } from '../modals/cover-sheet-modal/cover-sheet-modal.selectors';
import { addSelectedPlanValues, changeSelectedPageId, setupDisclosureTextUpdateSuccess } from './setup.actions';
import * as ConfigsActions from '../redux/configs/actions';
import {
  chain,
  DEFAULT_DISCLOSURE_TEXT,
  PRODUCT_TYPE_DISCLOSURE_RULE_MAP,
  RetirementShortfallMetrics,
} from '@shared/constants';
import { RetirementShortfallService } from './retirement-shortfall/retirement-shortfall.service';
import {
  PageConfig,
  GlobalConfig,
  CustomPageValues,
  CustomPagesConfig,
  SingleViewPlanMetric,
  PlanOption,
} from '@shared/models';
import { ChartDataService } from '@core/service';
import { PIN_VALUE_MARGIN, CHART_HEIGHT, PIN_LINE_HEIGHT } from '@shared/components/chart/chart.constants';
import { CustomPageService } from './custom-page/custom-page.service';
import * as SettingsActions from '../redux/configs/settings.actions';
import * as VisualizationActions from '../redux/configs/visualization.actions';
import {
  getPresentationConfigs,
  getPresentationGlobalConfig,
  getPresentationPageConfig,
} from '../redux/configs/selectors';
import { presentationPlansUpdateSuccess } from '../presentation.actions';
import { PlansService } from '../presentation-plans/plans.service';
import { coversheetPageName } from '../../../common/shared/components/assurance-navbar/navbar.constants';
import {
  Presentation,
  CareerPlan,
  ActivePlan,
  DragModelData,
  XAxisSourceType,
  PointData,
  ChartData,
} from '@core/model';
import { CALCULATED_DATA_TARGET, PermissionKeyEnum, PresentationRoutes } from '@core/enums';

@Injectable()
export class SetupService {
  public maxAgeTransformed: number;

  // TODO: need to remove, it doesnt use
  private classNameForChartValue: string;

  set classNameForChart(className) {
    this.classNameForChartValue = className;
  }

  get classNameForChart() {
    return this.classNameForChartValue;
  }

  constructor(
    public iamService: IAMService,
    private global: Global,
    private apiService: APIService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private store: Store<AppState>,
    private plansService: PlansService,
    public retirementShortfallService: RetirementShortfallService,
    public chartDataService: ChartDataService,
    public customPageService: CustomPageService,
    private metricsService: MetricsService
  ) {}

  public static filterPlansByIllustration(plans: CareerPlan[]): CareerPlan[] {
    return plans.filter((plan: CareerPlan) => plan.pdfIllustration.linkHash);
  }

  public setDisclosures(agencyRules: Record<string, string>, presentation: Presentation): void {
    if (!agencyRules || !presentation) {
      return;
    }

    const plans = this.global.getCurrentCarrierPlans || [];
    let disclosureText: string;

    const mappedPlans: number[] = plans.map((plan: CareerPlan) => {
      const productTypeIndex = plan.configjson?.metadata?.product_type_index;

      return productTypeIndex ? Number(productTypeIndex) : (productTypeIndex as number);
    });

    const getUniq = <T>(items: T[]): T[] => Array.from(new Set(items));

    const productTypeIndexes = getUniq<number>(mappedPlans);

    const disclosureFields = productTypeIndexes.map((i: number) => {
      return PRODUCT_TYPE_DISCLOSURE_RULE_MAP[i]
        ? PRODUCT_TYPE_DISCLOSURE_RULE_MAP[i]
        : PRODUCT_TYPE_DISCLOSURE_RULE_MAP.other;
    });

    const fields = getUniq<string>(disclosureFields);

    fields.forEach((field: string) => {
      const text = agencyRules[field];

      if (text && disclosureText) {
        disclosureText = disclosureText + `<br><br>${text}`;
      } else if (text) {
        disclosureText = text;
      }
    });

    if (!disclosureText) {
      disclosureText = DEFAULT_DISCLOSURE_TEXT;
    }

    this.store.dispatch(setupDisclosureTextUpdateSuccess({ payload: disclosureText }));
  }

  public getNavigationPath(page: any, isView?: boolean) {
    if (!_.has(page, 'config')) {
      return;
    }

    let navigationRoute = page.config.uiId;

    if (
      page.config.isSinglePolicy ||
      (page.config.uiId && _.isString(page.config.uiId) && page.config.uiId.includes('single_policy'))
    ) {
      navigationRoute = navigationRoute.replace(/[0-9]/g, '');
    }

    let localRoute;

    if (this.global.getPresentation?.isDistributed) {
      const isViewMode = this.global.isPresentationView || isView;
      localRoute = this.setRouteForDistributedCase(isViewMode);
    } else {
      localRoute = this.global.isPresentationView || isView ? PresentationRoutes.view : PresentationRoutes.setup;
    }

    const isSharedPresentation = this.global.isSharedPresentation();
    const route = [
      isSharedPresentation ? '/shared-presentation' : '/presentation',
      isSharedPresentation ? this.global.getSharedToken() : this.global.getActivePresentationId,
      localRoute,
    ];

    if (page.config.isSalesConcept || page.isEndPage || page.isDependentPage || page.isRequiredCoverSheet) {
      route.push(...this.getCustomPageRoute(page));
    } else {
      navigationRoute = _.kebabCase(navigationRoute);
    }

    return [...route, navigationRoute];
  }

  private setRouteForDistributedCase(isView = false): PresentationRoutes {
    const canViewSharedCase = this.iamService.hasUserAccess(PermissionKeyEnum.view_shared_case);
    const canEditSharedCase = this.iamService.hasUserAccess(PermissionKeyEnum.edit_shared_case);

    if (!canViewSharedCase) {
      return PresentationRoutes.home;
    }

    return canEditSharedCase && !isView ? PresentationRoutes.setup : PresentationRoutes.view;
  }

  public getCustomPageRoute(page: CustomPageValues | PageConfig): string[] {
    if ((page as CustomPageValues).isEndPage) {
      return ['end-page'];
    } else if ((page as CustomPageValues).isRequiredCoverSheet) {
      return ['required-coversheet'];
    } else if ((page as CustomPageValues).isDependentPage) {
      const parentUiId = page.parentUiId;

      return parentUiId ? ['dependent-page', parentUiId] : ['dependent-page'];
    } else if ((page as PageConfig).config?.isSalesConcept) {
      return ['sales-concept'];
    }
  }

  public navigatePage(page: any, replaceUrl = false) {
    const path = this.getNavigationPath(page);

    if (path) {
      this.router.navigate(path, { replaceUrl });
    }
  }

  public setPlansValuesByAge(
    plans: CareerPlan[],
    age: number,
    yKey: string,
    xKey?: XAxisSourceType,
    visualizationUiId?: string
  ): void {
    const xKeyItem = xKey ? xKey : 'eoy_age';
    const chartValues: Array<{
      visualizationUiId: string;
      value: number | string;
      planId: number;
      additionalLabel: string;
      show: boolean;
    }> = plans.map((plan: any) => {
      const enableIterator = Object.keys(plan.configjson.data).includes(yKey);
      const productName = plan.configjson.metadata.product_name;

      if (!enableIterator) {
        return {
          visualizationUiId,
          value: undefined as any,
          planId: plan.id,
          additionalLabel: productName,
          show: !plan.disable,
        };
      }

      const i = _.indexOf(plan.configjson.data[xKeyItem], age ? age.toString() : '0');

      if (_.isNaN(1 * plan.configjson.data[yKey][i]) && i >= 0) {
        return {
          visualizationUiId,
          value: 'N/A',
          planId: plan.id,
          additionalLabel: productName,
          show: !plan.disable,
        };
      } else {
        return {
          visualizationUiId,
          value: plan.configjson.data[yKey][i],
          planId: plan.id,
          additionalLabel: productName,
          show: !plan.disable,
        };
      }
    });

    this.store.dispatch(addSelectedPlanValues({ payload: chartValues }));
  }

  public getMinAgeValue(config?: PageConfig) {
    const min = this.getConfigXMin(config) || this.global.presentationSettings.minAge;

    return _.isNaN(min) ? null : min;
  }

  public getMaxAgeValue(config: PageConfig, agencyMaxAge: any, selectedPageId: string, graphValue: XAxisSourceType) {
    const max =
      this.getConfigXMax(config) ||
      this.global.presentationSettings.maxAge ||
      this.agencyMaxAgeConvert(config, agencyMaxAge, selectedPageId, graphValue);

    return _.isNaN(max) ? null : max;
  }

  public getDefaultPinValue(config: PageConfig, activePlans: any, graphValue: XAxisSourceType): number {
    const xSource = this.getXAxisSource(config, graphValue);
    const first = [];
    activePlans.forEach(plan => {
      if (config.config.isSinglePolicy) {
        first.push(Number(_.get(plan, `${CALCULATED_DATA_TARGET.data}[${xSource}][0]`)));

        return;
      }

      if (_.get(plan, `${CALCULATED_DATA_TARGET.data}[${config.config.selectedMetricKey}]`)) {
        first.push(Number(plan.configjson.data[xSource][0]));
      }
    });
    const start: number = _.min(first);

    return PIN_VALUE_MARGIN + start;
  }

  public getSelectedPlanValue(x: any, xSource: string, key: any, plan: any): string | number {
    const index = _.findIndex(plan.configjson.data[xSource], item =>
      item && x ? item.toString() === x.toString() : false
    );

    return _.get(plan, `${CALCULATED_DATA_TARGET.data}[${key}][${index}]`);
  }

  public getActiveTabs(data, tabs): string[] {
    const planMetricsKeys = Object.keys(data);
    const activeTabs = [];

    for (const tab of tabs) {
      const metricsKeys = tab.metrics.map(metric => metric.key);

      if (!metricsKeys.length || _.difference(metricsKeys, planMetricsKeys).length !== metricsKeys.length) {
        activeTabs.push(tab.tabTitle);
      }
    }

    return activeTabs;
  }

  public getUpdatedCarrierPlans(): Observable<unknown> {
    return this.apiService.getCareerPlans(this.global.getActivePresentationId).pipe(
      tap(response => {
        // TODO: need to change interface
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.global.setCurrentCarrierPlans = response.data;
        // TODO: need to change interface
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.store.dispatch(presentationPlansUpdateSuccess({ plans: response.data }));
      })
    );
  }

  public getAvailablePage(pagesList, pageId: string) {
    const selectedPage = pagesList.find(el => el.config.uiId === pageId && el.config.showPreview);

    if (!pageId || !selectedPage) {
      return this.getFirstAvailablePage(pagesList);
    }

    return selectedPage;
  }

  public sortPagesByOrder<T>(pages: T[], by: string | string[] = 'pageOrder'): T[] {
    return _.sortBy(pages, by);
  }

  public getFirstAvailablePage(pagesList: any[]): any {
    const filteredByPermissions = this.checkPermissions(pagesList);
    const sorted = this.sortPagesByOrder(filteredByPermissions);

    return _.find(sorted, el => el.config.showPreview);
  }

  public getFirstPage(pagesList: any[]): any {
    const filteredByPermissions = this.checkPermissions(pagesList);
    const sorted = this.sortPagesByOrder(filteredByPermissions);

    return this.defineFirstPage(sorted);
  }

  public defineFirstPage(soredList: PageConfig[], showPreview = false) {
    const list = showPreview ? _.filter(soredList, { config: { showPreview: true } }) : soredList;

    return (
      _.find(list, item => item.isRequiredCoverSheet || item.isCoverSheet || item.config.uiId === 'cover') || list[0]
    );
  }

  public checkIfHasNoSelectedPlan(item) {
    return (
      (item.config.uiId === 'retirement_shortfall' || item.config.uiId === 'charges') && !item.config.selectedPlanId
    );
  }

  public disableVisualizationsConfig(data: CareerPlan[], config: any): string[] {
    const retirementConf: any = this.getConfig('retirement_shortfall', config);
    const retirementDisabledMetrics = _.every(data, item => {
      return !this.metricsService.validateRetirement(item, RetirementShortfallMetrics);
    });
    const disabledPages: string[] = [];

    retirementDisabledMetrics && disabledPages.push(_.get(retirementConf, '[1].config.uiId'));

    return disabledPages;
  }

  public getConfig(uiId: string, config: any): any {
    const pageConfig = _.find(config, { config: { uiId } });

    return [config[0], pageConfig];
  }

  public getTooltipContent() {
    return this.iamService.hasUserAccess('PRSNT_EDIT') ? 'Presentation Setup' : 'Dashboard';
  }

  public getDefaultScheme(scheme: any) {
    scheme.map((color: any) => {
      color.free = true;
    });

    return scheme;
  }

  public getSelectedPageConfig(config: [GlobalConfig, PageConfig], selectedPageId: string): GlobalConfig | PageConfig {
    let page = _.find(config, {
      config: { uiId: selectedPageId },
    });

    if (!page) {
      page = config[1];
    }

    return page;
  }

  public getConfigTipValue(config: PageConfig): number | null {
    const tipConfig: any = _.find(
      config.config.planMetrics,
      config.config.isSinglePolicy
        ? {
            tabTitle: config.config.selectedMetricKey,
          }
        : {
            key: config.config.selectedMetricKey,
          }
    );

    if (tipConfig) {
      const numericTipValue =
        typeof tipConfig.tipValue === 'string' ? parseFloat(tipConfig.tipValue) : tipConfig.tipValue;

      return !isNaN(numericTipValue) ? numericTipValue : null;
    }

    return null;
  }

  public getConfigXMin(config: PageConfig): number {
    return _.has(config, 'config.chartConfig') ? config.config.chartConfig.xMin : null;
  }

  public getConfigXMax(config: PageConfig): number {
    return _.has(config, 'config.chartConfig') ? config.config.chartConfig.xMax : null;
  }

  public updateSavedConfigTipValue(config: PageConfig[]) {
    config.forEach((item: PageConfig) => {
      if (item.config.planMetrics) {
        const content = [];
        item.config.planMetrics.forEach(metricConfig => {
          content.push({
            key: metricConfig.key,
            tipValue: metricConfig.tipValue,
          });
        });
        this.store.dispatch(
          SettingsActions.updateConfigPlanMetricsTipValue({
            values: [{ uiId: item.config.uiId, content }],
          })
        );
      }
    });
  }

  public updatePlanMetricsTipValues(planMetrics: DragModelData[], pageConfig) {
    if (!planMetrics || !pageConfig) return;

    return planMetrics.map(value => {
      const metric = pageConfig.planMetrics.find(metric => metric.key === value.key);

      return { ...value, tipValue: metric.tipValue };
    });
  }

  // config: [GlobalConfig, PageConfig]
  public getActivePlans(plans: CareerPlan[], config: [GlobalConfig, PageConfig], selectedPageId: string): CareerPlan[] {
    const activePlans: CareerPlan[] = [];
    let index = 0;
    const conf = this.getSelectedPageConfig(config, selectedPageId);

    if (!conf || !conf.config || !conf.config.activePlans) {
      return [];
    }

    const activePlansConf: ActivePlan[] = _.cloneDeep(conf.config.activePlans);

    if (activePlansConf) {
      activePlansConf.forEach((item: ActivePlan) => {
        // const plan = _.find(plans, { id: item.carrierPlanId });
        const plan = plans.find((plan: CareerPlan) => plan.id === item.carrierPlanId);

        if (plan) {
          activePlans.push({
            ...plan,
            disable: !item.isShown,
            index,
          });
          index++;
        }
      });
    }

    return _.orderBy(activePlans, ['order']);
  }

  public setInteractiveGuidelineConfig(value: number, config: any, selectedPageId: string): void {
    const conf = this.getSelectedPageConfig(config, selectedPageId) as PageConfig;

    if (!_.has(conf, 'config.selectedMetricKey')) return;

    const uiId = config[1].config.uiId;
    const selectedMetricKey = config[1].config.selectedMetricKey;

    const selectedMetrics = conf.config.isSinglePolicy
      ? conf.config.planMetrics.filter(planMetric => planMetric.tabTitle === selectedMetricKey)
      : [conf.config.planMetrics.find(planMetric => planMetric.key === selectedMetricKey)];

    if (selectedMetrics[0] && selectedMetrics[0].tipValue !== value) {
      const content = selectedMetrics.map(metric => ({
        key: metric.key,
        tipValue: value,
      }));

      this.store.dispatch(
        SettingsActions.updateConfigPlanMetricsTipValue({
          values: [{ uiId, content }],
        })
      );
    }
  }

  public setSelectedMetricConfig(metricKey, config): void {
    if (metricKey && _.has(config[1], 'config.selectedMetricKey')) {
      this.store.dispatch(
        VisualizationActions.updateSelectedMetricKey({
          uiId: config[1].config.uiId,
          selectedMetricKey: metricKey,
        })
      );
    }
  }

  public getPageNameConfig(config: any) {
    return config[1].pageName;
  }

  public getPageConfig(pageId: any): Observable<[GlobalConfig, PageConfig]> {
    return combineLatest([
      this.store.select(getPresentationGlobalConfig),
      this.store.select(getPresentationPageConfig(pageId)),
    ]).pipe(first());
  }

  public setActivePlansConfig(activePlans: CareerPlan[] | SingleViewPlanMetric[], selectedPageId: string): void {
    const isSingleView = selectedPageId.includes('single_policy_');
    this.store
      .select(getPresentationConfigs)
      .pipe(first())
      .subscribe(config => {
        const configsActivePlans = {};
        config.forEach(conf => {
          const activePlansConfig = activePlans.map(plan => {
            const id = isSingleView ? plan.key : plan.id;
            const idKey = isSingleView ? 'metricId' : 'carrierPlanId';
            const selectedConfigPlan = conf.config.activePlans.find(
              (configPlan: ActivePlan) => configPlan[idKey] === id
            );

            const activePlan = {
              [idKey]: id,
              isShown: selectedConfigPlan?.isShown !== undefined ? selectedConfigPlan.isShown : true,
            };

            return isSingleView ? { ...activePlan, tabTitle: plan.tabTitle } : activePlan;
          });

          if (isSingleView && conf.config.uiId === selectedPageId) {
            configsActivePlans[conf.config.uiId] = activePlansConfig;
          }

          if (
            !isSingleView &&
            conf.config.uiId !== 'retirement_shortfall' &&
            !conf.config.isSinglePolicy &&
            conf.config.uiId !== 'spreadsheet' &&
            conf.internalId !== 0 &&
            conf.config.uiId !== 'charges' &&
            !conf.config.isSalesConcept
          ) {
            configsActivePlans[conf.config.uiId] = activePlansConfig;
          }
        });
        this.store.dispatch(
          ConfigsActions.setActivePlans({
            configsActivePlans,
          })
        );
      });
  }

  public generateChartConfigForPDF(options: Record<string, string | number> = {}) {
    return {
      chart: {
        pinLeftMargin: 110,
        duration: 0,
        animationDuration: 0,
        fixedChartWidth: options.fixedChartWidth,
        margin: {
          left: 160,
        },
        height: CHART_HEIGHT,
        pinLineHeight: PIN_LINE_HEIGHT,
      },
    };
  }

  public setSelectedPlanId(planId: number): void {
    this.global.selectedSinglePlanId = planId;
  }

  public getYAxisType(selectedPageConf: PageConfig): string {
    return selectedPageConf.config.chartConfig.yAxisType;
  }

  public getXAxisSource(selectedPageConf: PageConfig, graphValue: XAxisSourceType): XAxisSourceType {
    return selectedPageConf?.config?.chartConfig ? selectedPageConf.config.chartConfig.xAxisSource : graphValue;
  }

  public findInteractiveGuidelineValueIndex(value: number, chartData: PointData[]): number {
    return _.findIndex(chartData, { x: value });
  }

  public getLongestValues(data: ChartData[]): any[] {
    let values: any[] = [];

    if (data) {
      data.forEach(item => {
        if (item.values.length > values.length) {
          values = item.values;
        }
      });
    }

    return values;
  }

  public firstAvailableDependentPageId(dependentPagesConfig: CustomPagesConfig[]): string {
    return dependentPagesConfig
      .find(config => config.pages && config.pages.some(page => page.isSelected))
      ?.pages.find(page => page.isSelected)?.uiId;
  }

  public getFirstAvailableDependentPageId(
    dependentPagesConfig: CustomPagesConfig[],
    dependentPages: CustomPageValues[]
  ): { dependentPageId: string; parentUiId: string } | undefined {
    const config = dependentPagesConfig.find(
      config =>
        config.pages &&
        config.pages.some(page => page.isSelected) &&
        _.filter(dependentPages, { parentUiId: config.uiId })?.length
    );

    if (!config || !dependentPages.length) {
      return;
    }

    const filteredPages = _.filter(dependentPages, { parentUiId: config.uiId });
    const before = _.filter(filteredPages, { before: true });
    const after = _.filter(filteredPages, { after: true });
    const pages = _.filter(config.pages, { isSelected: true });
    const orderedPages = _.sortBy(pages, page => _.findIndex([...before, ...after], { config: { uiId: page.uiId } }));
    const dependentPageId = orderedPages.find(page => page.isSelected)?.uiId;

    return dependentPageId && { dependentPageId, parentUiId: config.uiId };
  }

  public selectFirstAvailablePage(configs: (GlobalConfig & PageConfig)[]): PageConfig {
    const pageConfig = this.getFirstAvailablePage(configs);
    this.setSelectedPageId(pageConfig?.config?.uiId);

    return pageConfig;
  }

  public setSelectedPageId(pageID: string): void {
    this.store.dispatch(changeSelectedPageId({ payload: pageID }));
  }

  public getMinPinValue(data: ChartData[]): number {
    const firstX = this.getFirstValueBySource(data, 'x');
    const lastX = this.getLastValueBySource(data);
    let defaultX = firstX + PIN_VALUE_MARGIN || PIN_VALUE_MARGIN;
    defaultX > lastX && (defaultX = lastX);
    const minXByValidY = this.getFirstValueBySource(data, 'y');

    return minXByValidY > defaultX ? minXByValidY : defaultX;
  }

  public findPlanMetricsMin(plans: CareerPlan[], selectedMetricKey: string): number {
    let min: any = null;

    plans.forEach((plan: CareerPlan) => {
      const metric = _.get(plan, `${CALCULATED_DATA_TARGET.data}.${selectedMetricKey}`);

      if (metric && (_.isNull(min) || _.min(metric.map(Number)) < min)) {
        min = _.min(metric.map(Number));
      }
    });

    return min;
  }

  public findPlanMetricsMax(plans: any, selectedMetricKey: string): number {
    let max = 0;
    plans.forEach((plan: any) => {
      const metric = _.get(plan, `${CALCULATED_DATA_TARGET.data}.${selectedMetricKey}`);

      if (metric && _.max(metric.map(Number)) > max) {
        max = _.max(metric.map(Number));
      }
    });

    return max;
  }

  public isIRRPlan(selectedMetricId: string): boolean {
    return _.indexOf(setupConstant.irrPlanIds, selectedMetricId) !== -1;
  }

  setActualPlansOrdering(plansData: CareerPlan[]): CareerPlan[] {
    return plansData.map((planData: CareerPlan) => {
      return {
        ...planData,
        order: this.global.getCurrentCarrierPlans.find(carrierPlan => carrierPlan.id === planData.id).order,
      };
    });
  }

  public updatePlanFromPage(data: CareerPlan, note: string) {
    data.configjson.metadata.note = note;
    data.presentationId = this.global.getActivePresentationId;
    const dataForPlanUpdate = {
      jsonCarrierPlanData: data,
      planId: data.id,
    };

    return this.plansService.updatePlan(dataForPlanUpdate).pipe(
      switchMap(() => {
        return this.global.getPageConfig(this.global.getActivePresentationId, false);
      }),
      first()
    );
  }

  public buildPlansNav(plans: CareerPlan[]): PlanOption[] {
    const list = plans.map((plan: CareerPlan) => {
      const id = plan.id;
      const planOrder = plan.order;
      const configJson = typeof plan.configjson === 'string' ? JSON.parse(plan.configjson) : plan.configjson;

      return {
        id,
        label: configJson.metadata.company_name,
        order: planOrder,
        productName: configJson.metadata.product_name,
        productNote: configJson.metadata.note,
        productType: configJson.metadata.product_type,
        carrierCode: configJson.metadata.carrier_code,
      };
    });

    return _.sortBy(list, 'order');
  }

  public reorderPlans(plansList: PlanOption[]) {
    // we shouldn't save coversheet order
    const onlyPlans = _.filter(plansList, (item: PlanOption) => (item.id as any) !== 'cover-details');
    const data = onlyPlans.map((plan: PlanOption, index: number) => {
      return {
        id: plan.id,
        order: index,
      };
    });

    return this.apiService.updatePlansOrder(data, this.global.getActivePresentationId);
  }

  // data: CareerPlan[] - presentation career plans
  // plans: CareerPlan[] - current career plans? career plans all ?
  public checkIfPlansIdsUpdated(plans: CareerPlan[], data: CareerPlan[]): boolean {
    return plans.some(plan => data.find(newPlan => plan.id !== newPlan.id));
  }

  public matchNewIdByIdForPlans(plans: CareerPlan[], data: CareerPlan[], activePlans = false) {
    return plans?.map(plan => {
      const newPlan = _.cloneDeep(data.find(newPlan => plan.id === newPlan.id));

      if (activePlans) {
        return newPlan
          ? {
              ...newPlan,
              color: plan.color,
              index: plan.index,
              disable: plan.disable,
              id: newPlan.id,
            }
          : plan;
      }

      return newPlan ? { ...newPlan, id: newPlan.id } : plan;
    });
  }

  // public setTermLimitByXSource(plans: CareerPlan[], xAxisSource: string) {
  //   if (xAxisSource !== 'eoy_age') {
  //     plans.forEach((plan: CareerPlan) => {
  //       plan.termLimit = plan.configjson.data.eoy_age.indexOf(plan.termLimit) + 1;
  //     });
  //   }
  // }

  public getValidationRange(xAxisSource: string) {
    return {
      min: this.findPlanMetricsMin(this.global.getCurrentCarrierPlans, xAxisSource),
      max: this.findPlanMetricsMax(this.global.getCurrentCarrierPlans, xAxisSource),
    };
  }

  public getAgencyRules(firstOperator = true) {
    const selector = this.store.select(getRules);

    if (firstOperator) {
      return selector.pipe(first());
    }

    return selector;
  }

  private agencyMaxAgeConvert(
    config: PageConfig,
    agencyMaxAge: any = '',
    selectedPageId: string,
    graphValue: XAxisSourceType
  ): number {
    const age = agencyMaxAge === '' ? null : Number(agencyMaxAge);

    if (age && !this.isEoyAge(selectedPageId, [config], graphValue)) {
      this.maxAgeTransformed = this.chartDataService.agePolicyYearConvert(
        age,
        graphValue,
        _.cloneDeep(this.global.getCurrentCarrierPlans)
      );
    } else {
      this.maxAgeTransformed = age;
    }

    return this.maxAgeTransformed || age;
  }

  public isEoyAge(selectedPageId: string, configs: PageConfig[], graphValue: XAxisSourceType): boolean {
    const selectedPageConf = _.find(configs, {
      config: { uiId: selectedPageId },
    } as any);

    if (!selectedPageConf || selectedPageConf.config.uiId === 'spreadsheet') {
      return graphValue === 'eoy_age';
    } else {
      return this.getXAxisSource(selectedPageConf, graphValue) === 'eoy_age';
    }
  }

  public getConfigLikeObject(item) {
    return {
      config: {
        uiId: item.uiId,
        showPreview: true,
      },
      label: coversheetPageName,
      isDisabled: true,
      dragDisabled: true,
      toggleDisabled: true,
      isRequiredCoverSheet: true,
      internalId: 99,
      pageOrder: null,
    } as any;
  }

  public getPageConfigById(configs: PageConfig[], uiId: string): PageConfig | undefined {
    return _.find(configs, { config: { uiId } });
  }

  public filterCoverLettersRequired(coverLetters: any[], menuPagesList: any[]) {
    let resultArr = coverLetters;
    let contributionAmount: number;
    let lifeInsurancePremium: number;

    const retirement_shortfall = _.find(menuPagesList, {
      config: { uiId: 'retirement_shortfall' },
    } as any) as any;

    const shortfallSettings = _.get(retirement_shortfall, 'config.shortfallSettings');

    if (shortfallSettings) {
      contributionAmount = this.retirementShortfallService.contributionAmountControlCalculate(
        shortfallSettings.currentAnnualIncome,
        shortfallSettings.retirementAssetsContributionOfIncome
      );
      lifeInsurancePremium = this.retirementShortfallService.calculateLifeInsurancePremium();
    }

    let ignoredUiId: string;

    if (
      !retirement_shortfall ||
      !_.get(retirement_shortfall, 'config.showPreview') ||
      !_.get(retirement_shortfall, 'config.selectedPlanId') ||
      lifeInsurancePremium > contributionAmount
    ) {
      ignoredUiId = 'allianzretirement';
    } else {
      ignoredUiId = 'allianz';
    }

    resultArr = coverLetters.filter((letter: any) => letter.uiId !== ignoredUiId);

    return resultArr;
  }

  public checkPermissions(config: any[]): any[] {
    const permissions = this.authService.isLogged && this.userService.groupPermissions;
    const configs: any = _.filter(config, (item: any) => {
      let isValid = item.config.checkPermission ? _.includes(permissions, item.config.uiId) : true;

      if (permissions && item.config.uiId && item.config.uiId.includes('single_policy')) {
        isValid = _.includes(permissions, 'single_policy');
      }

      if (permissions && item.config.uiId && item.config.uiId === 'cover') {
        isValid = this.authService.isLogged && this.iamService.hasGroupAccess('add_cover_letter');
      }

      if (permissions && item.config.uiId && item.config.isSalesConcept) {
        const { uiId } = this.customPageService.getSalesConceptIds(item.config.uiId);
        isValid = permissions.includes(`salesConcepts.${uiId}`);
      }

      return isValid;
    });

    return configs;
  }

  /**
   * getNavbarStaticPages - returns static pages for navbar which are based on staticPagesNavbarUiIds constant
   * and remove those static pages from menuPagesList.
   */
  public filterNavbarStaticPages(menuPagesList: any[]) {
    const staticPagesUiIds = setupConstant.staticPagesNavbarUiIds;

    return _.remove(menuPagesList, elem => staticPagesUiIds.includes(elem.config.uiId));
  }

  public moveOrRemoveCoverLetterByIndex(list, coverLetter) {
    const coverLetterIndex = list.findIndex(item => item.config.uiId === 'cover');

    if (coverLetterIndex === -1) return list;

    if (_.isEmpty(coverLetter)) return list.filter((_, index) => index !== coverLetterIndex);

    return [
      list[coverLetterIndex] && {
        ...list[coverLetterIndex],
        pageName: coversheetPageName,
      },
      ...list.slice(0, coverLetterIndex),
      ...list.slice(coverLetterIndex + 1),
    ];
  }

  private getFirstValueBySource(data: ChartData[], axisSource: string) {
    return chain(data)
      .map((item: any) => item.values)
      .flatten()
      .orderBy(['x'])
      .first((el: any) => el[axisSource])
      .get('x')
      .value();
  }

  private getLastValueBySource(data: ChartData[]) {
    return chain(data)
      .map((item: any) => item.values)
      .flatten()
      .orderBy(['x'])
      .last((el: any) => el.x)
      .get('x')
      .value();
  }
}
