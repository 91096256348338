import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  ElementRef,
  ViewChild,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import * as _ from 'lodash-es';
import { first, map, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { sortBy } from 'lodash-es';

import { Global } from '@shared/services';
import { CoverLetter } from '@shared/models';
import { CareerPlan } from '@core/model';
import { TEMPLATES_IDS } from '../../modals/cover-sheet-modal/cover-sheet-modal.constants';
import { PresentationInfo } from '../../cover-letter/cover-lettermodels';
import { getPresentationPlans } from '../../presentation.selectors';
import { AppState } from '../../../../reducers';

@UntilDestroy()
@Component({
  selector: 'ensight-cover-sheet-template',
  templateUrl: './cover-sheet-templates.component.html',
  styleUrls: ['./cover-sheet-templates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverSheetTemplatesComponent implements OnChanges, OnInit {
  @Input() PDFPrint: boolean;
  @Input() coverSheet: CoverLetter;
  @Input() coverSheetLoaded: boolean;
  @Input() coverSheetTemplates: any;
  @Input() selectedTemplateUid = 'lowcolor';
  @Input() presentationInfo: PresentationInfo;
  @Input() landscape = false;

  @Output() coverReadyEvent = new EventEmitter();

  @ViewChild('templateRef') set content(content: ElementRef) {
    if (content) {
      this.global
        .waitImages(content)
        .pipe(first(), untilDestroyed(this))
        .subscribe(() => this.coverReadyEvent.emit('cover'));
    }
  }

  fitPage = false;
  htmlBody: SafeHtml;
  innerHTML: SafeHtml;

  private selectedTemplate: any;
  private plans: CareerPlan[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    private global: Global,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.coverSheet && this.selectedTemplateUid === TEMPLATES_IDS.text) {
      this.htmlBody = changes.coverSheet.currentValue.textBody
        ? this.sanitizer.bypassSecurityTrustHtml(changes.coverSheet.currentValue.textBody)
        : '';
    }

    if (this.plans.length) {
      this.getTemplateCode();
    }
  }

  ngOnInit(): void {
    this.watchForPlans();
  }

  toggleZoom(): void {
    this.fitPage = !this.fitPage;
    this.getTemplateCode();
  }

  private getTemplateCode(): void {
    this.selectedTemplate = _.find(this.coverSheetTemplates, template => {
      return template.uiId === this.selectedTemplateUid;
    });

    if (!this.coverSheet && this.coverSheetLoaded) {
      this.initEmptyCoverSheet();
    }

    this.innerHTML = this.sanitizer.bypassSecurityTrustHtml(
      this.selectedTemplate.customFields.cssBody + this.addValuesToHtml(this.selectedTemplate.customFields.htmlBody)
    );

    this.cdr.markForCheck();
  }

  private getImgForAllianzPage(page: number): string {
    const imgSize = this.landscape ? 'small' : 'large';
    const name = `${page}_${imgSize}_${this.selectedTemplateUid}`;

    return _.find(this.selectedTemplate.files, (file: string) => file.includes(name));
  }

  private initEmptyCoverSheet(): void {
    this.coverSheet = {
      title: '',
      body: '',
      signature: '',
      footer: '',
      uiId: '',
    };
  }

  private addValuesToHtml(templateHtml: string): string {
    const compiled = _.template(templateHtml);
    let classes = '';
    classes += this.landscape ? 'allianz-landscape' : '';
    classes += this.fitPage || this.PDFPrint ? 'allianz-fit-width' : '';

    return compiled({
      title: this.replaceChipsWithData(this.coverSheet.title),
      body: this.replaceChipsWithData(this.coverSheet.body),
      signature: this.replaceChipsWithData(this.coverSheet.signature),
      footer: this.replaceChipsWithData(this.coverSheet.footer),
      firstPageImg: this.getImgForAllianzPage(1),
      secondPageImg: this.getImgForAllianzPage(2),
      clientName: this.presentationInfo.clientName,
      productName: this.presentationInfo.productName,
      pageDivider: this.PDFPrint ? `<div class="page-break"></div>` : '',
      cssClass: classes,
    });
  }

  // TODO: this method should be inside of service because the component should take already modified data
  private replaceChipsWithData(value: string): string {
    const product: CareerPlan = this.plans[0] || ({} as CareerPlan);
    const presentation = this.global.getPresentation;
    const wrap = document.createElement('div');

    wrap.innerHTML = value;

    Array.from(wrap.children).forEach((item: Element) => {
      const dataKey = item['dataset'].placeholderDataKey;
      const dataFrom = item['dataset'].placeholderDataFrom;
      const isFormatted = Boolean(item['dataset'].placeholderDataFormatted);
      const data = dataFrom === 'product' ? product.configjson.metadata[dataKey] : presentation[dataKey];
      const formattedValue = isFormatted ? this.getFormattedCurrency(data) : data;

      if (item.outerHTML !== '<br>') {
        value = value.replace(item.outerHTML, formattedValue || '');
      }
    });

    return value;
  }

  // TODO: need to have some service or try to rebuild logic where is used this pipe in component
  // TODO: pipe use only in template
  private getFormattedCurrency(value: string): string {
    return new CurrencyPipe('en-US').transform(value, 'USD', 'symbol', '1.3-3');
  }

  private watchForPlans(): void {
    this.store
      .select(getPresentationPlans)
      .pipe(
        untilDestroyed(this),
        map((plans: CareerPlan[]) => sortBy(plans, 'order')),
        tap((plans: CareerPlan[]) => (this.plans = plans))
      )
      .subscribe(() => this.getTemplateCode());
  }
}
